import { useCallback, useRef, useState } from 'react';
import { SubmitHandler, useForm } from 'react-hook-form';
import * as Yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import {
  Box,
  Heading,
  Divider,
  useToast,
  VStack,
  Button,
  Flex,
} from '@chakra-ui/react';
import axios from 'axios';
import { DefaultLayout } from '../../_layout/DefaultLayout';
import { consumeCouponsService } from '../../../../services/AdvertisementCoupons/ConsumeCouponsService';
import { translateError } from '../../../../utils/errors';
import { MaskedInput } from '../../../../components/Form/MaskedInput';
import { showCouponsService } from '../../../../services/AdvertisementCoupons/ShowCouponsService';
import { maskDateTime } from '../../../../utils/formatters/handleMask';
import AdvertisementCouponTable, {
  IAdvertisementCouponTableItem,
} from './components/AdvertisementCouponTable';

interface IConsumeCouponFormData {
  token: string;
}

const consumeCouponFormSchema = Yup.object().shape({
  token: Yup.string().required('Token requerido'),
});

export const ConsumeCoupon = (): JSX.Element => {
  const formRef = useRef<HTMLDivElement & HTMLFormElement>(null);
  const toast = useToast();

  const [coupon, setCoupon] = useState<IAdvertisementCouponTableItem>();

  const { register, reset, formState, handleSubmit } = useForm({
    resolver: yupResolver(consumeCouponFormSchema),
  });

  const { errors } = formState;

  const handleFindCoupon: SubmitHandler<IConsumeCouponFormData> = useCallback(
    async ({ token }) => {
      try {
        const couponData = await showCouponsService(token);

        const parsedCouponData = {
          ...couponData,
          formattedExpirationDate: couponData.expirationDate
            ? maskDateTime(couponData.expirationDate)
            : 'não expira',
          formattedUsedAt: couponData.usedAt
            ? maskDateTime(couponData.usedAt)
            : undefined,
        };

        setCoupon(parsedCouponData);
      } catch (err) {
        if (axios.isAxiosError(err) && err.response?.status !== 401) {
          toast({
            title: 'Falha ao buscar',
            description:
              translateError({ message: err.response?.data.message }) ||
              'Ocorreu um erro ao buscar o cupom, tente novamente',
            status: 'error',
            duration: 3000,
            isClosable: true,
            variant: 'subtle',
            position: 'top-right',
          });
        }
      }
    },
    [toast],
  );

  const handleConsumeCoupon = useCallback(async () => {
    if (coupon) {
      try {
        await consumeCouponsService(coupon.token);

        toast({
          title: 'Consumido com sucesso',
          description: 'O cupom foi consumido corretamente',
          status: 'success',
          duration: 3000,
          isClosable: true,
          variant: 'subtle',
          position: 'top-right',
        });

        reset({});
        setCoupon(undefined);
      } catch (err) {
        if (axios.isAxiosError(err) && err.response?.status !== 401) {
          toast({
            title: 'Falha ao usar',
            description:
              translateError({ message: err.response?.data.message }) ||
              'Ocorreu um erro ao usar o cupom, tente novamente',
            status: 'error',
            duration: 3000,
            isClosable: true,
            variant: 'subtle',
            position: 'top-right',
          });
        }
      }
    }
  }, [coupon, reset, toast]);

  return (
    <DefaultLayout>
      <Box
        ref={formRef}
        as="form"
        flex="1"
        borderRadius={8}
        bg="white"
        p="8"
        onSubmit={handleSubmit(handleFindCoupon)}
      >
        <Heading size="lg" fontWeight="normal">
          Consumir cupom
        </Heading>

        <Divider my="6" borderColor="gray.300" />

        <VStack>
          <MaskedInput
            label="Token"
            error={errors.token}
            showRightButton
            onSubmit={() => formRef.current?.requestSubmit()}
            mask="token"
            width="80"
            {...register('token')}
          />

          {!!coupon && (
            <Flex direction="column" w="100%">
              <AdvertisementCouponTable coupon={coupon} />

              {!coupon.isExpired && !coupon.isUsed && (
                <Flex justifyContent="flex-end" mt="8">
                  <Button
                    type="button"
                    colorScheme="green"
                    onClick={handleConsumeCoupon}
                    size="lg"
                  >
                    Consumir
                  </Button>
                </Flex>
              )}
            </Flex>
          )}
        </VStack>
      </Box>
    </DefaultLayout>
  );
};

import { IAdvertisementCategoryBase } from '../../models/advertisements';
import cjPontanegraApi from '../apis/cjPontanegraApi';

interface ICreateAdvertisementCategoryProps {
  description?: string;
  isActive: boolean;
  name: string;
  showOrder: number;
}

export type INewAdvertisementCategory = IAdvertisementCategoryBase;

export const createAdvertisementCategoriesService = async ({
  description,
  isActive,
  name,
  showOrder,
}: ICreateAdvertisementCategoryProps): Promise<INewAdvertisementCategory> => {
  const { data } = await cjPontanegraApi.post<INewAdvertisementCategory>(
    '/announcements/categories',
    {
      description,
      isActive,
      name,
      showOrder,
    },
  );

  return data;
};

import { IEntityBase } from './entityBase';

export enum UserProfileStatusColor {
  CONFIRMED = 'green.500',
  PENDING = 'red.500',
}

export enum UserProfileStatus {
  CONFIRMED = 'Confirmado',
  PENDING = 'Pendente',
}

export enum UserRoles {
  ADMIN = 'ADM Master',
  EMPLOYEE = 'Lojista',
  CUSTOMER = 'Cliente',
}

export enum UserGenders {
  FEMALE = 'Feminino',
  MALE = 'Masculino',
  OTHER = 'Outros',
  UNDEFINED = 'Não informado',
}

export interface IUserCountByGenderBase {
  female: number;
  male: number;
  other: number;
  undef: number;
}

export interface IUserBase extends IEntityBase {
  avatar?: string;
  avatarUrl?: string;
  birthDate: string;
  confirmPending: boolean;
  cpf: string;
  email: string;
  gender: 'FEMALE' | 'MALE' | 'OTHER' | 'UNDEFINED';
  name: string;
  phone?: string;
  role: 'ADMIN' | 'EMPLOYEE' | 'CUSTOMER';
  sellerId?: string;
}

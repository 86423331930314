import {
  IAdvertisementCategoryBase,
  IAdvertisementBase,
} from '../../models/advertisements';
import cjPontanegraApi from '../apis/cjPontanegraApi';

interface IUpdateAdvertisementCategoryAvatarProps {
  categoryId: string;
  avatarData: FormData;
}

export interface IUpdatedAdvertisementCategoryAvatar
  extends IAdvertisementCategoryBase {
  advertisements: IAdvertisementBase;
}

export const updateAdvertisementCategoryAvatarsService = async ({
  avatarData,
  categoryId,
}: IUpdateAdvertisementCategoryAvatarProps): Promise<IUpdatedAdvertisementCategoryAvatar> => {
  const { data } =
    await cjPontanegraApi.patch<IUpdatedAdvertisementCategoryAvatar>(
      `/avatars/announcement-categories/${categoryId}`,
      avatarData,
    );

  return data;
};

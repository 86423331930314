import { Box, Flex, Grid, GridItem } from '@chakra-ui/react';
import { useEffect, useState } from 'react';
import { AppDownloadStore } from '../../../../models/appDownloads';
import { listAdvertisementClicksService } from '../../../../services/AdvertisementClicks/ListAdvertisementClicksService';
import { listAppDownloadsService } from '../../../../services/AppDownloads/ListAppDownloadsService';
import { listUsersAgeGroupsService } from '../../../../services/Users/ListUsersAgeGroupsService';
import { showUsersCountByGenderService } from '../../../../services/Users/ShowUsersCountByGenderService';

import { DefaultLayout } from '../../_layout/DefaultLayout';
import AdvertisementClickChart from './components/AdvertisementClickChart';
import AppDownloadChart from './components/AppDownloadChart';
import UserAgeDistributionChart from './components/UserAgeDistributionChart';
import UserAgeGroupChart from './components/UserAgeGroupChart';
import UserGenderDistributionChart from './components/UserGenderDistributionChart';

type SeriesData = { series: number[]; labels: string[] };

export const Dashboard = (): JSX.Element => {
  const [usersAgeGroups, setUsersAgeGroups] = useState<SeriesData>({
    series: [],
    labels: [],
  });
  const [advertisementClicks, setAdvertisementClicks] = useState<SeriesData>({
    series: [],
    labels: [],
  });
  const [usersCountByGenders, setUsersCountByGender] = useState<SeriesData>({
    series: [],
    labels: [],
  });
  const [appDownloads, setAppDownloads] = useState<SeriesData>({
    series: [],
    labels: [],
  });
  // const [consumedCoupons, setConsumedCoupons] = useState<SeriesData>({
  //   series: [],
  //   labels: [],
  // });

  useEffect(() => {
    async function loadUsersAgeGroups(): Promise<void> {
      const usersAgeGroupsData = await listUsersAgeGroupsService({
        ageGroups: [
          { maxAge: 12, minAge: 0 },
          { maxAge: 18, minAge: 12 },
          { maxAge: 25, minAge: 18 },
          { maxAge: 35, minAge: 25 },
          { maxAge: 45, minAge: 35 },
          { maxAge: 60, minAge: 45 },
          { maxAge: null, minAge: 60 },
        ],
        roles: ['CUSTOMER'],
      });

      const parsedUsersAgeGroups = usersAgeGroupsData.reduce(
        (options, ageGroup) => ({
          labels: [
            ...options.labels,
            `${ageGroup.minAge} - ${ageGroup.maxAge || 'mais'} anos`,
          ],
          series: [...options.series, ageGroup.count],
        }),
        {
          series: [],
          labels: [],
        } as SeriesData,
      );

      setUsersAgeGroups(parsedUsersAgeGroups);
    }

    loadUsersAgeGroups();
  }, []);

  useEffect(() => {
    async function loadAdvertisementClicks(): Promise<void> {
      const advertisementClicksData = await listAdvertisementClicksService({
        take: 15,
      });

      const parsedAdvertisementClicks = advertisementClicksData.reduce(
        (options, advertisementClick) => ({
          labels: [...options.labels, advertisementClick.title],
          series: [...options.series, advertisementClick.count],
        }),
        {
          labels: [],
          series: [],
        } as SeriesData,
      );

      setAdvertisementClicks(parsedAdvertisementClicks);
    }

    loadAdvertisementClicks();
  }, []);

  useEffect(() => {
    async function loadAppDownloads(): Promise<void> {
      const appDownloadsData = await listAppDownloadsService();

      const parsedAppDownloads = appDownloadsData.reduce(
        (options, appDownload) => ({
          labels: [...options.labels, AppDownloadStore[appDownload.store]],
          series: [...options.series, appDownload.count],
        }),
        {
          labels: [],
          series: [],
        } as SeriesData,
      );

      setAppDownloads(parsedAppDownloads);
    }

    loadAppDownloads();
  }, []);

  useEffect(() => {
    async function loadUsersCountByGender(): Promise<void> {
      const { female, male, other, undef } =
        await showUsersCountByGenderService({
          roles: ['CUSTOMER'],
        });

      setUsersCountByGender({
        labels: ['Feminino', 'Masculino', 'Outros', 'Não informado'],
        series: [female, male, other, undef],
      });
    }

    loadUsersCountByGender();
  }, []);

  // useEffect(() => {
  //   // TODO
  //   setConsumedCoupons({
  //     labels: ['Dezembro', 'Janeiro', 'Fevereiro'],
  //     series: [150, 360, 49],
  //   });
  // }, []);

  return (
    <DefaultLayout>
      <Flex flex={1} direction="column">
        <Grid
          templateColumns={[
            'repeat(1, 1fr)',
            'repeat(1, 1fr)',
            'repeat(1, 1fr)',
            'repeat(1, 1fr)',
            'repeat(12, 1fr)',
          ]}
          gap="4"
          width="100%"
        >
          <GridItem colSpan={[12, 12, 12, 12, 4]}>
            <Box p="8" bg="white" borderRadius={8} height="100%">
              <UserAgeDistributionChart usersAgeGroups={usersAgeGroups} />
            </Box>
          </GridItem>

          <GridItem colSpan={[12, 12, 12, 12, 8]}>
            <Box p="8" bg="white" borderRadius={8} height="100%">
              <UserAgeGroupChart usersAgeGroups={usersAgeGroups} />
            </Box>
          </GridItem>
        </Grid>

        <Grid
          templateColumns={[
            'repeat(1, 1fr)',
            'repeat(1, 1fr)',
            'repeat(1, 1fr)',
            'repeat(1, 1fr)',
            'repeat(12, 1fr)',
          ]}
          mt="4"
          gap="4"
          width="100%"
        >
          <GridItem colSpan={[12, 12, 12, 12, 4]}>
            <Box p="8" bg="white" borderRadius={8} height="100%">
              <UserGenderDistributionChart
                usersCountByGenders={usersCountByGenders}
              />
            </Box>
          </GridItem>

          <GridItem colSpan={[12, 12, 12, 12, 4]}>
            <Box p="8" bg="white" borderRadius={8} height="100%">
              <AppDownloadChart appDownloads={appDownloads} />
            </Box>
          </GridItem>

          <GridItem colSpan={[12, 12, 12, 12, 4]}>
            {/* <Box p="8" bg="white" borderRadius={8} height="100%">
              <ConsumedCouponChart consumedCoupons={consumedCoupons} />
            </Box> */}
          </GridItem>
        </Grid>

        <Box p="8" bg="white" borderRadius={8} mt="4" height="100%">
          <AdvertisementClickChart advertisementClicks={advertisementClicks} />
        </Box>
      </Flex>
    </DefaultLayout>
  );
};

import { useCallback, useEffect, useState } from 'react';
import { useHistory, useLocation } from 'react-router';
import { SubmitHandler, useForm } from 'react-hook-form';
import * as Yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import {
  Box,
  Heading,
  Divider,
  VStack,
  SimpleGrid,
  Flex,
  Button,
  useToast,
  ButtonGroup,
} from '@chakra-ui/react';

import axios from 'axios';
import { AvatarDropzone } from '../../../../../components/Form/AvatarDropzone';
import { MaskedInput } from '../../../../../components/Form/MaskedInput';
import { DefaultLayout } from '../../../_layout/DefaultLayout';
import { DeleteConfirmationModal } from '../../../../../components/DeleteConfirmationModal';
import { InternationalPhoneInput } from '../../../../../components/Form/InternationalPhoneInput';
import { showSellersService } from '../../../../../services/Sellers/ShowSellersService';
import { updateSellersService } from '../../../../../services/Sellers/UpdateSellersService';
import { updateSellerAvatarsService } from '../../../../../services/Sellers/UpdateSellerAvatarsService';
import { deleteSellerAvatarsService } from '../../../../../services/Sellers/DeleteSellerAvatarsService';
import { maskCnpj } from '../../../../../utils/formatters/handleMask';
import { translateError } from '../../../../../utils/errors';

type UpdateSellerFormData = {
  bio?: string;
  cnpj: string;
  email: string;
  name: string;
  phone?: string;
};

interface ILocationState {
  sellerId: string;
}

const updateSellerFormSchema = Yup.object().shape({
  bio: Yup.string()
    .nullable()
    .transform((value, originalValue) => (originalValue === '' ? null : value)),
  cnpj: Yup.string()
    .required('Cpf requerido')
    .length(14, 'Valor inválido, requerido 14 dígitos')
    .transform((_, originalValue) => originalValue.replace(/\D/g, '')),
  email: Yup.string()
    .email('E-mail inválido')
    .required('E-mail requerido')
    .transform((value) => value.toLowerCase()),
  name: Yup.string().required('Nome requerido'),
  phone: Yup.string()
    .nullable()
    .transform((value, originalValue) => (originalValue === '' ? null : value)),
});

export const SellerUpdate = (): JSX.Element => {
  const { goBack } = useHistory();
  const toast = useToast();
  const { state } = useLocation<ILocationState>();

  const [updatingSeller, setUpdatingSeller] = useState<UpdateSellerFormData>();
  const [avatar, setAvatar] = useState<File>();
  const [avatarUrl, setAvatarUrl] = useState<string>();
  const [
    isDeleteConfirmationModalVisible,
    setIsDeleteConfirmationModalVisible,
  ] = useState(false);

  const { register, handleSubmit, formState, reset, control } = useForm({
    resolver: yupResolver(updateSellerFormSchema),
  });

  const { errors } = formState;
  const { sellerId } = state;

  useEffect(() => {
    async function loadSeller(): Promise<void> {
      try {
        const sellerData = await showSellersService(sellerId);
        setAvatarUrl(sellerData.avatarUrl || undefined);

        setUpdatingSeller({
          cnpj: maskCnpj(sellerData.cnpj).toString(),
          email: sellerData.email,
          name: sellerData.name,
          bio: sellerData.bio,
          phone: sellerData.phone,
        });
      } catch (err) {
        if (axios.isAxiosError(err) && err.response?.status !== 401) {
          toast({
            title: 'Falha ao carregar dados',
            description:
              translateError({ message: err.response?.data.message }) ||
              'Ocorreu um erro ao carregar os dados da loja, tente novamente',
            status: 'error',
            duration: 3000,
            isClosable: true,
            variant: 'subtle',
            position: 'top-right',
          });
        }
      }
    }

    loadSeller();
  }, [sellerId, reset, toast]);

  useEffect(() => {
    reset(updatingSeller);
  }, [reset, updatingSeller]);

  const handleChangeAvatar = useCallback((file: File) => {
    setAvatar(file);
    setAvatarUrl(URL.createObjectURL(file));
  }, []);

  const handleToggleDeleteConfirmationModal = useCallback(() => {
    setIsDeleteConfirmationModalVisible((prevState) => !prevState);
  }, []);

  const handleDeleteAvatar = useCallback(async () => {
    await deleteSellerAvatarsService(sellerId);

    setAvatar(undefined);
    setAvatarUrl(undefined);
    handleToggleDeleteConfirmationModal();
  }, [sellerId, handleToggleDeleteConfirmationModal]);

  const handleUpdateSeller: SubmitHandler<UpdateSellerFormData> = useCallback(
    async (sellerData) => {
      try {
        await updateSellersService({
          sellerId,
          ...sellerData,
        });

        if (avatar) {
          const formData = new FormData();

          formData.append('avatar', avatar);

          await updateSellerAvatarsService({
            sellerId,
            avatarData: formData,
          });
        }

        toast({
          title: 'Editado com sucesso',
          description: 'A loja foi editada corretamente',
          status: 'success',
          duration: 3000,
          isClosable: true,
          variant: 'subtle',
          position: 'top-right',
        });

        goBack();
      } catch (err) {
        if (axios.isAxiosError(err) && err.response?.status !== 401) {
          toast({
            title: 'Falha ao editar',
            description:
              translateError({ message: err.response?.data.message }) ||
              'Ocorreu um erro ao editar a loja, tente novamente',
            status: 'error',
            duration: 3000,
            isClosable: true,
            variant: 'subtle',
            position: 'top-right',
          });
        }
      }
    },
    [avatar, sellerId, goBack, toast],
  );

  return (
    <DefaultLayout>
      <DeleteConfirmationModal
        isOpen={isDeleteConfirmationModalVisible}
        onClose={handleToggleDeleteConfirmationModal}
        onConfirm={handleDeleteAvatar}
      />

      <Box
        as="form"
        flex="1"
        borderRadius={8}
        bg="white"
        p="8"
        onSubmit={handleSubmit(handleUpdateSeller)}
      >
        <Heading size="lg" fontWeight="normal">
          Editar loja
        </Heading>

        <Divider my="6" borderColor="gray.300" />

        <Flex justify="center" mb="8">
          <AvatarDropzone
            avatarUrl={avatarUrl}
            onChange={handleChangeAvatar}
            onDelete={handleToggleDeleteConfirmationModal}
          />
        </Flex>

        <VStack spacing="8">
          <MaskedInput label="Nome" error={errors.name} {...register('name')} />
          <SimpleGrid minChildWidth="240px" spacing="8" w="100%">
            <MaskedInput
              label="E-mail"
              type="email"
              textTransform="lowercase"
              error={errors.email}
              {...register('email')}
            />

            <InternationalPhoneInput
              label="Telefone"
              name="phone"
              control={control}
              error={errors.phone}
            />

            <MaskedInput
              label="CNPJ"
              mask="cnpj"
              error={errors.cnpj}
              {...register('cnpj')}
            />
          </SimpleGrid>

          <MaskedInput
            label="Biografia"
            as="textarea"
            minHeight="160px"
            resize="none"
            py="2"
            error={errors.bio}
            {...register('bio')}
          />
        </VStack>

        <Flex mt="12" justify="flex-end">
          <ButtonGroup>
            <Button colorScheme="blackAlpha" onClick={goBack}>
              Cancelar
            </Button>
            <Button
              type="submit"
              colorScheme="green"
              isLoading={formState.isSubmitting}
            >
              Salvar
            </Button>
          </ButtonGroup>
        </Flex>
      </Box>
    </DefaultLayout>
  );
};

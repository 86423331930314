import {
  Input as ChakraInput,
  FormLabel,
  FormControl,
  FormErrorMessage,
} from '@chakra-ui/react';
import ReactDatePicker, { registerLocale } from 'react-datepicker';
import { Control, Controller, FieldError } from 'react-hook-form';
import ptBr from 'date-fns/locale/pt-BR';

import 'react-datepicker/dist/react-datepicker.css';
import { useCallback } from 'react';

interface IDatePickerProps {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  control: Control<any>;
  error?: FieldError;
  isClearable?: boolean;
  isDisabled?: boolean;
  label?: string;
  maxDate?: Date;
  minDate?: Date;
  name: string;
}

registerLocale('pt-BR', ptBr);

export const DatePicker = ({
  name,
  label,
  control,
  error,
  isClearable = true,
  isDisabled = false,
  maxDate,
  minDate,
  ...rest
}: IDatePickerProps): JSX.Element => {
  const handleInputMask = useCallback(
    (e: React.FormEvent<HTMLInputElement>): void => {
      e.currentTarget.maxLength = 10;

      const match = e.currentTarget.value
        .replace(/\D/g, '')
        .match(/^(\d{2})(\d{2})(\d{4})/);

      if (match) {
        e.currentTarget.value = `${match[1]}/${match[2]}/${match[3]}`;

        return;
      }

      e.currentTarget.value = e.currentTarget.value
        .replace(/\D/g, '')
        .replace(/(\d{2})(\d)/, '$1/$2')
        .replace(/(\d{2})(\d)/, '$1/$2');
    },
    [],
  );

  return (
    <FormControl
      isInvalid={!!error}
      display="flex"
      flexDirection="column"
      zIndex={3}
    >
      {!!label && <FormLabel htmlFor={name}>{label}</FormLabel>}

      <Controller
        control={control}
        name={name}
        render={({ field: { onChange, onBlur, value } }) => (
          <ReactDatePicker
            disabled={isDisabled}
            dateFormat="dd/MM/yyyy"
            isClearable={isClearable && !isDisabled}
            locale="pt-BR"
            maxDate={maxDate}
            minDate={minDate}
            onBlur={onBlur}
            onChange={onChange}
            selected={value}
            todayButton="Hoje"
            customInput={
              <ChakraInput
                borderColor="gray.300"
                bg="gray.100"
                focusBorderColor="blue.300"
                onKeyUp={handleInputMask}
                size="lg"
                variant="outline"
              />
            }
          />
        )}
        {...rest}
      />

      {!!error && <FormErrorMessage>{error.message}</FormErrorMessage>}
    </FormControl>
  );
};

import {
  Box,
  Table,
  Text,
  Tbody,
  Td,
  Th,
  Thead,
  Tr,
  BoxProps,
  Icon,
} from '@chakra-ui/react';
import { RiEyeLine } from 'react-icons/ri';
import { LinkButton } from '../../../../../../../components/LinkButton';
import { Pagination } from '../../../../../../../components/Pagination';
import { TableFilters } from '../../../../../../../components/TableFilters';
import {
  UserProfileStatus,
  UserProfileStatusColor,
} from '../../../../../../../models/users';
import { IUserListItem } from '../../../../../../../services/Users/ListUsersService';

export interface ICustomer extends IUserListItem {
  formattedDate: string;
}

const filterByOptions = [
  {
    label: 'Nome',
    value: 'name',
  },
  {
    label: 'E-mail',
    value: 'email',
  },
  {
    label: 'Telefone',
    value: 'phone',
  },
  {
    label: 'CPF',
    value: 'cpf',
  },
];

const sortByOptions = [
  {
    label: 'Nome',
    value: 'name',
  },
  {
    label: 'Telefone',
    value: 'phone',
  },
  {
    label: 'E-mail',
    value: 'email',
  },
  {
    label: 'CPF',
    value: 'cpf',
  },
  {
    label: 'Data de cadastro',
    value: 'createdAt',
  },
];

interface ICustomerTableProps extends BoxProps {
  customers: ICustomer[];
  currentPage?: number;
  filterBy?: string;
  onChangeFilterBy?: (value: string) => void;
  onFilter?: (value: string) => void;
  onOrder?: (order: 'ASC' | 'DESC') => void;
  onPageChange?: (page: number) => void;
  onSort?: (sort: 'cpf' | 'name' | 'phone' | 'email' | 'createdAt') => void;
  order?: 'ASC' | 'DESC';
  sortBy?: string;
  totalPages?: number;
}

export const CustomerTable = ({
  customers,
  currentPage,
  filterBy,
  onChangeFilterBy,
  onFilter,
  onOrder,
  onPageChange,
  onSort,
  order,
  sortBy,
  totalPages,
  ...rest
}: ICustomerTableProps): JSX.Element => (
  <Box {...rest}>
    <TableFilters
      filterBy={filterBy}
      filterByOptions={filterByOptions}
      onChangeFilterBy={onChangeFilterBy}
      onFilter={onFilter}
      onOrder={onOrder}
      onSort={(sort) =>
        !!onSort &&
        onSort(sort as 'cpf' | 'name' | 'phone' | 'email' | 'createdAt')
      }
      order={order}
      sortBy={sortBy}
      sortByOptions={sortByOptions}
    />

    <Table colorScheme="blue" mt="4">
      <Thead>
        <Tr>
          <Th>Usuário</Th>
          <Th>Telefone</Th>
          <Th>E-mail</Th>
          <Th>CPF</Th>
          <Th textAlign="center">Status do perfil</Th>
          <Th textAlign="center">Data de cadastro</Th>
          <Th w="8" />
        </Tr>
      </Thead>

      <Tbody>
        {customers.map((customer) => (
          <Tr key={customer.id}>
            <Td>
              <Box>
                <Text fontWeight="bold">{customer.name}</Text>
                <Text fontSize="sm" color="gray.500">
                  {customer.seller?.name}
                </Text>
              </Box>
            </Td>

            <Td>{customer.phone}</Td>

            <Td>{customer.email}</Td>

            <Td>{customer.cpf}</Td>

            <Td textAlign="center">
              <Text
                color={
                  UserProfileStatusColor[
                    customer.confirmPending ? 'PENDING' : 'CONFIRMED'
                  ]
                }
              >
                {
                  UserProfileStatus[
                    customer.confirmPending ? 'PENDING' : 'CONFIRMED'
                  ]
                }
              </Text>
            </Td>
            <Td textAlign="center">{customer.formattedDate}</Td>

            <Td>
              <LinkButton
                p={0}
                to={{
                  pathname: '/customers/details',
                  state: {
                    customerId: customer.id,
                  },
                }}
              >
                <Icon as={RiEyeLine} fontSize="16" />
              </LinkButton>
            </Td>
          </Tr>
        ))}
      </Tbody>
    </Table>

    {!!currentPage && !!onPageChange && !!totalPages && totalPages > 1 && (
      <Pagination
        currentPage={currentPage}
        onPageChange={onPageChange}
        totalPages={totalPages}
      />
    )}
  </Box>
);

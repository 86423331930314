import {
  IAdvertisementBase,
  IAdvertisementCategoryBase,
} from '../../models/advertisements';
import { ISellerBase } from '../../models/sellers';
import cjPontanegraApi from '../apis/cjPontanegraApi';

interface IUpdateAdvertisementAvatarProps {
  advertisementId: string;
  avatarData: FormData;
}

export interface IUpdatedAdvertisementAvatar extends IAdvertisementBase {
  category: IAdvertisementCategoryBase;
  seller: ISellerBase;
}

export const updateAdvertisementAvatarsService = async ({
  avatarData,
  advertisementId,
}: IUpdateAdvertisementAvatarProps): Promise<IUpdatedAdvertisementAvatar> => {
  const { data } = await cjPontanegraApi.patch<IUpdatedAdvertisementAvatar>(
    `/avatars/announcements/${advertisementId}`,
    avatarData,
  );

  return data;
};

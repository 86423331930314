import { useHistory, useLocation } from 'react-router-dom';
import {
  Box,
  Heading,
  Flex,
  Button,
  Icon,
  Image,
  Text,
  SimpleGrid,
  Avatar,
  useToast,
  ButtonGroup,
  HStack,
  VStack,
} from '@chakra-ui/react';
import {
  RiCheckboxBlankCircleLine,
  RiCheckboxCircleLine,
  RiCheckDoubleLine,
  RiDeleteBinLine,
  RiEditLine,
} from 'react-icons/ri';
import { useCallback, useEffect, useState } from 'react';
import axios from 'axios';
import { DefaultLayout } from '../../../_layout/DefaultLayout';
import { DeleteConfirmationModal } from '../../../../../components/DeleteConfirmationModal';
import { LinkButton } from '../../../../../components/LinkButton';
import {
  maskDate,
  maskMoney,
} from '../../../../../utils/formatters/handleMask';
import {
  IDetailedAdvertisement,
  showAdvertisementsService,
} from '../../../../../services/Advertisements/ShowAdvertisementsService';
import { deleteAdvertisementsService } from '../../../../../services/Advertisements/DeleteAdvertisementsService';
import {
  IReviewAdvertisementData,
  ReviewAdvertisementModal,
} from './components/ReviewAdvertisementModal';
import { updateAdvertisementsStatusService } from '../../../../../services/Advertisements/UpdateAdvertisementsStatusService';
import {
  AdvertisementStatus,
  AdvertisementStatusColor,
} from '../../../../../models/advertisements';
import { useAuth } from '../../../../../hooks/auth';
import { translateError } from '../../../../../utils/errors';
import { getOptimalScaleValue } from '../../../../../components/Form/TimeInputWithScale/useTimeInputOptions';

interface IAdvertisement extends IDetailedAdvertisement {
  formattedCreatedAt: string;
  formattedExpirationDate?: string;
  formattedDiscount: string;
  formattedMaxCouponAge: string;
}

interface ILocationState {
  advertisementId: string;
}

export const AdvertisementDetails = (): JSX.Element => {
  const toast = useToast();
  const { user } = useAuth();
  const {
    state: { advertisementId },
  } = useLocation<ILocationState>();
  const { push } = useHistory();

  const [advertisement, setAdvertisement] = useState<IAdvertisement>();
  const [
    isReviewAdvertisementModalVisible,
    setIsReviewAdvertisementModalVisible,
  ] = useState(false);
  const [
    isDeleteConfirmationModalVisible,
    setIsDeleteConfirmationModalVisible,
  ] = useState(false);

  useEffect(() => {
    async function loadAdvertisement(): Promise<void> {
      try {
        const advertisementData = await showAdvertisementsService(
          advertisementId,
        );

        let formattedMaxCouponAge = 'sem limite';

        if (advertisementData.couponsMaxAgeInMinutes) {
          const optimalScaleValue = getOptimalScaleValue(
            advertisementData.couponsMaxAgeInMinutes,
          );

          formattedMaxCouponAge = `${optimalScaleValue.optimalValue} ${optimalScaleValue.optimalScale.label}`;
        }

        const parsedAdvertisement = {
          ...advertisementData,
          formattedCreatedAt: maskDate(advertisementData.createdAt),
          formattedExpirationDate: advertisementData.expirationDate
            ? maskDate(advertisementData.expirationDate)
            : 'não expira',
          formattedDiscount: advertisementData.discount
            ? maskMoney(advertisementData.discount)
            : 'nenhum',
          formattedMaxCouponAge,
        };

        setAdvertisement(parsedAdvertisement);
      } catch (err) {
        if (axios.isAxiosError(err) && err.response?.status !== 401) {
          toast({
            title: 'Falha ao carregar dados',
            description:
              translateError({ message: err.response?.data.message }) ||
              'Ocorreu um erro ao carregar os detalhes do anúncio, tente novamente',
            status: 'error',
            duration: 3000,
            isClosable: true,
            variant: 'subtle',
            position: 'top-right',
          });
        }
      }
    }

    loadAdvertisement();
  }, [advertisementId, toast]);

  const handleToggleReviewAdvertisementModal = useCallback(() => {
    setIsReviewAdvertisementModalVisible((prevState) => !prevState);
  }, []);

  const handleToggleDeleteConfirmationModal = useCallback(() => {
    setIsDeleteConfirmationModalVisible((prevState) => !prevState);
  }, []);

  const handleReviewAdvertisement = useCallback(
    async ({
      reviewComments: newReviewComments,
      status: newStatus,
    }: IReviewAdvertisementData) => {
      try {
        const { reviewComments, status } =
          await updateAdvertisementsStatusService({
            advertisementId,
            reviewComments: newReviewComments,
            status: newStatus,
          });

        setAdvertisement((prevState) =>
          prevState ? { ...prevState, reviewComments, status } : undefined,
        );

        toast({
          title: 'Revisado com sucesso',
          description: 'O anúncio foi revisado corretamente',
          status: 'success',
          duration: 3000,
          isClosable: true,
          variant: 'subtle',
          position: 'top-right',
        });

        handleToggleReviewAdvertisementModal();
      } catch (err) {
        if (axios.isAxiosError(err) && err.response?.status !== 401) {
          toast({
            title: 'Falha ao revisar',
            description:
              translateError({ message: err.response?.data.message }) ||
              'Ocorreu um erro ao revisar o anúncio, tente novamente',
            status: 'error',
            duration: 3000,
            isClosable: true,
            variant: 'subtle',
            position: 'top-right',
          });
        }
      }
    },
    [advertisementId, handleToggleReviewAdvertisementModal, toast],
  );

  const handleDeleteAdvertisement = useCallback(async () => {
    try {
      await deleteAdvertisementsService(advertisementId);

      toast({
        title: 'Excluído com sucesso',
        description: 'O anúncio foi excluído corretamente',
        status: 'success',
        duration: 3000,
        isClosable: true,
        variant: 'subtle',
        position: 'top-right',
      });

      push('/advertisements');
    } catch (err) {
      if (axios.isAxiosError(err) && err.response?.status !== 401) {
        toast({
          title: 'Falha ao excluir',
          description:
            translateError({ message: err.response?.data.message }) ||
            'Ocorreu um erro ao excluir o anúncio, tente novamente',
          status: 'error',
          duration: 3000,
          isClosable: true,
          variant: 'subtle',
          position: 'top-right',
        });
      }
    }
  }, [advertisementId, push, toast]);

  return (
    <DefaultLayout>
      <DeleteConfirmationModal
        isOpen={isDeleteConfirmationModalVisible}
        onClose={handleToggleDeleteConfirmationModal}
        onConfirm={handleDeleteAdvertisement}
      />

      <ReviewAdvertisementModal
        isOpen={isReviewAdvertisementModalVisible}
        onClose={handleToggleReviewAdvertisementModal}
        onSelect={handleReviewAdvertisement}
      />

      <Box flex="1" borderRadius={8} bg="white" p="8">
        <Flex mb="8" justify="space-between" align="center">
          <Heading size="lg" fontWeight="normal">
            Detalhes do anúncio
          </Heading>

          <ButtonGroup>
            {user.role === 'ADMIN' &&
              advertisement?.status === 'REVIEW_PENDING' && (
                <Button
                  size="sm"
                  fontSize="sm"
                  colorScheme="blue"
                  onClick={handleToggleReviewAdvertisementModal}
                  leftIcon={<Icon as={RiCheckDoubleLine} fontSize="20" />}
                >
                  Revisar
                </Button>
              )}

            <LinkButton
              colorScheme="yellow"
              color="white"
              icon={<Icon as={RiEditLine} fontSize="16" />}
              to={{
                pathname: '/advertisements/update',
                state: {
                  advertisementId,
                },
              }}
            >
              Editar
            </LinkButton>

            <Button
              size="sm"
              fontSize="sm"
              colorScheme="red"
              onClick={handleToggleDeleteConfirmationModal}
              leftIcon={<Icon as={RiDeleteBinLine} fontSize="20" />}
            >
              Excluir
            </Button>
          </ButtonGroup>
        </Flex>

        {advertisement && (
          <Flex direction="column">
            <Flex align="center" direction="column">
              {!advertisement.avatarUrl ? (
                <Avatar size="2xl" name={advertisement.title} />
              ) : (
                <Image
                  htmlWidth="240px"
                  objectFit="cover"
                  src={advertisement.avatarUrl}
                  alt={advertisement.title}
                />
              )}

              <Text fontSize="4xl" fontWeight="bold" mt="4">
                {advertisement.title}
              </Text>

              <Text color="gray.500">{advertisement.seller?.name}</Text>

              <HStack mt="4">
                <Text>Status:</Text>
                <Text color={AdvertisementStatusColor[advertisement.status]}>
                  {AdvertisementStatus[advertisement.status]}
                </Text>
              </HStack>

              {advertisement.status === 'REJECTED' && (
                <VStack spacing="0">
                  <Text>Comentários do revisor:</Text>
                  <Text>{advertisement.reviewComments}</Text>
                </VStack>
              )}

              <HStack mt="2" spacing="8">
                <HStack>
                  <Text>Ativo:</Text>
                  {advertisement.isActive ? (
                    <Icon
                      fontSize="3xl"
                      as={RiCheckboxCircleLine}
                      color="green"
                    />
                  ) : (
                    <Icon
                      fontSize="3xl"
                      as={RiCheckboxBlankCircleLine}
                      color="gray.400"
                    />
                  )}
                </HStack>

                <HStack>
                  <Text>Validade:</Text>
                  {!advertisement.isExpired ? (
                    <Icon
                      fontSize="3xl"
                      as={RiCheckboxCircleLine}
                      color="green"
                    />
                  ) : (
                    <Icon
                      fontSize="3xl"
                      as={RiCheckboxBlankCircleLine}
                      color="gray.400"
                    />
                  )}
                </HStack>
              </HStack>

              <Text
                textAlign="center"
                fontSize="sm"
                color="gray.500"
                mt="2"
                width="50%"
              >
                {advertisement.description}
              </Text>
            </Flex>

            <SimpleGrid
              minChildWidth="280px"
              spacing="8"
              px="8"
              mt="4"
              w="100%"
            >
              <HStack>
                <Text>Desconto:</Text>
                <Text color="gray.500">{advertisement.formattedDiscount}</Text>
              </HStack>

              <HStack>
                <Text>Categoria:</Text>
                <Text color="gray.500">{advertisement.category.name}</Text>
              </HStack>

              <HStack>
                <Text>Cupons total:</Text>
                <Text color="gray.500">
                  {advertisement.couponsAmount || 'sem limite'}
                </Text>
              </HStack>

              <HStack>
                <Text>Cupons disponíveis:</Text>
                <Text color="gray.500">
                  {advertisement.couponsAmountAvailable || 'sem limite'}
                </Text>
              </HStack>

              <HStack>
                <Text>Cupons resgatados:</Text>
                <Text color="gray.500">
                  {advertisement.createdCouponsCount}
                </Text>
              </HStack>

              <HStack>
                <Text>Validade dos cupons:</Text>
                <Text color="gray.500">
                  {advertisement.formattedMaxCouponAge}
                </Text>
              </HStack>

              <HStack>
                <Text>Máx. cupons por usuário:</Text>
                <Text color="gray.500">
                  {advertisement.couponsMaxUsagePerUser || 'sem limite'}
                </Text>
              </HStack>
              <HStack>
                <Text>Data de encerramento:</Text>
                <Text color="gray.500">
                  {advertisement.formattedExpirationDate}
                </Text>
              </HStack>

              <HStack>
                <Text>Data de cadastro:</Text>
                <Text color="gray.500">{advertisement.formattedCreatedAt}</Text>
              </HStack>
            </SimpleGrid>
          </Flex>
        )}
      </Box>
    </DefaultLayout>
  );
};

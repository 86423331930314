import { Switch } from 'react-router-dom';
import { AdvertisementCategoryDetails } from '../pages/App/Adm/AdvertisementCategories/AdvertisementCategoryDetails';
import { AdvertisementCategoryList } from '../pages/App/Adm/AdvertisementCategories/AdvertisementCategoryList';
import { AdvertisementCategoryRegister } from '../pages/App/Adm/AdvertisementCategories/AdvertisementCategoryRegister';
import { AdvertisementCategoryUpdate } from '../pages/App/Adm/AdvertisementCategories/AdvertisementCategoryUpdate';
import { SellerDetails } from '../pages/App/Adm/Sellers/SellerDetails';
import { SellerList } from '../pages/App/Adm/Sellers/SellerList';
import { SellerRegister } from '../pages/App/Adm/Sellers/SellerRegister';
import { SellerUpdate } from '../pages/App/Adm/Sellers/SellerUpdate';
import { UserDetails } from '../pages/App/Adm/Users/UserDetails';
import { UserList } from '../pages/App/Adm/Users/UserList';
import { UserRegister } from '../pages/App/Adm/Users/UserRegister';
import { UserUpdate } from '../pages/App/Adm/Users/UserUpdate';
import { AdvertisementDetails } from '../pages/App/General/Advertisements/AdvertisementDetails';
import { AdvertisementList } from '../pages/App/General/Advertisements/AdvertisementList';
import { AdvertisementRegister } from '../pages/App/General/Advertisements/AdvertisementRegister';
import { AdvertisementUpdate } from '../pages/App/General/Advertisements/AdvertisementUpdate';
import { ConsumeCoupon } from '../pages/App/General/ConsumeCoupon';
import { CustomerDetails } from '../pages/App/General/Customers/CustomerDetails';
import { CustomerList } from '../pages/App/General/Customers/CustomerList';
import { CustomerRegister } from '../pages/App/General/Customers/CustomerRegister';
import { CustomerUpdate } from '../pages/App/General/Customers/CustomerUpdate';
import { Dashboard } from '../pages/App/General/Dashboard';
import { Profile } from '../pages/App/Profile';
import { ConfirmPassword } from '../pages/Auth/ConfirmPassword';
import { ForgotPassword } from '../pages/Auth/ForgotPassword';
import { ResetPassword } from '../pages/Auth/ResetPassword';
import { SignIn } from '../pages/Auth/SignIn';
import { Route } from './Route';

export const Routes = (): JSX.Element => (
  <Switch>
    <Route path="/" exact component={SignIn} />
    <Route path="/users" exact component={UserList} isPrivate />
    <Route path="/users/details" component={UserDetails} isPrivate />
    <Route path="/users/register" component={UserRegister} isPrivate />
    <Route path="/users/update" component={UserUpdate} isPrivate />
    <Route path="/users/passwords/forgot" component={ForgotPassword} />
    <Route path="/users/passwords/reset" component={ResetPassword} />
    <Route path="/users/passwords/confirm" component={ConfirmPassword} />
    <Route path="/profile" exact component={Profile} isPrivate />
    <Route path="/dashboard" component={Dashboard} isPrivate />
    <Route path="/customers/" exact component={CustomerList} isPrivate />
    <Route
      path="/customers/details"
      exact
      component={CustomerDetails}
      isPrivate
    />
    <Route
      path="/customers/register"
      exact
      component={CustomerRegister}
      isPrivate
    />
    <Route
      path="/customers/update"
      exact
      component={CustomerUpdate}
      isPrivate
    />
    <Route
      path="/categories"
      exact
      component={AdvertisementCategoryList}
      isPrivate
    />
    <Route
      path="/categories/details"
      component={AdvertisementCategoryDetails}
      isPrivate
    />
    <Route
      path="/categories/register"
      component={AdvertisementCategoryRegister}
      isPrivate
    />
    <Route
      path="/categories/update"
      component={AdvertisementCategoryUpdate}
      isPrivate
    />
    <Route path="/sellers" exact component={SellerList} isPrivate />
    <Route path="/sellers/details" component={SellerDetails} isPrivate />
    <Route path="/sellers/register" component={SellerRegister} isPrivate />
    <Route path="/sellers/update" component={SellerUpdate} isPrivate />
    <Route
      path="/advertisements"
      exact
      component={AdvertisementList}
      isPrivate
    />
    <Route
      path="/advertisements/details"
      component={AdvertisementDetails}
      isPrivate
    />
    <Route
      path="/advertisements/register"
      component={AdvertisementRegister}
      isPrivate
    />
    <Route
      path="/advertisements/update"
      component={AdvertisementUpdate}
      isPrivate
    />
    <Route path="/coupons" component={ConsumeCoupon} isPrivate />
  </Switch>
);

import {
  IAdvertisementBase,
  IAdvertisementCategoryBase,
} from '../../models/advertisements';
import { ISellerBase } from '../../models/sellers';
import cjPontanegraApi from '../apis/cjPontanegraApi';

interface IUpdateAdvertisementProps {
  advertisementId: string;
  categoryId: string;
  couponsAmount?: number;
  couponsMaxAgeInMinutes?: number;
  couponsMaxUsagePerUser?: number;
  description?: string;
  discount?: number | null;
  expirationDate?: Date | null;
  isActive: boolean;
  sellerId: string;
  title: string;
}

export interface IUpdatedAdvertisement extends IAdvertisementBase {
  category: IAdvertisementCategoryBase;
  seller: ISellerBase;
}

export const updateAdvertisementsService = async ({
  advertisementId,
  categoryId,
  couponsAmount,
  couponsMaxAgeInMinutes,
  couponsMaxUsagePerUser,
  description,
  discount,
  expirationDate,
  isActive,
  sellerId,
  title,
}: IUpdateAdvertisementProps): Promise<IUpdatedAdvertisement> => {
  const { data } = await cjPontanegraApi.put<IUpdatedAdvertisement>(
    `/announcements/${advertisementId}`,
    {
      categoryId,
      couponsAmount,
      couponsMaxAgeInMinutes,
      couponsMaxUsagePerUser,
      description,
      discount,
      expirationDate,
      isActive,
      sellerId,
      title,
    },
  );

  return data;
};

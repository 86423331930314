import Chart from 'react-apexcharts';
import { Box, Text } from '@chakra-ui/react';
import useUserAgeDistributionChartOptions from './useUserAgeDistributionChartOptions';

type SeriesData = { series: number[]; labels: string[] };

interface IUserAgeDistributionChartProps {
  usersAgeGroups: SeriesData;
}

const UserAgeDistributionChart = ({
  usersAgeGroups,
}: IUserAgeDistributionChartProps): JSX.Element => {
  const userAgeDistributionOptions =
    useUserAgeDistributionChartOptions(usersAgeGroups);

  return (
    <>
      <Text fontSize="lg">Distribuição dos membros por faixa etária</Text>
      {!!userAgeDistributionOptions.series?.length && (
        <Box mt="8">
          <Chart
            options={userAgeDistributionOptions}
            series={userAgeDistributionOptions.series}
            type="donut"
            height={360}
          />
        </Box>
      )}
    </>
  );
};

export default UserAgeDistributionChart;

import cjPontanegraApi from '../apis/cjPontanegraApi';

interface IListAdvertisementClicksProps {
  take?: number;
}

export interface IAdvertisemenClick {
  id: string;
  count: number;
  title: string;
}

export const listAdvertisementClicksService = async ({
  take,
}: IListAdvertisementClicksProps): Promise<IAdvertisemenClick[]> => {
  const { data } = await cjPontanegraApi.get<IAdvertisemenClick[]>(
    '/announcements/clicks',
    { params: { take } },
  );
  return data;
};

import { ApexOptions } from 'apexcharts';
import theme from '../../../../../../styles/theme';

interface IUseUserAgeDistributionChartOptions {
  labels: string[];
  series: number[];
}
const useUserAgeDistributionChartOptions = ({
  labels,
  series,
}: IUseUserAgeDistributionChartOptions): ApexOptions =>
  ({
    chart: {
      foreColor: theme.colors.gray[500],
    },
    legend: {
      position: 'bottom',
    },
    colors: [
      '#90CDF4',
      '#63B3ED',
      '#4299E1',
      '#3182CE',
      '#2B6CB0',
      '#2C5282',
      '#2A4365',
    ],
    // colors: [
    //   '#2E93fA',
    //   '#66DA26',
    //   '#FF9800',
    //   '#E91E63',
    //   '#805AD5',
    //   '#00CFC4',
    //   '#546E7A',
    // ],
    labels,
    series,
  } as ApexOptions);

export default useUserAgeDistributionChartOptions;

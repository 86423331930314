import { Box, Heading, Flex, useToast, Icon } from '@chakra-ui/react';
import { RiAddLine } from 'react-icons/ri';
import { useEffect, useState, useCallback } from 'react';
import axios from 'axios';
import debounce from 'lodash.debounce';
import { DefaultLayout } from '../../../_layout/DefaultLayout';
import { LinkButton } from '../../../../../components/LinkButton';
import {
  maskCnpj,
  maskShortDate,
} from '../../../../../utils/formatters/handleMask';
import { ISeller, SellerTable } from './components/SellerTable';
import { listSellersService } from '../../../../../services/Sellers/ListSellersService';
import { translateError } from '../../../../../utils/errors';

interface ILoadSellersProps {
  cnpj?: string;
  email?: string;
  name?: string;
  phone?: string;
  sort?: 'cnpj' | 'name' | 'phone' | 'email' | 'createdAt';
  order?: 'ASC' | 'DESC';
  limit?: number;
  page?: number;
}

export const SellerList = (): JSX.Element => {
  const toast = useToast();

  const [sellersList, setSellersList] = useState<ISeller[]>([]);
  const [currentFilters, setCurrentFilters] =
    useState<{ [key: string]: string }>();
  const [currentOrdering, setCurrentOrdering] = useState<'ASC' | 'DESC'>('ASC');
  const [currentPage, setCurrentPage] = useState(1);
  const [filterBy, setFilterBy] = useState('name');
  const [sortBy, setSortBy] = useState<
    'cnpj' | 'name' | 'phone' | 'email' | 'createdAt'
  >('createdAt');
  const [totalPages, setTotalPages] = useState<number>();

  const loadSellers = useCallback(
    async ({
      cnpj,
      email,
      name,
      phone,
      limit = 20,
      order,
      page,
      sort,
    }: ILoadSellersProps) => {
      try {
        const sellers = await listSellersService({
          cnpj: cnpj || undefined,
          email: email || undefined,
          name: name || undefined,
          phone: phone || undefined,
          limit,
          order,
          page,
          sort,
        });

        const parsedSellers = sellers.items.map((seller) => ({
          ...seller,
          cnpj: maskCnpj(seller.cnpj).toString(),
          formattedDate: maskShortDate(seller.createdAt),
        }));

        setTotalPages(sellers.pages);
        setSellersList(parsedSellers);
      } catch (err) {
        if (axios.isAxiosError(err) && err.response?.status !== 401) {
          toast({
            title: 'Falha ao carregar dados',
            description:
              translateError({ message: err.response?.data.message }) ||
              'Ocorreu um erro ao carregar os dados das lojas, tente novamente',
            status: 'error',
            duration: 3000,
            isClosable: true,
            variant: 'subtle',
            position: 'top-right',
          });
        }
      }
    },
    [toast],
  );

  useEffect(() => {
    loadSellers({
      ...currentFilters,
      order: currentOrdering,
      page: currentPage,
      sort: sortBy,
    });
  }, [currentFilters, currentPage, sortBy, loadSellers, currentOrdering]);

  const handleFilterBy = useCallback((value: string) => {
    setFilterBy(value);
  }, []);

  const handleFilter = useCallback(
    (value: string) => {
      setCurrentFilters({ [filterBy]: value });
    },
    [filterBy],
  );

  const debounceHandleFilter = debounce(handleFilter, 1000);

  const handleSortList = useCallback(
    (sort: 'cnpj' | 'name' | 'phone' | 'email' | 'createdAt') => {
      setSortBy(sort);
    },
    [],
  );

  const handleOrderList = useCallback((order: 'ASC' | 'DESC') => {
    setCurrentOrdering(order);
  }, []);

  const handlePageChange = useCallback((page: number) => {
    setCurrentPage(page);
  }, []);

  return (
    <DefaultLayout>
      <Box flex="1" borderRadius={8} bg="white" p="8">
        <Flex justify="space-between" align="center">
          <Heading size="lg" fontWeight="normal">
            Lojas
          </Heading>

          <LinkButton
            colorScheme="green"
            icon={<Icon as={RiAddLine} fontSize="16" />}
            to="/sellers/register"
          >
            Cadastrar nova
          </LinkButton>
        </Flex>

        <SellerTable
          mt="4"
          sellers={sellersList}
          currentPage={currentPage}
          filterBy={filterBy}
          onChangeFilterBy={handleFilterBy}
          onFilter={debounceHandleFilter}
          onOrder={handleOrderList}
          onPageChange={handlePageChange}
          onSort={handleSortList}
          order={currentOrdering}
          sortBy={sortBy}
          totalPages={totalPages}
        />
      </Box>
    </DefaultLayout>
  );
};

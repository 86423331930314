import { IUserCountByGenderBase } from '../../models/users';
import cjPontanegraApi from '../apis/cjPontanegraApi';

export type IUserCountByGender = IUserCountByGenderBase;

interface IShowUsersCountByGenderServiceProps {
  roles?: Array<string>;
}

export const showUsersCountByGenderService = async ({
  roles,
}: IShowUsersCountByGenderServiceProps): Promise<IUserCountByGender> => {
  const { data } = await cjPontanegraApi.post<IUserCountByGender>(
    '/users/genders',
    {
      roles,
    },
  );

  return data;
};

import { useCallback, useEffect, useMemo, useState } from 'react';
import { useHistory, useLocation } from 'react-router';
import { SubmitHandler, useForm } from 'react-hook-form';
import * as Yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import {
  Box,
  Heading,
  Divider,
  VStack,
  SimpleGrid,
  Flex,
  Button,
  useToast,
  ButtonGroup,
  Grid,
  GridItem,
} from '@chakra-ui/react';

import axios from 'axios';
import { startOfDay } from 'date-fns';
import { AvatarDropzone } from '../../../../../components/Form/AvatarDropzone';
import { MaskedInput } from '../../../../../components/Form/MaskedInput';
import { DefaultLayout } from '../../../_layout/DefaultLayout';
import { useAuth } from '../../../../../hooks/auth';
import { DeleteConfirmationModal } from '../../../../../components/DeleteConfirmationModal';
import { InternationalPhoneInput } from '../../../../../components/Form/InternationalPhoneInput';
import {
  IDetailedUser,
  showUsersService,
} from '../../../../../services/Users/ShowUsersService';
import { deleteUserAvatarsService } from '../../../../../services/Users/DeleteUserAvatarsService';
import { updateUsersService } from '../../../../../services/Users/UpdateUsersService';
import { updateUserAvatarsService } from '../../../../../services/Users/UpdateUserAvatarsService';
import {
  AsyncSelect,
  SelectOption,
} from '../../../../../components/Form/AsyncSelect';
import { ReactSelect } from '../../../../../components/Form/ReactSelect';
import { listSellersService } from '../../../../../services/Sellers/ListSellersService';
import { maskCpf } from '../../../../../utils/formatters/handleMask';
import { translateError } from '../../../../../utils/errors';
import { DatePicker } from '../../../../../components/Form/DatePicker';

type UpdateUserFormData = {
  birthDate: Date;
  cpf: string;
  email: string;
  gender: 'FEMALE' | 'MALE' | 'OTHER' | 'UNDEFINED';
  name: string;
  phone?: string;
  role: 'ADMIN' | 'EMPLOYEE' | 'CUSTOMER';
  selectedSeller?: SelectOption;
};

interface ILocationState {
  userId: string;
}

const userUpdateFormSchema = Yup.object().shape({
  birthDate: Yup.date()
    .max(startOfDay(new Date()), 'Data inválida')
    .nullable()
    .required('Requerido'),
  cpf: Yup.string()
    .required('CPF requerido')
    .length(11, 'Valor inválido, requerido 11 dígitos')
    .transform((_, originalValue) => originalValue.replace(/\D/g, '')),
  email: Yup.string()
    .email('E-mail inválido')
    .required('E-mail requerido')
    .transform((value) => value.toLowerCase()),
  gender: Yup.string().required('Gênero requerido'),
  name: Yup.string().required('Nome requerido'),
  phone: Yup.string()
    .nullable()
    .transform((value, originalValue) => (originalValue === '' ? null : value)),
  role: Yup.string()
    .oneOf(['ADMIN', 'EMPLOYEE', 'CUSTOMER'], 'Inválido')
    .required('Requerido'),
  selectedSeller: Yup.object().when('role', {
    is: (val: 'ADMIN' | 'EMPLOYEE' | 'CUSTOMER') => val !== 'ADMIN',
    then: Yup.object()
      .shape({
        label: Yup.string().required('Loja requerida'),
        value: Yup.string().uuid().nullable().required('Loja requerida'),
      })
      .nullable()
      .required('Loja requerida'),
    otherwise: Yup.object()
      .shape({
        label: Yup.string(),
        value: Yup.string().uuid().nullable(),
      })
      .nullable(),
  }),
});

export const UserUpdate = (): JSX.Element => {
  const { goBack } = useHistory();
  const toast = useToast();
  const { state } = useLocation<ILocationState>();
  const { updateUser, user } = useAuth();

  const [updatingUser, setUpdatingUser] = useState<IDetailedUser>();
  const [avatar, setAvatar] = useState<File>();
  const [avatarUrl, setAvatarUrl] = useState<string>();
  const [
    isDeleteConfirmationModalVisible,
    setIsDeleteConfirmationModalVisible,
  ] = useState(false);

  const userRolesSelectOption = useMemo(
    () => [
      {
        label: 'ADM Master',
        value: 'ADMIN',
      },
      {
        label: 'Lojista',
        value: 'EMPLOYEE',
      },
    ],
    [],
  );

  const userGendersSelectOption = useMemo(
    () => [
      {
        label: 'Feminino',
        value: 'FEMALE',
      },
      {
        label: 'Masculino',
        value: 'MALE',
      },
      {
        label: 'Outros',
        value: 'OTHERS',
      },
      {
        label: 'Não informado',
        value: 'UNDEFINED',
      },
    ],
    [],
  );

  const { register, handleSubmit, formState, reset, control } = useForm({
    resolver: yupResolver(userUpdateFormSchema),
  });

  const { errors } = formState;
  const { userId } = state;

  useEffect(() => {
    async function loadUser(): Promise<void> {
      try {
        const userData = await showUsersService({ userId });
        setAvatarUrl(userData.avatarUrl || undefined);

        setUpdatingUser(userData);
      } catch (err) {
        if (axios.isAxiosError(err) && err.response?.status !== 401) {
          toast({
            title: 'Falha ao carregar dados',
            description:
              translateError({ message: err.response?.data.message }) ||
              'Ocorreu um erro ao carregar os dados do usuário, tente novamente',
            status: 'error',
            duration: 3000,
            isClosable: true,
            variant: 'subtle',
            position: 'top-right',
          });
        }
      }
    }

    loadUser();
  }, [userId, reset, toast]);

  useEffect(() => {
    if (updatingUser) {
      reset({
        birthDate: new Date(updatingUser.birthDate),
        cpf: maskCpf(updatingUser.cpf).toString(),
        email: updatingUser.email,
        gender: updatingUser.gender,
        name: updatingUser.name,
        phone: updatingUser.phone,
        role: updatingUser.role,
        selectedSeller: updatingUser.seller
          ? {
              label: updatingUser.seller.name,
              value: updatingUser.seller.id,
            }
          : undefined,
      });
    }
  }, [reset, updatingUser]);

  const handleChangeAvatar = useCallback((file: File) => {
    setAvatar(file);
    setAvatarUrl(URL.createObjectURL(file));
  }, []);

  const handleToggleDeleteConfirmationModal = useCallback(() => {
    setIsDeleteConfirmationModalVisible((prevState) => !prevState);
  }, []);

  const handleDeleteAvatar = useCallback(async () => {
    await deleteUserAvatarsService(userId);

    setAvatar(undefined);
    setAvatarUrl(undefined);
    handleToggleDeleteConfirmationModal();

    if (userId === user.id) {
      delete user.avatar;
      delete user.avatarUrl;

      updateUser(user);
    }
  }, [userId, handleToggleDeleteConfirmationModal, updateUser, user]);

  const handleLoadSellerSelectOption = useCallback(
    async (name?: string): Promise<SelectOption[]> => {
      const { items: sellers } = await listSellersService({ name, limit: 4 });

      const parsedSellersSelectOption: SelectOption[] = [
        {
          label: ' ',
          value: null,
        },
        ...sellers.map((seller) => ({
          label: seller.name,
          value: seller.id,
        })),
      ];

      return parsedSellersSelectOption;
    },
    [],
  );

  const handleUpdateUser: SubmitHandler<UpdateUserFormData> = useCallback(
    async ({
      birthDate,
      cpf,
      email,
      gender,
      name,
      phone,
      role,
      selectedSeller,
    }) => {
      try {
        const updatedUser = await updateUsersService({
          userId,
          birthDate,
          cpf,
          email,
          gender,
          name,
          phone,
          role,
          sellerId: selectedSeller?.value,
        });

        if (avatar) {
          const formData = new FormData();

          formData.append('avatar', avatar);

          const userWithUpdatedAvatar = await updateUserAvatarsService({
            userId,
            avatarData: formData,
          });

          if (userWithUpdatedAvatar.id === user.id) {
            updateUser(userWithUpdatedAvatar);
          }
        } else if (updatedUser.id === user.id) {
          updateUser(updatedUser);
        }

        toast({
          title: 'Editado com sucesso',
          description: 'O usuário foi editado corretamente',
          status: 'success',
          duration: 3000,
          isClosable: true,
          variant: 'subtle',
          position: 'top-right',
        });

        goBack();
      } catch (err) {
        if (axios.isAxiosError(err) && err.response?.status !== 401) {
          toast({
            title: 'Falha ao editar',
            description:
              translateError({ message: err.response?.data.message }) ||
              'Ocorreu um erro ao editar o usuário, tente novamente',
            status: 'error',
            duration: 3000,
            isClosable: true,
            variant: 'subtle',
            position: 'top-right',
          });
        }
      }
    },
    [avatar, userId, goBack, toast, updateUser, user.id],
  );

  return (
    <DefaultLayout>
      <DeleteConfirmationModal
        isOpen={isDeleteConfirmationModalVisible}
        onClose={handleToggleDeleteConfirmationModal}
        onConfirm={handleDeleteAvatar}
      />

      <Box
        as="form"
        flex="1"
        borderRadius={8}
        bg="white"
        p="8"
        onSubmit={handleSubmit(handleUpdateUser)}
      >
        <Heading size="lg" fontWeight="normal">
          Editar usuário
        </Heading>

        <Divider my="6" borderColor="gray.300" />

        <Flex justify="center" mb="8">
          <AvatarDropzone
            avatarUrl={avatarUrl}
            onChange={handleChangeAvatar}
            onDelete={handleToggleDeleteConfirmationModal}
          />
        </Flex>

        <VStack spacing="8">
          <Grid
            templateColumns={[
              'repeat(1, 1fr)',
              'repeat(1, 1fr)',
              'repeat(1, 1fr)',
              'repeat(1, 1fr)',
              'repeat(12, 1fr)',
            ]}
            gap="8"
            width="100%"
          >
            <GridItem colSpan={[12, 12, 12, 12, 8]}>
              <MaskedInput
                label="Nome completo"
                error={errors.name}
                {...register('name')}
              />
            </GridItem>

            <GridItem colSpan={[12, 12, 12, 12, 4]}>
              <ReactSelect
                name="gender"
                label="Gênero"
                control={control}
                error={errors.gender}
                options={userGendersSelectOption}
              />
            </GridItem>
          </Grid>

          <Grid
            templateColumns={[
              'repeat(1, 1fr)',
              'repeat(1, 1fr)',
              'repeat(1, 1fr)',
              'repeat(1, 1fr)',
              'repeat(12, 1fr)',
            ]}
            gap="8"
            width="100%"
          >
            <GridItem colSpan={[12, 12, 12, 12, 4]}>
              <ReactSelect
                name="role"
                label="Tipo"
                control={control}
                error={errors.role}
                options={userRolesSelectOption}
              />
            </GridItem>

            <GridItem colSpan={[12, 12, 12, 12, 8]}>
              <AsyncSelect
                label="Loja"
                name="selectedSeller"
                loadOptions={handleLoadSellerSelectOption}
                control={control}
                error={errors.selectedSeller}
              />
            </GridItem>
          </Grid>

          <SimpleGrid minChildWidth="240px" spacing="8" w="100%">
            <MaskedInput
              label="E-mail"
              type="email"
              textTransform="lowercase"
              error={errors.email}
              {...register('email')}
            />

            <InternationalPhoneInput
              label="Telefone"
              name="phone"
              control={control}
              error={errors.phone}
            />
          </SimpleGrid>

          <SimpleGrid minChildWidth="240px" spacing="8" w="100%">
            <MaskedInput
              label="CPF"
              mask="cpf"
              error={errors.cpf}
              {...register('cpf')}
            />

            <DatePicker
              label="Data de nascimento"
              isClearable={false}
              maxDate={new Date()}
              control={control}
              error={errors.birthDate}
              {...register('birthDate')}
            />
          </SimpleGrid>
        </VStack>

        <Flex mt="12" justify="flex-end">
          <ButtonGroup>
            <Button colorScheme="blackAlpha" onClick={goBack}>
              Cancelar
            </Button>
            <Button
              type="submit"
              colorScheme="green"
              isLoading={formState.isSubmitting}
            >
              Salvar
            </Button>
          </ButtonGroup>
        </Flex>
      </Box>
    </DefaultLayout>
  );
};

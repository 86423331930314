import { IAdvertisementBase } from '../../models/advertisements';
import { IUserBase } from '../../models/users';
import cjPontanegraApi from '../apis/cjPontanegraApi';

interface IUpdateUserProps {
  userId: string;
  birthDate: Date;
  cpf: string;
  email: string;
  gender: 'FEMALE' | 'MALE' | 'OTHER' | 'UNDEFINED';
  name: string;
  phone?: string;
  role: 'ADMIN' | 'EMPLOYEE' | 'CUSTOMER';
  sellerId?: string | null;
}

export interface IUpdatedUser extends IUserBase {
  favorites: IAdvertisementBase[];
}

export const updateUsersService = async ({
  userId,
  birthDate,
  cpf,
  email,
  gender,
  name,
  phone,
  role,
  sellerId,
}: IUpdateUserProps): Promise<IUpdatedUser> => {
  const { data } = await cjPontanegraApi.put<IUpdatedUser>(`/users/${userId}`, {
    birthDate,
    cpf,
    email,
    gender,
    name,
    phone,
    role,
    sellerId,
  });

  return data;
};

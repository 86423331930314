import { Box, Heading, Flex, useToast, Icon } from '@chakra-ui/react';
import { RiAddLine } from 'react-icons/ri';
import { useEffect, useState, useCallback } from 'react';
import axios from 'axios';
import debounce from 'lodash.debounce';
import { DefaultLayout } from '../../../_layout/DefaultLayout';
import { LinkButton } from '../../../../../components/LinkButton';
import { maskShortDate } from '../../../../../utils/formatters/handleMask';
import {
  IAdvertisementCategory,
  AdvertisementCategoryTable,
} from './components/AdvertisementCategoryTable';
import { listAdvertisementCategoriesService } from '../../../../../services/AdvertisementCategories/ListAdvertisementCategoriesService';
import { translateError } from '../../../../../utils/errors';

interface ILoadAdvertisementCategoriesProps {
  name?: string;
  sort?: 'isActive' | 'name' | 'showOrder' | 'createdAt';
  order?: 'ASC' | 'DESC';
  limit?: number;
  page?: number;
}

export const AdvertisementCategoryList = (): JSX.Element => {
  const toast = useToast();

  const [advertisementCategoriesList, setAdvertisementCategoriesList] =
    useState<IAdvertisementCategory[]>([]);
  const [currentFilters, setCurrentFilters] =
    useState<{ [key: string]: string }>();
  const [currentOrdering, setCurrentOrdering] = useState<'ASC' | 'DESC'>('ASC');
  const [currentPage, setCurrentPage] = useState(1);
  const [filterBy, setFilterBy] = useState('name');
  const [sortBy, setSortBy] = useState<
    'isActive' | 'name' | 'showOrder' | 'createdAt'
  >('createdAt');
  const [totalPages, setTotalPages] = useState<number>();

  const loadAdvertisementCategories = useCallback(
    async ({
      name,
      limit = 20,
      order,
      page,
      sort,
    }: ILoadAdvertisementCategoriesProps) => {
      try {
        const advertisementCategories =
          await listAdvertisementCategoriesService({
            name: name || undefined,
            limit,
            order,
            page,
            sort,
          });

        const advertisementCategoriesWithformattedDate =
          advertisementCategories.items.map((advertisementCategory) => ({
            ...advertisementCategory,
            formattedDate: maskShortDate(advertisementCategory.createdAt),
          }));

        setTotalPages(advertisementCategories.pages);
        setAdvertisementCategoriesList(
          advertisementCategoriesWithformattedDate,
        );
      } catch (err) {
        if (axios.isAxiosError(err) && err.response?.status !== 401) {
          toast({
            title: 'Falha ao carregar dados',
            description:
              translateError({ message: err.response?.data.message }) ||
              'Ocorreu um erro ao carregar os dados das categorias, tente novamente',
            status: 'error',
            duration: 3000,
            isClosable: true,
            variant: 'subtle',
            position: 'top-right',
          });
        }
      }
    },
    [toast],
  );

  useEffect(() => {
    loadAdvertisementCategories({
      ...currentFilters,
      order: currentOrdering,
      page: currentPage,
      sort: sortBy,
    });
  }, [
    currentFilters,
    currentPage,
    sortBy,
    loadAdvertisementCategories,
    currentOrdering,
  ]);

  const handleFilterBy = useCallback((value: string) => {
    setFilterBy(value);
  }, []);

  const handleFilter = useCallback(
    (value: string) => {
      setCurrentFilters({ [filterBy]: value });
    },
    [filterBy],
  );

  const debounceHandleFilter = debounce(handleFilter, 1000);

  const handleSortList = useCallback(
    (sort: 'isActive' | 'name' | 'showOrder' | 'createdAt') => {
      setSortBy(sort);
    },
    [],
  );

  const handleOrderList = useCallback((order: 'ASC' | 'DESC') => {
    setCurrentOrdering(order);
  }, []);

  const handlePageChange = useCallback((page: number) => {
    setCurrentPage(page);
  }, []);

  return (
    <DefaultLayout>
      <Box flex="1" borderRadius={8} bg="white" p="8">
        <Flex justify="space-between" align="center">
          <Heading size="lg" fontWeight="normal">
            Categorias
          </Heading>

          <LinkButton
            colorScheme="green"
            icon={<Icon as={RiAddLine} fontSize="16" />}
            to="/categories/register"
          >
            Cadastrar nova
          </LinkButton>
        </Flex>

        <AdvertisementCategoryTable
          mt="4"
          advertisementCategories={advertisementCategoriesList}
          currentPage={currentPage}
          filterBy={filterBy}
          onChangeFilterBy={handleFilterBy}
          onFilter={debounceHandleFilter}
          onOrder={handleOrderList}
          onPageChange={handlePageChange}
          onSort={handleSortList}
          order={currentOrdering}
          sortBy={sortBy}
          totalPages={totalPages}
        />
      </Box>
    </DefaultLayout>
  );
};

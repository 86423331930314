import { useState, ChangeEvent, useEffect } from 'react';

import {
  Input,
  InputGroup,
  InputRightAddon,
  InputProps,
  Select,
} from '@chakra-ui/react';
import { scaleOptions, getOptimalScaleValue } from './useTimeInputOptions';

export interface ITimeInputProps extends InputProps {
  value?: number;
  defaultScale?: string;
  onChangeTimeValue: (value?: number | null) => void;
}

export const TimeInput = ({
  defaultScale = 'Minutos',
  isDisabled,
  onChangeTimeValue,
  value,
  ...rest
}: ITimeInputProps): JSX.Element => {
  const [currentTime, setCurrentTime] = useState<number>();
  const [currentScale, setCurrentScale] = useState({
    label: 'Minutos',
    value: 1,
  });

  useEffect(() => {
    const defaultScaleOption = scaleOptions.find(
      (scale) => scale.label === defaultScale,
    );

    if (defaultScaleOption) {
      setCurrentScale(defaultScaleOption);
    }
  }, [defaultScale]);

  useEffect(() => {
    if (value) {
      const optimalScaleValue = getOptimalScaleValue(value);

      if (optimalScaleValue) {
        const { optimalScale, optimalValue } = optimalScaleValue;

        setCurrentScale(optimalScale);

        setCurrentTime(optimalValue);
      }
    }
  }, [value]);

  const onChangeScale = (e: ChangeEvent<HTMLSelectElement>): void => {
    const selectedScale = scaleOptions.find(
      (scale) => scale.value === Number(e.target.value),
    );

    if (!selectedScale) {
      return;
    }

    setCurrentScale(selectedScale);

    if (currentTime) {
      const affectedValue = currentTime * selectedScale.value;

      onChangeTimeValue(affectedValue);
    }
  };

  const onChangeValue = (e: ChangeEvent<HTMLInputElement>): void => {
    const digitsValue = e.target.value.replace(/\D/g, '');

    if (digitsValue.length) {
      const numberValue = Number(digitsValue);

      setCurrentTime(numberValue);

      onChangeTimeValue(numberValue * (currentScale?.value || 1));
    } else {
      setCurrentTime(undefined);

      onChangeTimeValue(null);
    }
  };

  return (
    <InputGroup size="lg">
      <Input
        borderColor="gray.300"
        borderRightRadius={0}
        bg="gray.100"
        focusBorderColor="blue.300"
        isDisabled={isDisabled}
        variant="outline"
        size="lg"
        value={currentTime}
        onChange={onChangeValue}
        {...rest}
      />
      <InputRightAddon p={0}>
        <Select
          isDisabled={isDisabled}
          variant="outline"
          size="lg"
          borderColor="gray.300"
          borderLeftWidth={0}
          borderLeftRadius={0}
          bg="gray.100"
          focusBorderColor="blue.300"
          zIndex={1}
          value={currentScale?.value}
          onChange={onChangeScale}
        >
          {scaleOptions.map((option) => (
            <option key={option.value} value={option.value}>
              {option.label}
            </option>
          ))}
        </Select>
      </InputRightAddon>
    </InputGroup>
  );
};

import cjPontanegraApi from '../apis/cjPontanegraApi';
import { IDetailedUser } from '../Users/ShowUsersService';

interface IResetPasswordProps {
  password: string;
  passwordConfirmation: string;
  token: string;
}

export const resetPasswordsService = async ({
  password,
  passwordConfirmation,
  token,
}: IResetPasswordProps): Promise<IDetailedUser> => {
  const { data } = await cjPontanegraApi.post<IDetailedUser>(
    '/passwords/reset',
    {
      password,
      passwordConfirmation,
      token,
    },
  );

  return data;
};

type Scale = {
  label: string;
  value: number;
};

type OptimalScaleValue = {
  optimalScale: Scale;
  optimalValue: number;
};

export const scaleOptions = [
  {
    label: 'Minutos',
    value: 1,
  },
  {
    label: 'Horas',
    value: 60, // minutes
  },
  {
    label: 'Dias',
    value: 60 * 24, // minutes * hours
  },
  {
    label: 'Meses (30 dias)',
    value: 60 * 24 * 30, // minutes * hours * days (30)
  },
  {
    label: 'Anos',
    value: 60 * 24 * 365, // minutes * hours * days
  },
];

export const getOptimalScaleValue = (value: number): OptimalScaleValue => {
  const descendingScaleOptions = scaleOptions.sort((a, b) => b.value - a.value);

  const optimalScale = descendingScaleOptions.find(
    (scale) => !(Number(value) % scale.value),
  ) || { label: 'Minutos', value: 1 };

  const optimalValue = Number(value) / optimalScale.value;

  return {
    optimalScale,
    optimalValue,
  };
};

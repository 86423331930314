import { IAdvertisementBase } from '../../models/advertisements';
import { ISellerBase } from '../../models/sellers';
import cjPontanegraApi from '../apis/cjPontanegraApi';

interface IUpdateSellerAvatarProps {
  sellerId: string;
  avatarData: FormData;
}

export interface IUpdatedSellerAvatar extends ISellerBase {
  advertisements?: IAdvertisementBase[];
}

export const updateSellerAvatarsService = async ({
  avatarData,
  sellerId,
}: IUpdateSellerAvatarProps): Promise<IUpdatedSellerAvatar> => {
  const { data } = await cjPontanegraApi.patch<IUpdatedSellerAvatar>(
    `/avatars/sellers/${sellerId}`,
    avatarData,
  );

  return data;
};

import { IUserBase } from '../../models/users';
import cjPontanegraApi from '../apis/cjPontanegraApi';

export const createProfileConfirmationsService = async (
  userId: string,
): Promise<IUserBase> => {
  const { data } = await cjPontanegraApi.post<IUserBase>(
    `/users/${userId}/profile-confirmation`,
  );

  return data;
};

import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  ModalFooter,
  Button,
  HStack,
  Text,
  Heading,
} from '@chakra-ui/react';

interface IProfileConfirmationModalProps {
  isOpen: boolean;
  onClose: () => void;
  onConfirm: () => void;
}

export const ProfileConfirmationModal = ({
  isOpen,
  onClose,
  onConfirm,
}: IProfileConfirmationModalProps): JSX.Element => (
  <Modal size="lg" scrollBehavior="inside" isOpen={isOpen} onClose={onClose}>
    <ModalOverlay />
    <ModalContent>
      <ModalHeader>
        <Heading size="lg" fontWeight="normal">
          Enviar notificação
        </Heading>
      </ModalHeader>
      <ModalCloseButton />
      <ModalBody>
        <Text>Deseja reenviar a notificação?</Text>
      </ModalBody>

      <ModalFooter>
        <HStack>
          <Button colorScheme="blackAlpha" onClick={onClose}>
            Cancelar
          </Button>
          <Button colorScheme="green" onClick={onConfirm}>
            Confirmar
          </Button>
        </HStack>
      </ModalFooter>
    </ModalContent>
  </Modal>
);

import {
  IAdvertisementBase,
  IAdvertisementCategoryBase,
} from '../../models/advertisements';
import cjPontanegraApi from '../apis/cjPontanegraApi';

interface IListAdvertisementCategoryProps {
  isActive?: boolean;
  name?: string;
  limit?: number;
  order?: 'ASC' | 'DESC';
  page?: number;
  sort?: 'isActive' | 'name' | 'showOrder' | 'createdAt';
}

export interface IAdvertisementCategoryListItem
  extends IAdvertisementCategoryBase {
  advertisements: IAdvertisementBase[];
}

export interface IAdvertisementCategoryList {
  items: IAdvertisementCategoryListItem[];
  pages: number;
  total: number;
}

export const listAdvertisementCategoriesService = async (
  filters?: IListAdvertisementCategoryProps,
): Promise<IAdvertisementCategoryList> => {
  const params =
    filters && Object.keys(filters).length !== 0
      ? {
          name: filters.name || undefined,
          isActive: filters.isActive,
          sort: filters.sort,
          order: filters.order,
          limit: filters.limit,
          page: filters.page,
        }
      : undefined;

  const { data } = await cjPontanegraApi.get<IAdvertisementCategoryList>(
    '/announcements/categories',
    {
      params,
    },
  );

  return data;
};

import { useCallback, useState, useMemo, useEffect } from 'react';
import { useHistory } from 'react-router';
import { SubmitHandler, useForm } from 'react-hook-form';
import * as Yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import {
  Box,
  Heading,
  Divider,
  VStack,
  SimpleGrid,
  Flex,
  Button,
  useToast,
  ButtonGroup,
  Grid,
  GridItem,
} from '@chakra-ui/react';

import axios from 'axios';
import { startOfDay } from 'date-fns';
import { AvatarDropzone } from '../../../../../components/Form/AvatarDropzone';
import { MaskedInput } from '../../../../../components/Form/MaskedInput';
import { DefaultLayout } from '../../../_layout/DefaultLayout';
import { InternationalPhoneInput } from '../../../../../components/Form/InternationalPhoneInput';
import { createUsersService } from '../../../../../services/Users/CreateUsersService';
import { updateUserAvatarsService } from '../../../../../services/Users/UpdateUserAvatarsService';
import { ReactSelect } from '../../../../../components/Form/ReactSelect';
import {
  AsyncSelect,
  SelectOption,
} from '../../../../../components/Form/AsyncSelect';
import { listSellersService } from '../../../../../services/Sellers/ListSellersService';
import { translateError } from '../../../../../utils/errors';
import { DatePicker } from '../../../../../components/Form/DatePicker';

type NewUserFormData = {
  birthDate: Date;
  cpf: string;
  email: string;
  gender: 'FEMALE' | 'MALE' | 'OTHER' | 'UNDEFINED';
  name: string;
  phone?: string;
  role: 'ADMIN' | 'EMPLOYEE' | 'CUSTOMER';
  sellerId?: string;
};

const registerUserFormSchema = Yup.object().shape({
  birthDate: Yup.date()
    .max(startOfDay(new Date()), 'Data inválida')
    .nullable()
    .required('Requerido'),
  cpf: Yup.string()
    .required('CPF requerido')
    .length(11, 'Valor inválido, requerido 11 dígitos')
    .transform((_, originalValue) => originalValue.replace(/\D/g, '')),
  email: Yup.string()
    .email('E-mail inválido')
    .required('E-mail requerido')
    .transform((value) => value.toLowerCase()),
  gender: Yup.string().required('Gênero requerido'),
  name: Yup.string().required('Nome requerido'),
  phone: Yup.string()
    .nullable()
    .transform((value, originalValue) => (originalValue === '' ? null : value)),
  role: Yup.string()
    .oneOf(['ADMIN', 'EMPLOYEE', 'CUSTOMER'], 'Inválido')
    .required('Requerido'),
  sellerId: Yup.string().when('role', {
    is: (val: 'ADMIN' | 'EMPLOYEE' | 'CUSTOMER') => val !== 'ADMIN',
    then: Yup.string()
      .uuid()
      .nullable()
      .required('Loja requerida')
      .transform((value, originalValue) =>
        originalValue === '' ? null : value.value,
      ),
    otherwise: Yup.string()
      .uuid()
      .nullable()
      .transform((value, originalValue) =>
        originalValue === '' ? null : value.value,
      ),
  }),
});

export const UserRegister = (): JSX.Element => {
  const { goBack, push } = useHistory();
  const toast = useToast();

  const [avatar, setAvatar] = useState<File>();
  const [avatarUrl, setAvatarUrl] = useState<string>();
  const userRolesSelectOption = useMemo(
    () => [
      {
        label: 'ADM Master',
        value: 'ADMIN',
      },
      {
        label: 'Lojista',
        value: 'EMPLOYEE',
      },
    ],
    [],
  );

  const userGendersSelectOption = useMemo(
    () => [
      {
        label: 'Feminino',
        value: 'FEMALE',
      },
      {
        label: 'Masculino',
        value: 'MALE',
      },
      {
        label: 'Outros',
        value: 'OTHERS',
      },
      {
        label: 'Não informado',
        value: 'UNDEFINED',
      },
    ],
    [],
  );

  const { register, handleSubmit, formState, control, setValue } = useForm({
    resolver: yupResolver(registerUserFormSchema),
  });

  const { errors } = formState;

  useEffect(() => {
    setValue('gender', 'UNDEFINED');
  }, [setValue]);

  const handleChangeAvatar = useCallback((file: File) => {
    setAvatar(file);
    setAvatarUrl(URL.createObjectURL(file));
  }, []);

  const handleDeleteAvatar = useCallback(() => {
    setAvatar(undefined);
    setAvatarUrl(undefined);
  }, []);

  const handleLoadSellerSelectOption = useCallback(
    async (name?: string): Promise<SelectOption[]> => {
      const { items: sellers } = await listSellersService({ name, limit: 4 });

      const parsedSellersSelectOption: SelectOption[] = [
        {
          label: ' ',
          value: null,
        },
        ...sellers.map((seller) => ({
          label: seller.name,
          value: seller.id,
        })),
      ];

      return parsedSellersSelectOption;
    },
    [],
  );

  const handleNewUser: SubmitHandler<NewUserFormData> = useCallback(
    async ({ birthDate, cpf, email, gender, name, phone, role, sellerId }) => {
      try {
        const user = await createUsersService({
          birthDate,
          cpf,
          email,
          gender,
          name,
          phone,
          role,
          sellerId,
        });

        if (avatar) {
          const formData = new FormData();

          formData.append('avatar', avatar);

          await updateUserAvatarsService({
            avatarData: formData,
            userId: user.id,
          });
        }

        toast({
          title: 'Cadastrado com sucesso',
          description: 'O usuário foi cadastrado corretamente',
          status: 'success',
          duration: 3000,
          isClosable: true,
          variant: 'subtle',
          position: 'top-right',
        });

        push('/users/details', { userId: user.id });
      } catch (err) {
        if (axios.isAxiosError(err) && err.response?.status !== 401) {
          toast({
            title: 'Falha no cadastro',
            description:
              translateError({ message: err.response?.data.message }) ||
              'Ocorreu um erro ao cadastrar o usuário, tente novamente',
            status: 'error',
            duration: 3000,
            isClosable: true,
            variant: 'subtle',
            position: 'top-right',
          });
        }
      }
    },
    [avatar, push, toast],
  );

  return (
    <DefaultLayout>
      <Box
        as="form"
        flex="1"
        borderRadius={8}
        bg="white"
        p="8"
        onSubmit={handleSubmit(handleNewUser)}
      >
        <Heading size="lg" fontWeight="normal">
          Cadastrar usuário
        </Heading>

        <Divider my="6" borderColor="gray.300" />

        <Flex justify="center" mb="8">
          <AvatarDropzone
            avatarUrl={avatarUrl}
            onChange={handleChangeAvatar}
            onDelete={handleDeleteAvatar}
          />
        </Flex>

        <VStack spacing="8">
          <Grid
            templateColumns={[
              'repeat(1, 1fr)',
              'repeat(1, 1fr)',
              'repeat(1, 1fr)',
              'repeat(1, 1fr)',
              'repeat(12, 1fr)',
            ]}
            gap="8"
            width="100%"
          >
            <GridItem colSpan={[12, 12, 12, 12, 8]}>
              <MaskedInput
                label="Nome completo"
                error={errors.name}
                {...register('name')}
              />
            </GridItem>

            <GridItem colSpan={[12, 12, 12, 12, 4]}>
              <ReactSelect
                name="gender"
                label="Gênero"
                control={control}
                error={errors.gender}
                options={userGendersSelectOption}
              />
            </GridItem>
          </Grid>

          <Grid
            templateColumns={[
              'repeat(1, 1fr)',
              'repeat(1, 1fr)',
              'repeat(1, 1fr)',
              'repeat(1, 1fr)',
              'repeat(12, 1fr)',
            ]}
            gap="8"
            width="100%"
          >
            <GridItem colSpan={[12, 12, 12, 12, 4]}>
              <ReactSelect
                name="role"
                label="Tipo"
                control={control}
                error={errors.role}
                options={userRolesSelectOption}
              />
            </GridItem>

            <GridItem colSpan={[12, 12, 12, 12, 8]}>
              <AsyncSelect
                label="Loja"
                name="sellerId"
                loadOptions={handleLoadSellerSelectOption}
                control={control}
                error={errors.sellerId}
              />
            </GridItem>
          </Grid>

          <SimpleGrid minChildWidth="240px" spacing="8" w="100%">
            <MaskedInput
              label="E-mail"
              type="email"
              textTransform="lowercase"
              error={errors.email}
              {...register('email')}
            />

            <InternationalPhoneInput
              label="Telefone"
              name="phone"
              control={control}
              error={errors.phone}
            />
          </SimpleGrid>

          <SimpleGrid minChildWidth="240px" spacing="8" w="100%">
            <MaskedInput
              label="CPF"
              mask="cpf"
              error={errors.cpf}
              {...register('cpf')}
            />

            <DatePicker
              label="Data de nascimento"
              isClearable={false}
              maxDate={new Date()}
              control={control}
              error={errors.birthDate}
              {...register('birthDate')}
            />
          </SimpleGrid>
        </VStack>

        <Flex mt="12" justify="flex-end">
          <ButtonGroup>
            <Button colorScheme="blackAlpha" onClick={goBack}>
              Cancelar
            </Button>
            <Button
              type="submit"
              colorScheme="green"
              isLoading={formState.isSubmitting}
            >
              Salvar
            </Button>
          </ButtonGroup>
        </Flex>
      </Box>
    </DefaultLayout>
  );
};

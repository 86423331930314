import Chart from 'react-apexcharts';
import { Text } from '@chakra-ui/react';
import useAppDownloadChartOptions from './useAppDownloadChartOptions';

type SeriesData = { series: number[]; labels: string[] };

interface IAppDownloadChartProps {
  appDownloads: SeriesData;
}

const AppDownloadChart = ({
  appDownloads,
}: IAppDownloadChartProps): JSX.Element => {
  const appDownloadOptions = useAppDownloadChartOptions(appDownloads);

  return (
    <>
      <Text fontSize="lg">Downloads do aplicativo</Text>
      {!!appDownloadOptions.series?.length && (
        <Chart
          options={appDownloadOptions}
          series={appDownloadOptions.series}
          type="bar"
          height={360}
        />
      )}
    </>
  );
};

export default AppDownloadChart;

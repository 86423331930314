import { useHistory, useLocation } from 'react-router-dom';
import {
  Box,
  Heading,
  Flex,
  Button,
  Icon,
  Text,
  SimpleGrid,
  Avatar,
  useToast,
  ButtonGroup,
  HStack,
} from '@chakra-ui/react';
import { RiDeleteBinLine, RiEditLine, RiMailSendLine } from 'react-icons/ri';
import { useCallback, useEffect, useState } from 'react';
import axios from 'axios';
import { DefaultLayout } from '../../../_layout/DefaultLayout';
import { DeleteConfirmationModal } from '../../../../../components/DeleteConfirmationModal';
import { LinkButton } from '../../../../../components/LinkButton';
import { maskCpf, maskDate } from '../../../../../utils/formatters/handleMask';
import {
  IDetailedUser,
  showUsersService,
} from '../../../../../services/Users/ShowUsersService';
import { deleteUsersService } from '../../../../../services/Users/DeleteUsersService';
import { UserGenders, UserRoles } from '../../../../../models/users';
import { createProfileConfirmationsService } from '../../../../../services/Users/CreateProfileConfirmationsService';
import { ProfileConfirmationModal } from '../../../../../components/ProfileConfirmationModal';
import { translateError } from '../../../../../utils/errors';

interface IUser extends IDetailedUser {
  formattedBirthDate: string;
  formattedDate: string;
}

interface ILocationState {
  userId: string;
}

export const UserDetails = (): JSX.Element => {
  const toast = useToast();
  const {
    state: { userId },
  } = useLocation<ILocationState>();
  const { push } = useHistory();

  const [user, setUser] = useState<IUser>();
  const [
    isSendProfileConfirmationModalVisible,
    setIsSendProfileConfirmationModalVisible,
  ] = useState(false);
  const [
    isDeleteConfirmationModalVisible,
    setIsDeleteConfirmationModalVisible,
  ] = useState(false);

  useEffect(() => {
    async function loadUser(): Promise<void> {
      try {
        const userData = await showUsersService({ userId });

        const parsedUser = {
          ...userData,
          cpf: maskCpf(userData.cpf).toString(),
          formattedBirthDate: maskDate(userData.birthDate),
          formattedDate: maskDate(userData.createdAt),
        };

        setUser(parsedUser);
      } catch (err) {
        if (axios.isAxiosError(err) && err.response?.status !== 401) {
          toast({
            title: 'Falha ao carregar dados',
            description:
              translateError({ message: err.response?.data.message }) ||
              'Ocorreu um erro ao carregar os detalhes do usuário, tente novamente',
            status: 'error',
            duration: 3000,
            isClosable: true,
            variant: 'subtle',
            position: 'top-right',
          });
        }
      }
    }

    loadUser();
  }, [userId, toast]);

  const handleToggleProfileConfirmationModal = useCallback(() => {
    setIsSendProfileConfirmationModalVisible((prevState) => !prevState);
  }, []);

  const handleToggleDeleteConfirmationModal = useCallback(() => {
    setIsDeleteConfirmationModalVisible((prevState) => !prevState);
  }, []);

  const handleProfileConfirmation = useCallback(async () => {
    try {
      if (user) {
        await createProfileConfirmationsService(user.id);
      }

      toast({
        title: 'Notificado com sucesso',
        description: 'O usuário foi notificado corretamente',
        status: 'success',
        duration: 3000,
        isClosable: true,
        variant: 'subtle',
        position: 'top-right',
      });

      handleToggleProfileConfirmationModal();
    } catch (err) {
      if (axios.isAxiosError(err) && err.response?.status !== 401) {
        toast({
          title: 'Falha ao notificar',
          description:
            translateError({ message: err.response?.data.message }) ||
            'Ocorreu um erro ao enviar a notificação, tente novamente',
          status: 'error',
          duration: 3000,
          isClosable: true,
          variant: 'subtle',
          position: 'top-right',
        });
      }
    }
  }, [user, toast, handleToggleProfileConfirmationModal]);

  const handleDeleteUser = useCallback(async () => {
    try {
      await deleteUsersService(userId);

      toast({
        title: 'Excluído com sucesso',
        description: 'O usuário foi excluído corretamente',
        status: 'success',
        duration: 3000,
        isClosable: true,
        variant: 'subtle',
        position: 'top-right',
      });

      push('/users');
    } catch (err) {
      if (axios.isAxiosError(err) && err.response?.status !== 401) {
        toast({
          title: 'Falha ao excluir',
          description:
            translateError({ message: err.response?.data.message }) ||
            'Ocorreu um erro ao excluir o usuário, tente novamente',
          status: 'error',
          duration: 3000,
          isClosable: true,
          variant: 'subtle',
          position: 'top-right',
        });
      }
    }
  }, [userId, push, toast]);

  return (
    <DefaultLayout>
      <DeleteConfirmationModal
        isOpen={isDeleteConfirmationModalVisible}
        onClose={handleToggleDeleteConfirmationModal}
        onConfirm={handleDeleteUser}
      />

      <ProfileConfirmationModal
        isOpen={isSendProfileConfirmationModalVisible}
        onClose={handleToggleProfileConfirmationModal}
        onConfirm={handleProfileConfirmation}
      />

      <Box flex="1" borderRadius={8} bg="white" p="8">
        <Flex mb="8" justify="space-between" align="center">
          <Heading size="lg" fontWeight="normal">
            Detalhes do usuário
          </Heading>

          <ButtonGroup>
            {user?.confirmPending && (
              <Button
                size="sm"
                fontSize="sm"
                colorScheme="blue"
                onClick={handleToggleProfileConfirmationModal}
                leftIcon={<Icon as={RiMailSendLine} fontSize="20" />}
              >
                Reenviar confirmação
              </Button>
            )}

            <LinkButton
              colorScheme="yellow"
              color="white"
              icon={<Icon as={RiEditLine} fontSize="16" />}
              to={{
                pathname: '/users/update',
                state: {
                  userId,
                },
              }}
            >
              Editar
            </LinkButton>

            <Button
              size="sm"
              fontSize="sm"
              colorScheme="red"
              onClick={handleToggleDeleteConfirmationModal}
              leftIcon={<Icon as={RiDeleteBinLine} fontSize="20" />}
            >
              Excluir
            </Button>
          </ButtonGroup>
        </Flex>

        {user && (
          <Flex direction="column">
            <Flex align="center" direction="column">
              <Avatar size="2xl" name={user.name} src={user.avatarUrl} />

              <Text fontSize="lg" mt="2" color="gray.500">
                {UserRoles[user.role]}
              </Text>

              <Text fontSize="4xl" fontWeight="bold" mt="4">
                {user.name}
              </Text>

              <Text color="gray.500">{user.seller?.name}</Text>
            </Flex>

            <SimpleGrid
              minChildWidth="240px"
              spacing="8"
              px="8"
              mt="4"
              w="100%"
            >
              <HStack>
                <Text>Telefone:</Text>
                <Text as="span" color="gray.500">
                  {user.phone}
                </Text>
              </HStack>

              <HStack>
                <Text>E-mail:</Text>
                <Text as="span" color="gray.500">
                  {user.email}
                </Text>
              </HStack>

              <HStack>
                <Text>CPF:</Text>
                <Text as="span" color="gray.500">
                  {user.cpf}
                </Text>
              </HStack>
            </SimpleGrid>

            <SimpleGrid
              minChildWidth="240px"
              spacing="8"
              px="8"
              mt="4"
              w="100%"
            >
              <Text>
                Gênero:
                <Text as="span" color="gray.500">
                  {UserGenders[user.gender]}
                </Text>
              </Text>

              <Text>
                Data de nascimento:
                <Text as="span" color="gray.500">
                  {user.formattedBirthDate}
                </Text>
              </Text>

              <Text>
                Data de cadastro:
                <Text as="span" color="gray.500">
                  {user.formattedDate}
                </Text>
              </Text>
            </SimpleGrid>
          </Flex>
        )}
      </Box>
    </DefaultLayout>
  );
};

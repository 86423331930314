import {
  IAdvertisementCategoryBase,
  IAdvertisementBase,
} from '../../models/advertisements';
import cjPontanegraApi from '../apis/cjPontanegraApi';

export interface IDetailedAdvertisementCategory
  extends IAdvertisementCategoryBase {
  advertisements: IAdvertisementBase[];
}

export const showAdvertisementCategoriesService = async (
  categoryId: string,
): Promise<IDetailedAdvertisementCategory> => {
  const { data } = await cjPontanegraApi.get<IDetailedAdvertisementCategory>(
    `/announcements/categories/${categoryId}`,
  );

  return data;
};

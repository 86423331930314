import { IAdvertisementBase } from '../../models/advertisements';
import { ISellerBase } from '../../models/sellers';
import { IUserBase } from '../../models/users';
import cjPontanegraApi from '../apis/cjPontanegraApi';

interface IUpdateUserAvatarProps {
  userId: string;
  avatarData: FormData;
}

export interface IUpdatedUserAvatar extends IUserBase {
  seller?: ISellerBase;
  favorites?: IAdvertisementBase[];
}

export const updateUserAvatarsService = async ({
  avatarData,
  userId,
}: IUpdateUserAvatarProps): Promise<IUpdatedUserAvatar> => {
  const { data } = await cjPontanegraApi.patch<IUpdatedUserAvatar>(
    `/avatars/users/${userId}`,
    avatarData,
  );

  return data;
};

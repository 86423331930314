import { ChangeEvent, useCallback, useEffect, useState } from 'react';
import { useHistory, useLocation } from 'react-router';
import { SubmitHandler, useForm } from 'react-hook-form';
import * as Yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import {
  Box,
  Heading,
  Divider,
  VStack,
  SimpleGrid,
  Flex,
  Button,
  useToast,
  ButtonGroup,
  FormLabel,
  Switch,
} from '@chakra-ui/react';

import axios from 'axios';
import { AvatarDropzone } from '../../../../../components/Form/AvatarDropzone';
import { MaskedInput } from '../../../../../components/Form/MaskedInput';
import { DefaultLayout } from '../../../_layout/DefaultLayout';
import { DeleteConfirmationModal } from '../../../../../components/DeleteConfirmationModal';
import { showAdvertisementCategoriesService } from '../../../../../services/AdvertisementCategories/ShowAdvertisementCategoriesService';
import { updateAdvertisementCategoriesService } from '../../../../../services/AdvertisementCategories/UpdateAdvertisementCategoriesService';
import { deleteAdvertisementCategoryAvatarsService } from '../../../../../services/AdvertisementCategories/DeleteAdvertisementCategoriesAvatarService';
import { updateAdvertisementCategoryAvatarsService } from '../../../../../services/AdvertisementCategories/UpdateAdvertisementCategoriesAvatarsService';
import { NumberInput } from '../../../../../components/Form/NumberInput';
import { translateError } from '../../../../../utils/errors';

type UpdateAdvertisementCategoryFormData = {
  description?: string;
  name: string;
  showOrder: number;
};

interface ILocationState {
  advertisementCategoryId: string;
}

const advertisementCategoryUpdateFormSchema = Yup.object().shape({
  description: Yup.string()
    .nullable()
    .transform((value, originalValue) => (originalValue === '' ? null : value)),
  name: Yup.string().required('Nome requerido'),
  showOrder: Yup.number()
    .typeError('Requerido valor positivo')
    .integer('Apenas valores inteiros.')
    .min(0, 'Apenas valores positivos')
    .required('Ordem requerida'),
});

export const AdvertisementCategoryUpdate = (): JSX.Element => {
  const { goBack } = useHistory();
  const toast = useToast();
  const { state } = useLocation<ILocationState>();

  const [updatingAdvertisementCategory, setUpdatingAdvertisementCategory] =
    useState<UpdateAdvertisementCategoryFormData>();
  const [avatar, setAvatar] = useState<File>();
  const [avatarUrl, setAvatarUrl] = useState<string>();
  const [
    isDeleteConfirmationModalVisible,
    setIsDeleteConfirmationModalVisible,
  ] = useState(false);
  const [isActive, setIsActive] = useState(false);

  const { register, handleSubmit, formState, reset } = useForm({
    resolver: yupResolver(advertisementCategoryUpdateFormSchema),
  });

  const { errors } = formState;
  const { advertisementCategoryId } = state;

  useEffect(() => {
    async function loadAdvertisementCategory(): Promise<void> {
      try {
        const advertisementCategoryData =
          await showAdvertisementCategoriesService(advertisementCategoryId);
        setAvatarUrl(advertisementCategoryData.iconUrl || undefined);

        setIsActive(advertisementCategoryData.isActive);

        setUpdatingAdvertisementCategory({
          description: advertisementCategoryData.description,
          name: advertisementCategoryData.name,
          showOrder: advertisementCategoryData.showOrder,
        });
      } catch (err) {
        if (axios.isAxiosError(err) && err.response?.status !== 401) {
          toast({
            title: 'Falha ao carregar dados',
            description:
              translateError({ message: err.response?.data.message }) ||
              'Ocorreu um erro ao carregar os dados da categoria, tente novamente',
            status: 'error',
            duration: 3000,
            isClosable: true,
            variant: 'subtle',
            position: 'top-right',
          });
        }
      }
    }

    loadAdvertisementCategory();
  }, [advertisementCategoryId, reset, toast]);

  useEffect(() => {
    reset(updatingAdvertisementCategory);
  }, [reset, updatingAdvertisementCategory]);

  const handleChangeAvatar = useCallback((file: File) => {
    setAvatar(file);
    setAvatarUrl(URL.createObjectURL(file));
  }, []);

  const handleToggleIsActive = useCallback(
    (event: ChangeEvent<HTMLInputElement>) => {
      setIsActive(event.target.checked);
    },
    [],
  );

  const handleToggleDeleteConfirmationModal = useCallback(() => {
    setIsDeleteConfirmationModalVisible((prevState) => !prevState);
  }, []);

  const handleDeleteAvatar = useCallback(async () => {
    await deleteAdvertisementCategoryAvatarsService(advertisementCategoryId);

    setAvatar(undefined);
    setAvatarUrl(undefined);
    handleToggleDeleteConfirmationModal();
  }, [advertisementCategoryId, handleToggleDeleteConfirmationModal]);

  const handleUpdateAdvertisementCategory: SubmitHandler<UpdateAdvertisementCategoryFormData> =
    useCallback(
      async (advertisementCategoryData) => {
        try {
          await updateAdvertisementCategoriesService({
            categoryId: advertisementCategoryId,
            isActive,
            ...advertisementCategoryData,
          });

          if (avatar) {
            const formData = new FormData();

            formData.append('avatar', avatar);

            await updateAdvertisementCategoryAvatarsService({
              categoryId: advertisementCategoryId,
              avatarData: formData,
            });
          }

          toast({
            title: 'Editado com sucesso',
            description: 'A categoria foi editada corretamente',
            status: 'success',
            duration: 3000,
            isClosable: true,
            variant: 'subtle',
            position: 'top-right',
          });

          goBack();
        } catch (err) {
          if (axios.isAxiosError(err) && err.response?.status !== 401) {
            toast({
              title: 'Falha ao editar',
              description:
                translateError({ message: err.response?.data.message }) ||
                'Ocorreu um erro ao editar a categoria, tente novamente',
              status: 'error',
              duration: 3000,
              isClosable: true,
              variant: 'subtle',
              position: 'top-right',
            });
          }
        }
      },
      [advertisementCategoryId, isActive, avatar, toast, goBack],
    );

  return (
    <DefaultLayout>
      <DeleteConfirmationModal
        isOpen={isDeleteConfirmationModalVisible}
        onClose={handleToggleDeleteConfirmationModal}
        onConfirm={handleDeleteAvatar}
      />

      <Box
        as="form"
        flex="1"
        borderRadius={8}
        bg="white"
        p="8"
        onSubmit={handleSubmit(handleUpdateAdvertisementCategory)}
      >
        <Heading size="lg" fontWeight="normal">
          Editar categoria
        </Heading>

        <Divider my="6" borderColor="gray.300" />

        <Flex justify="center" mb="8">
          <AvatarDropzone
            avatarUrl={avatarUrl}
            onChange={handleChangeAvatar}
            onDelete={handleToggleDeleteConfirmationModal}
          />
        </Flex>

        <VStack spacing="8">
          <MaskedInput label="Nome" error={errors.name} {...register('name')} />

          <MaskedInput
            as="textarea"
            minHeight="100px"
            resize="none"
            py="2"
            label="Descrição"
            error={errors.description}
            {...register('description')}
          />

          <SimpleGrid
            alignSelf="flex-start"
            minChildWidth="240px"
            spacing="8"
            w="100%"
          >
            <NumberInput
              label="Ordem de exibição"
              error={errors.showOrder}
              {...register('showOrder')}
            />

            <Box>
              <FormLabel htmlFor="isActive">Ativo</FormLabel>
              <Switch
                name="isActive"
                id="isActive"
                isChecked={isActive}
                onChange={handleToggleIsActive}
              />
            </Box>
          </SimpleGrid>
        </VStack>

        <Flex mt="12" justify="flex-end">
          <ButtonGroup>
            <Button colorScheme="blackAlpha" onClick={goBack}>
              Cancelar
            </Button>
            <Button
              type="submit"
              colorScheme="green"
              isLoading={formState.isSubmitting}
            >
              Salvar
            </Button>
          </ButtonGroup>
        </Flex>
      </Box>
    </DefaultLayout>
  );
};

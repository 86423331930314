import {
  IAdvertisementCategoryBase,
  IAdvertisementBase,
} from '../../models/advertisements';
import cjPontanegraApi from '../apis/cjPontanegraApi';

interface IUpdateAdvertisementCategoryProps {
  categoryId: string;
  description?: string;
  isActive: boolean;
  name: string;
  showOrder: number;
}

export interface IUpdatedAdvertisementCategory
  extends IAdvertisementCategoryBase {
  advertisements: IAdvertisementBase;
}

export const updateAdvertisementCategoriesService = async ({
  categoryId,
  description,
  isActive,
  name,
  showOrder,
}: IUpdateAdvertisementCategoryProps): Promise<IUpdatedAdvertisementCategory> => {
  const { data } = await cjPontanegraApi.put<IUpdatedAdvertisementCategory>(
    `/announcements/categories/${categoryId}`,
    {
      description,
      isActive,
      name,
      showOrder,
    },
  );

  return data;
};

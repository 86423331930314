import { useCallback, useState } from 'react';
import { useHistory } from 'react-router';
import { SubmitHandler, useForm } from 'react-hook-form';
import * as Yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import {
  Box,
  Heading,
  Divider,
  VStack,
  SimpleGrid,
  Flex,
  Button,
  useToast,
  ButtonGroup,
} from '@chakra-ui/react';

import axios from 'axios';
import { AvatarDropzone } from '../../../../../components/Form/AvatarDropzone';
import { MaskedInput } from '../../../../../components/Form/MaskedInput';
import { DefaultLayout } from '../../../_layout/DefaultLayout';
import { createAdvertisementCategoriesService } from '../../../../../services/AdvertisementCategories/CreateAdvertisementCategoriesService';
import { updateAdvertisementCategoryAvatarsService } from '../../../../../services/AdvertisementCategories/UpdateAdvertisementCategoriesAvatarsService';
import { Switch } from '../../../../../components/Form/Switch';
import { NumberInput } from '../../../../../components/Form/NumberInput';
import { translateError } from '../../../../../utils/errors';

type NewAdvertisementCategoryFormData = {
  description?: string;
  isActive: boolean;
  name: string;
  showOrder: number;
};

const registerAdvertisementCategoryFormSchema = Yup.object().shape({
  description: Yup.string()
    .nullable()
    .transform((value, originalValue) => (originalValue === '' ? null : value)),
  isActive: Yup.boolean().required('Requerido'),
  name: Yup.string().required('Nome requerido'),
  showOrder: Yup.number()
    .typeError('Apenas números')
    .integer('Apenas valores inteiros.')
    .min(0)
    .required('Ordem requerida'),
});

export const AdvertisementCategoryRegister = (): JSX.Element => {
  const { goBack, push } = useHistory();
  const toast = useToast();

  const [avatar, setAvatar] = useState<File>();
  const [avatarUrl, setAvatarUrl] = useState<string>();

  const { register, handleSubmit, formState } = useForm({
    resolver: yupResolver(registerAdvertisementCategoryFormSchema),
  });

  const { errors } = formState;

  const handleChangeAvatar = useCallback((file: File) => {
    setAvatar(file);
    setAvatarUrl(URL.createObjectURL(file));
  }, []);

  const handleDeleteAvatar = useCallback(() => {
    setAvatar(undefined);
    setAvatarUrl(undefined);
  }, []);

  const handleNewAdvertisementCategory: SubmitHandler<NewAdvertisementCategoryFormData> =
    useCallback(
      async ({ description, isActive, name, showOrder }) => {
        try {
          const advertisementCategory =
            await createAdvertisementCategoriesService({
              description,
              isActive,
              name,
              showOrder,
            });

          if (avatar) {
            const formData = new FormData();

            formData.append('avatar', avatar);

            await updateAdvertisementCategoryAvatarsService({
              avatarData: formData,
              categoryId: advertisementCategory.id,
            });
          }

          toast({
            title: 'Cadastrado com sucesso',
            description: 'A categoria foi cadastrada corretamente',
            status: 'success',
            duration: 3000,
            isClosable: true,
            variant: 'subtle',
            position: 'top-right',
          });

          push('/categories/details', {
            advertisementCategoryId: advertisementCategory.id,
          });
        } catch (err) {
          if (axios.isAxiosError(err) && err.response?.status !== 401) {
            toast({
              title: 'Falha no cadastro',
              description:
                translateError({ message: err.response?.data.message }) ||
                'Ocorreu um erro ao cadastrar a categoria, tente novamente',
              status: 'error',
              duration: 3000,
              isClosable: true,
              variant: 'subtle',
              position: 'top-right',
            });
          }
        }
      },
      [avatar, push, toast],
    );

  return (
    <DefaultLayout>
      <Box
        as="form"
        flex="1"
        borderRadius={8}
        bg="white"
        p="8"
        onSubmit={handleSubmit(handleNewAdvertisementCategory)}
      >
        <Heading size="lg" fontWeight="normal">
          Cadastrar categoria
        </Heading>

        <Divider my="6" borderColor="gray.300" />

        <Flex justify="center" mb="8">
          <AvatarDropzone
            avatarUrl={avatarUrl}
            onChange={handleChangeAvatar}
            onDelete={handleDeleteAvatar}
          />
        </Flex>

        <VStack spacing="8">
          <MaskedInput label="Nome" error={errors.name} {...register('name')} />

          <MaskedInput
            as="textarea"
            minHeight="100px"
            resize="none"
            py="2"
            label="Descrição"
            error={errors.description}
            {...register('description')}
          />

          <SimpleGrid
            alignSelf="flex-start"
            minChildWidth="240px"
            spacing="8"
            w="50%"
          >
            <NumberInput
              label="Ordem de exibição"
              error={errors.showOrder}
              {...register('showOrder')}
            />

            <Switch
              label="Ativo"
              defaultChecked
              error={errors.isActive}
              {...register('isActive')}
            />
          </SimpleGrid>
        </VStack>

        <Flex mt="12" justify="flex-end">
          <ButtonGroup>
            <Button colorScheme="blackAlpha" onClick={goBack}>
              Cancelar
            </Button>
            <Button
              type="submit"
              colorScheme="green"
              isLoading={formState.isSubmitting}
            >
              Salvar
            </Button>
          </ButtonGroup>
        </Flex>
      </Box>
    </DefaultLayout>
  );
};

import { IAdvertisementBase } from '../../models/advertisements';
import { ISellerBase } from '../../models/sellers';
import cjPontanegraApi from '../apis/cjPontanegraApi';

interface IUpdateSellerProps {
  sellerId: string;
  bio?: string;
  cnpj: string;
  email: string;
  name: string;
  phone?: string;
}

export interface IUpdatedSeller extends ISellerBase {
  advertisements: IAdvertisementBase[];
}

export const updateSellersService = async ({
  sellerId,
  bio,
  cnpj,
  email,
  name,
  phone,
}: IUpdateSellerProps): Promise<IUpdatedSeller> => {
  const { data } = await cjPontanegraApi.put<IUpdatedSeller>(
    `/sellers/${sellerId}`,
    {
      bio,
      cnpj,
      email,
      name,
      phone,
    },
  );

  return data;
};

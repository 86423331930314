import Chart from 'react-apexcharts';
import { Text } from '@chakra-ui/react';
import useAdvertisementClickChartOptions from './useAdvertisementClickChartOptions';

type SeriesData = { series: number[]; labels: string[] };

interface IAdvertisementClickChartProps {
  advertisementClicks: SeriesData;
}

const AdvertisementClickChart = ({
  advertisementClicks,
}: IAdvertisementClickChartProps): JSX.Element => {
  const advertisementClicksOptions =
    useAdvertisementClickChartOptions(advertisementClicks);

  return (
    <>
      <Text fontSize="lg">Cliques por anúncio</Text>
      {!!advertisementClicksOptions.series?.length && (
        <Chart
          options={advertisementClicksOptions}
          series={advertisementClicksOptions.series}
          type="bar"
          height={640}
        />
      )}
    </>
  );
};

export default AdvertisementClickChart;

import { IAdvertisementCouponBase } from '../../models/advertisements';
import cjPontanegraApi from '../apis/cjPontanegraApi';

export type IConsumeCouponsResponse = IAdvertisementCouponBase;

export const consumeCouponsService = async (
  token: string,
): Promise<IConsumeCouponsResponse> => {
  const { data } = await cjPontanegraApi.post<IConsumeCouponsResponse>(
    '/announcements/coupons/consume',
    { token },
  );

  return data;
};

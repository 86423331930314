import { IEntityBase } from './entityBase';

export enum AdvertisementStatusColor {
  APPROVED = 'green.500',
  REJECTED = 'red.500',
  REVIEW_PENDING = 'blue.500',
}

export enum AdvertisementStatus {
  APPROVED = 'Aprovado',
  REJECTED = 'Rejeitado',
  REVIEW_PENDING = 'Aguardando revisão',
}

export interface IAdvertisementCategoryBase extends IEntityBase {
  description?: string;
  icon?: string;
  iconUrl?: string;
  isActive: boolean;
  name: string;
  showOrder: number;
}

export interface IAdvertisementCouponBase extends IEntityBase {
  advertisementId: string;
  discount: number;
  expirationDate?: string;
  isExpired: boolean;
  isUsed: boolean;
  token: string;
  usedAt?: string;
  userId: string;
}

export interface IAdvertisementBase extends IEntityBase {
  avatar?: string;
  avatarUrl?: string;
  categoryId: string;
  couponsAmount?: number;
  couponsAmountAvailable?: number;
  couponsMaxAgeInMinutes?: number;
  couponsMaxUsagePerUser?: number;
  createdCouponsCount: number;
  description?: string;
  discount?: number;
  expirationDate?: string;
  isActive: boolean;
  isExpired: boolean;
  isFavorite: boolean;
  reviewComments?: string;
  sellerId: string;
  status: 'APPROVED' | 'REJECTED' | 'REVIEW_PENDING';
  title: string;
  userCouponsAmountAvailable?: number;
}

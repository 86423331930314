import { ISellerBase } from '../../models/sellers';
import { IUserBase } from '../../models/users';
import cjPontanegraApi from '../apis/cjPontanegraApi';

interface ICreateSessionProps {
  email: string;
  password: string;
}

export interface ISessionUser extends IUserBase {
  seller?: ISellerBase;
}

export interface INewSession {
  user: ISessionUser;
  token: string;
  refreshToken: string;
}

export const authenticateSessionsService = async ({
  email,
  password,
}: ICreateSessionProps): Promise<INewSession> => {
  const { data } = await cjPontanegraApi.post<INewSession>('/sessions', {
    email,
    password,
  });

  return data;
};

import {
  IAdvertisementBase,
  IAdvertisementCategoryBase,
} from '../../models/advertisements';
import { ISellerBase } from '../../models/sellers';
import cjPontanegraApi from '../apis/cjPontanegraApi';

interface IUpdateAdvertisementProps {
  advertisementId: string;
  reviewComments?: string;
  status: 'APPROVED' | 'REJECTED';
}

export interface IUpdatedAdvertisement extends IAdvertisementBase {
  category: IAdvertisementCategoryBase;
  seller: ISellerBase;
}

export const updateAdvertisementsStatusService = async ({
  advertisementId,
  reviewComments,
  status,
}: IUpdateAdvertisementProps): Promise<IUpdatedAdvertisement> => {
  const { data } = await cjPontanegraApi.patch<IUpdatedAdvertisement>(
    `/announcements/${advertisementId}/status`,
    {
      reviewComments,
      status,
    },
  );

  return data;
};

import {
  IAdvertisementBase,
  IAdvertisementCategoryBase,
} from '../../models/advertisements';
import { ISellerBase } from '../../models/sellers';
import cjPontanegraApi from '../apis/cjPontanegraApi';

export interface IDetailedAdvertisement extends IAdvertisementBase {
  category: IAdvertisementCategoryBase;
  seller: ISellerBase;
}

export const showAdvertisementsService = async (
  advertisementId: string,
): Promise<IDetailedAdvertisement> => {
  const { data } = await cjPontanegraApi.get<IDetailedAdvertisement>(
    `/announcements/${advertisementId}`,
  );

  return data;
};

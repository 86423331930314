import {
  IAdvertisementBase,
  IAdvertisementCategoryBase,
} from '../../models/advertisements';
import { ISellerBase } from '../../models/sellers';
import cjPontanegraApi from '../apis/cjPontanegraApi';

export interface IAdvertisementListItem extends IAdvertisementBase {
  category: IAdvertisementCategoryBase;
  seller: ISellerBase;
}

export interface IAdvertisementList {
  items: IAdvertisementListItem[];
  pages: number;
  total: number;
}

interface IListAdvertisementProps {
  categoryId?: string;
  isActive?: boolean;
  isExpired?: boolean;
  search?: string;
  sellerId?: string;
  status?: 'APPROVED' | 'REJECTED' | 'REVIEW_PENDING';
  order?: 'ASC' | 'DESC';
  sort?: 'title' | 'isActive' | 'expirationDate' | 'createdAt';
  limit?: number;
  page?: number;
}

export const listAdvertisementsService = async (
  filters?: IListAdvertisementProps,
): Promise<IAdvertisementList> => {
  const params =
    filters && Object.keys(filters).length !== 0
      ? {
          categoryId: filters.categoryId || undefined,
          isActive: filters.isActive,
          isExpired: filters.isExpired || undefined,
          search: filters.search || undefined,
          sellerId: filters.sellerId || undefined,
          status: filters.status || undefined,
          sort: filters.sort,
          order: filters.order,
          limit: filters.limit,
          page: filters.page,
        }
      : undefined;

  const { data } = await cjPontanegraApi.get<IAdvertisementList>(
    '/announcements',
    {
      params,
    },
  );

  return data;
};

import { Stack } from '@chakra-ui/react';
import {
  RiBriefcase4Line,
  RiContactsLine,
  RiDashboardLine,
  RiInputMethodLine,
  RiShieldUserLine,
  RiMenuLine,
  RiMoneyDollarBoxLine,
} from 'react-icons/ri';
import { useAuth } from '../../hooks/auth';
import { NavLink } from './NavLink';
import { NavSection } from './NavSection';

export const SidebarNav = (): JSX.Element => {
  const { user } = useAuth();

  return (
    <Stack spacing="12" align="flex-start" minWidth="170px">
      <NavSection title="GERAL">
        <NavLink icon={RiDashboardLine} to="/dashboard">
          Dashboard
        </NavLink>

        <NavLink icon={RiInputMethodLine} to="/advertisements">
          Anúncios
        </NavLink>

        <NavLink icon={RiShieldUserLine} to="/customers">
          Clientes
        </NavLink>

        <NavLink icon={RiMoneyDollarBoxLine} to="/coupons">
          Cupons
        </NavLink>
      </NavSection>

      {user.role === 'ADMIN' && (
        <NavSection title="ADMINISTRATIVO">
          <NavLink icon={RiContactsLine} to="/users">
            Usuários
          </NavLink>

          <NavLink icon={RiMenuLine} to="/categories">
            Categorias
          </NavLink>

          <NavLink icon={RiBriefcase4Line} to="/sellers">
            Lojas
          </NavLink>
        </NavSection>
      )}
    </Stack>
  );
};

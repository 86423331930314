import Chart from 'react-apexcharts';
import { Text } from '@chakra-ui/react';
import useUserAgeGroupChartOptions from './useUserAgeGroupChartOptions';

type SeriesData = { series: number[]; labels: string[] };

interface IUserAgeGroupChartProps {
  usersAgeGroups: SeriesData;
}

const UserAgeGroupChart = ({
  usersAgeGroups,
}: IUserAgeGroupChartProps): JSX.Element => {
  const userAgeGroupOptions = useUserAgeGroupChartOptions(usersAgeGroups);

  return (
    <>
      <Text fontSize="lg">Membros por faixa etária</Text>
      {!!userAgeGroupOptions.series?.length && (
        <Chart
          options={userAgeGroupOptions}
          series={userAgeGroupOptions.series}
          type="bar"
          height={360}
        />
      )}
    </>
  );
};

export default UserAgeGroupChart;

import { IAdvertisementBase } from '../../models/advertisements';
import { ISellerBase } from '../../models/sellers';
import { IUserBase } from '../../models/users';
import cjPontanegraApi from '../apis/cjPontanegraApi';

export interface IDetailedFavorites extends IAdvertisementBase {
  seller: ISellerBase;
}

export interface IDetailedUser extends IUserBase {
  seller?: ISellerBase;
  favorites?: IDetailedFavorites[];
}

interface IShowUsersServiceProps {
  favoriteTitle?: string;
  userId: string;
}

export const showUsersService = async ({
  favoriteTitle,
  userId,
}: IShowUsersServiceProps): Promise<IDetailedUser> => {
  const { data } = await cjPontanegraApi.get<IDetailedUser>(
    `/users/${userId}`,
    {
      params: {
        favoriteTitle,
      },
    },
  );

  return data;
};

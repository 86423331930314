import {
  Box,
  Table,
  Text,
  Tbody,
  Td,
  Th,
  Thead,
  Tr,
  BoxProps,
  Icon,
} from '@chakra-ui/react';
import { useMemo } from 'react';
import {
  RiCheckboxBlankCircleLine,
  RiCheckboxCircleLine,
  RiEyeLine,
} from 'react-icons/ri';
import { LinkButton } from '../../../../../../../components/LinkButton';
import { Pagination } from '../../../../../../../components/Pagination';
import { TableFilters } from '../../../../../../../components/TableFilters';
import {
  AdvertisementStatus,
  AdvertisementStatusColor,
} from '../../../../../../../models/advertisements';
import { IAdvertisementListItem } from '../../../../../../../services/Advertisements/ListAdvertisementsService';

export interface IAdvertisement extends IAdvertisementListItem {
  formattedCreatedAt: string;
  formattedExpirationDate?: string;
}

interface IAdvertisementTableProps extends BoxProps {
  advertisements: IAdvertisement[];
  currentPage?: number;
  filterBy?: string;
  onChangeFilterBy?: (value: string) => void;
  onFilter?: (value: string) => void;
  onOrder?: (order: 'ASC' | 'DESC') => void;
  onPageChange?: (page: number) => void;
  onSort?: (
    sort: 'title' | 'isActive' | 'expirationDate' | 'createdAt',
  ) => void;
  order?: 'ASC' | 'DESC';
  sortBy?: string;
  totalPages?: number;
}

export const AdvertisementTable = ({
  advertisements,
  currentPage,
  filterBy,
  onChangeFilterBy,
  onFilter,
  onOrder,
  onPageChange,
  onSort,
  order,
  sortBy,
  totalPages,
  ...rest
}: IAdvertisementTableProps): JSX.Element => {
  const filterByOptions = useMemo(
    () => [
      {
        label: 'Título',
        value: 'title',
      },
      {
        label: 'Status',
        value: 'status',
      },
    ],
    [],
  );

  const filterSelectOptions = useMemo(
    () => [
      {
        label: 'Todos',
        value: '',
      },
      {
        label: 'Aprovado',
        value: 'APPROVED',
      },
      {
        label: 'Rejeitado',
        value: 'REJECTED',
      },
      {
        label: 'Aguardando revisão',
        value: 'REVIEW_PENDING',
      },
    ],
    [],
  );

  const sortByOptions = useMemo(
    () => [
      {
        label: 'Título',
        value: 'title',
      },
      {
        label: 'Ativo',
        value: 'isActive',
      },
      {
        label: 'Data de encerramento',
        value: 'expirationDate',
      },
      {
        label: 'Data de cadastro',
        value: 'createdAt',
      },
    ],
    [],
  );

  return (
    <Box {...rest}>
      <TableFilters
        filterBy={filterBy}
        filterByOptions={filterByOptions}
        filterInputType={filterBy === 'status' ? 'select' : 'input'}
        filterSelectOptions={
          filterBy === 'status' ? filterSelectOptions : undefined
        }
        onChangeFilterBy={onChangeFilterBy}
        onFilter={onFilter}
        onOrder={onOrder}
        onSort={(sort) =>
          !!onSort &&
          onSort(sort as 'title' | 'isActive' | 'expirationDate' | 'createdAt')
        }
        order={order}
        sortBy={sortBy}
        sortByOptions={sortByOptions}
      />

      <Table colorScheme="blue" mt="4">
        <Thead>
          <Tr>
            <Th>Anúncio</Th>
            <Th>Categoria</Th>
            <Th>Status</Th>
            <Th textAlign="center">Ativo</Th>
            <Th textAlign="center">Na Validade</Th>
            <Th textAlign="center">Data de encerramento</Th>
            <Th textAlign="center">Data de cadastro</Th>
            <Th w="8" />
          </Tr>
        </Thead>

        <Tbody>
          {advertisements.map((advertisement) => (
            <Tr key={advertisement.id}>
              <Td>
                <Box>
                  <Text fontWeight="bold">{advertisement.title}</Text>
                  <Text fontSize="sm" color="gray.500">
                    {advertisement.seller.name}
                  </Text>
                </Box>
              </Td>

              <Td>{advertisement.category.name}</Td>

              <Td>
                <Text color={AdvertisementStatusColor[advertisement.status]}>
                  {AdvertisementStatus[advertisement.status]}
                </Text>
              </Td>

              <Td textAlign="center">
                {advertisement.isActive ? (
                  <Icon as={RiCheckboxCircleLine} color="green" />
                ) : (
                  <Icon as={RiCheckboxBlankCircleLine} color="gray.400" />
                )}
              </Td>

              <Td textAlign="center">
                {!advertisement.isExpired ? (
                  <Icon as={RiCheckboxCircleLine} color="green" />
                ) : (
                  <Icon as={RiCheckboxBlankCircleLine} color="gray.400" />
                )}
              </Td>

              <Td textAlign="center">
                {advertisement.formattedExpirationDate}
              </Td>

              <Td textAlign="center">{advertisement.formattedCreatedAt}</Td>

              <Td>
                <LinkButton
                  p={0}
                  to={{
                    pathname: '/advertisements/details',
                    state: {
                      advertisementId: advertisement.id,
                    },
                  }}
                >
                  <Icon as={RiEyeLine} fontSize="16" />
                </LinkButton>
              </Td>
            </Tr>
          ))}
        </Tbody>
      </Table>

      {!!currentPage && !!onPageChange && !!totalPages && totalPages > 1 && (
        <Pagination
          currentPage={currentPage}
          onPageChange={onPageChange}
          totalPages={totalPages}
        />
      )}
    </Box>
  );
};

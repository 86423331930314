import { useCallback, useState, useEffect } from 'react';
import { useHistory } from 'react-router';
import { SubmitHandler, useForm } from 'react-hook-form';
import * as Yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import {
  Box,
  Heading,
  Divider,
  VStack,
  SimpleGrid,
  Flex,
  Button,
  useToast,
  ButtonGroup,
  Icon,
  GridItem,
  Grid,
} from '@chakra-ui/react';
import { RiCloseCircleLine, RiPencilLine } from 'react-icons/ri';
import axios from 'axios';
import { parseISO, startOfDay } from 'date-fns';
import { DefaultLayout } from '../_layout/DefaultLayout';
import { AvatarDropzone } from '../../../components/Form/AvatarDropzone';
import { MaskedInput } from '../../../components/Form/MaskedInput';
import { useAuth } from '../../../hooks/auth';
import { DeleteConfirmationModal } from '../../../components/DeleteConfirmationModal';
import { InternationalPhoneInput } from '../../../components/Form/InternationalPhoneInput';
import { deleteUserAvatarsService } from '../../../services/Users/DeleteUserAvatarsService';
import { updateUserAvatarsService } from '../../../services/Users/UpdateUserAvatarsService';
import { updateUsersProfileService } from '../../../services/Users/UpdateUsersProfileService';
import { maskCpf } from '../../../utils/formatters/handleMask';
import { translateError } from '../../../utils/errors';
import { DatePicker } from '../../../components/Form/DatePicker';
import { ReactSelect } from '../../../components/Form/ReactSelect';

type ProfileFormData = {
  birthDate: Date;
  cpf: string;
  email: string;
  gender: 'FEMALE' | 'MALE' | 'OTHER' | 'UNDEFINED';
  name: string;
  password?: string;
  passwordConfirmation?: string;
  phone?: string;
};

const profileFormSchema = Yup.object().shape({
  birthDate: Yup.date()
    .max(startOfDay(new Date()), 'Data inválida')
    .required('Requerido'),
  cpf: Yup.string()
    .required('Cpf requerido')
    .transform((_, originalValue) => originalValue.replace(/\D/g, '')),
  email: Yup.string()
    .email('E-mail inválido')
    .required('E-mail requerido')
    .transform((value) => value.toLowerCase()),
  gender: Yup.string().required('Gênero requerido'),
  name: Yup.string().required('Nome requerido'),
  password: Yup.string()
    .nullable()
    .transform((value, originalValue) => (originalValue === '' ? null : value)),
  passwordConfirmation: Yup.string()
    .oneOf([null, Yup.ref('password')], 'Senhas não coincidem')
    .nullable()
    .transform((value, originalValue) => (originalValue === '' ? null : value)),
  phone: Yup.string()
    .nullable()
    .transform((value, originalValue) => (originalValue === '' ? null : value)),
});

export const Profile = (): JSX.Element => {
  const { updateUser, user } = useAuth();
  const { push } = useHistory();
  const toast = useToast();

  const { register, handleSubmit, formState, reset, control } = useForm({
    resolver: yupResolver(profileFormSchema),
  });

  const [avatar, setAvatar] = useState<File>();
  const [avatarUrl, setAvatarUrl] = useState(user.avatarUrl);
  const [isInputEnabled, setIsInputEnabled] = useState(false);
  const [
    isDeleteConfirmationModalVisible,
    setIsDeleteConfirmationModalVisible,
  ] = useState(false);

  const userGendersSelectOption = [
    {
      label: 'Feminino',
      value: 'FEMALE',
    },
    {
      label: 'Masculino',
      value: 'MALE',
    },
    {
      label: 'Outros',
      value: 'OTHERS',
    },
    {
      label: 'Não informado',
      value: 'UNDEFINED',
    },
  ];

  const { errors } = formState;

  useEffect(() => {
    reset({
      birthDate: parseISO(user.birthDate),
      cpf: maskCpf(user.cpf),
      email: user.email,
      gender: user.gender,
      phone: user.phone,
    });
  }, [reset, user]);

  const handleChangeAvatar = useCallback((file: File) => {
    setAvatar(file);
    setAvatarUrl(URL.createObjectURL(file));
  }, []);

  const handleToggleDeleteConfirmationModal = useCallback(() => {
    setIsDeleteConfirmationModalVisible((prevState) => !prevState);
  }, []);

  const handleDeleteAvatar = useCallback(async () => {
    await deleteUserAvatarsService(user.id);

    setAvatar(undefined);
    setAvatarUrl(undefined);
    handleToggleDeleteConfirmationModal();

    delete user.avatar;
    delete user.avatarUrl;

    updateUser(user);
  }, [handleToggleDeleteConfirmationModal, updateUser, user]);

  const handleToggleInputEnable = useCallback(() => {
    setIsInputEnabled((prevState) => !prevState);
  }, []);

  const handleUpdateProfile: SubmitHandler<ProfileFormData> = useCallback(
    async ({
      birthDate,
      cpf,
      email,
      gender,
      name,
      password,
      passwordConfirmation,
      phone,
    }) => {
      try {
        const updatedUser = await updateUsersProfileService({
          birthDate,
          cpf,
          email,
          gender,
          name,
          password,
          passwordConfirmation,
          phone,
          userId: user.id,
        });

        if (avatar) {
          const formData = new FormData();

          formData.append('avatar', avatar);

          const userWithNewAvatar = await updateUserAvatarsService({
            userId: user.id,
            avatarData: formData,
          });

          updateUser(userWithNewAvatar);
        } else {
          updateUser(updatedUser);
        }

        toast({
          title: 'Editado com sucesso',
          description: 'Seu perfil foi editado corretamente',
          status: 'success',
          duration: 3000,
          isClosable: true,
          variant: 'subtle',
          position: 'top-right',
        });

        push('/dashboard');
      } catch (err) {
        if (axios.isAxiosError(err) && err.response?.status !== 401) {
          toast({
            title: 'Falha ao editar',
            description:
              translateError({ message: err.response?.data.message }) ||
              'Ocorreu um erro ao editar seu perfil, tente novamente',
            status: 'error',
            duration: 3000,
            isClosable: true,
            variant: 'subtle',
            position: 'top-right',
          });
        }
      }
    },
    [avatar, push, toast, updateUser, user.id],
  );

  return (
    <DefaultLayout>
      <DeleteConfirmationModal
        isOpen={isDeleteConfirmationModalVisible}
        onClose={handleToggleDeleteConfirmationModal}
        onConfirm={handleDeleteAvatar}
      />

      <Box
        as="form"
        flex="1"
        borderRadius={8}
        bg="white"
        p="8"
        onSubmit={handleSubmit(handleUpdateProfile)}
      >
        <Flex justifyContent="space-between">
          <Heading size="lg" fontWeight="normal">
            Perfil
          </Heading>

          <Button
            size="sm"
            fontSize="sm"
            colorScheme={isInputEnabled ? 'red' : 'yellow'}
            color="white"
            onClick={handleToggleInputEnable}
            leftIcon={
              <Icon
                as={!isInputEnabled ? RiPencilLine : RiCloseCircleLine}
                fontSize="16"
              />
            }
          >
            {isInputEnabled ? 'Cancelar' : 'Editar'}
          </Button>
        </Flex>

        <Divider my="6" borderColor="gray.300" />

        <Flex justify="center" mb="8">
          <AvatarDropzone
            avatarUrl={avatarUrl}
            disabled={!isInputEnabled}
            onChange={handleChangeAvatar}
            onDelete={handleToggleDeleteConfirmationModal}
          />
        </Flex>

        <VStack spacing="8">
          <Grid
            templateColumns={[
              'repeat(1, 1fr)',
              'repeat(1, 1fr)',
              'repeat(1, 1fr)',
              'repeat(1, 1fr)',
              'repeat(12, 1fr)',
            ]}
            gap="8"
            width="100%"
          >
            <GridItem colSpan={[12, 12, 12, 12, 8]}>
              <MaskedInput
                isDisabled={!isInputEnabled}
                label="Nome completo"
                error={errors.name}
                {...register('name')}
              />
            </GridItem>

            <GridItem colSpan={[12, 12, 12, 12, 4]}>
              <ReactSelect
                name="gender"
                label="Gênero"
                control={control}
                error={errors.gender}
                options={userGendersSelectOption}
              />
            </GridItem>
          </Grid>

          <SimpleGrid minChildWidth="240px" spacing="8" w="100%">
            <MaskedInput
              isDisabled={!isInputEnabled}
              label="CPF"
              mask="cpf"
              error={errors.cpf}
              {...register('cpf')}
            />

            <MaskedInput
              isDisabled={!isInputEnabled}
              label="E-mail"
              type="email"
              textTransform="lowercase"
              error={errors.email}
              {...register('email')}
            />

            <InternationalPhoneInput
              isDisabled={!isInputEnabled}
              label="Telefone"
              name="phone"
              control={control}
              error={errors.phone}
            />

            <DatePicker
              isDisabled={!isInputEnabled}
              label="Data de nascimento"
              maxDate={new Date()}
              control={control}
              error={errors.birthDate}
              {...register('birthDate')}
            />
          </SimpleGrid>

          <SimpleGrid minChildWidth="240px" spacing="8" w="100%">
            <MaskedInput
              label="Senha"
              type="password"
              isDisabled={!isInputEnabled}
              error={errors.password}
              {...register('password')}
            />

            <MaskedInput
              label="Confirmação de senha"
              type="password"
              isDisabled={!isInputEnabled}
              error={errors.passwordConfirmation}
              {...register('passwordConfirmation')}
            />
          </SimpleGrid>

          {isInputEnabled && (
            <Flex mt="12" justify="flex-end">
              <ButtonGroup>
                <Button
                  colorScheme="blackAlpha"
                  onClick={handleToggleInputEnable}
                >
                  Cancelar
                </Button>
                <Button
                  type="submit"
                  colorScheme="green"
                  isLoading={formState.isSubmitting}
                >
                  Salvar
                </Button>
              </ButtonGroup>
            </Flex>
          )}
        </VStack>
      </Box>
    </DefaultLayout>
  );
};

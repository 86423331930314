import { IAdvertisementBase } from '../../models/advertisements';
import { IUserBase } from '../../models/users';
import cjPontanegraApi from '../apis/cjPontanegraApi';

interface IUpdateUserProps {
  userId: string;
  birthDate: Date;
  cpf: string;
  email: string;
  gender: 'FEMALE' | 'MALE' | 'OTHER' | 'UNDEFINED';
  name: string;
  password?: string;
  passwordConfirmation?: string;
  phone?: string;
}

export interface IUpdatedUser extends IUserBase {
  favorites: IAdvertisementBase[];
}

export const updateUsersProfileService = async ({
  userId,
  birthDate,
  cpf,
  email,
  gender,
  name,
  password,
  passwordConfirmation,
  phone,
}: IUpdateUserProps): Promise<IUpdatedUser> => {
  const { data } = await cjPontanegraApi.put<IUpdatedUser>(
    `/users/${userId}/profile`,
    {
      birthDate,
      cpf,
      email,
      gender,
      name,
      password,
      passwordConfirmation,
      phone,
    },
  );

  return data;
};

import {
  Input as ChakraInput,
  InputProps as ChakraInputProps,
  FormLabel,
  FormControl,
  FormErrorMessage,
  Button,
  HStack,
} from '@chakra-ui/react';
import { forwardRef, ForwardRefRenderFunction, useCallback } from 'react';
import { FieldError } from 'react-hook-form';
import {
  area,
  cep,
  cnpj,
  cpf,
  creci,
  date,
  money,
  phone,
  rg,
  token,
} from './masks';

interface IInputProps extends ChakraInputProps {
  name: string;
  label?: string;
  error?: FieldError;
  showRightButton?: boolean;
  onSubmit?: () => void;
  mask?:
    | 'area'
    | 'cep'
    | 'cnpj'
    | 'cpf'
    | 'creci'
    | 'date'
    | 'money'
    | 'phone'
    | 'rg'
    | 'token';
}

const InputBase: ForwardRefRenderFunction<HTMLInputElement, IInputProps> = (
  {
    name,
    label,
    mask,
    onSubmit,
    showRightButton = false,
    error = null,
    ...rest
  },
  ref,
) => {
  const handleInputChange = useCallback(
    (e: React.FormEvent<HTMLInputElement>) => {
      switch (mask) {
        case 'area':
          area(e);
          break;

        case 'cep':
          cep(e);
          break;

        case 'cnpj':
          cnpj(e);
          break;

        case 'cpf':
          cpf(e);
          break;

        case 'creci':
          creci(e);
          break;

        case 'date':
          date(e);
          break;

        case 'money':
          money(e);
          break;

        case 'phone':
          phone(e);
          break;

        case 'rg':
          rg(e);
          break;

        case 'token':
          token(e);
          break;

        default:
          break;
      }
    },
    [mask],
  );

  return (
    <FormControl isInvalid={!!error}>
      {!!label && <FormLabel htmlFor={name}>{label}</FormLabel>}

      <HStack>
        <ChakraInput
          ref={ref}
          name={name}
          id={name}
          onChangeCapture={handleInputChange}
          borderColor="gray.300"
          bg="gray.100"
          focusBorderColor="blue.300"
          variant="outline"
          size="lg"
          {...rest}
        />

        {showRightButton && (
          <Button type="button" colorScheme="blue" onClick={onSubmit} size="lg">
            Buscar
          </Button>
        )}
      </HStack>

      {!!error && (
        <FormErrorMessage position="absolute">{error.message}</FormErrorMessage>
      )}
    </FormControl>
  );
};

export const MaskedInput = forwardRef(InputBase);

import cjPontanegraApi from '../apis/cjPontanegraApi';

type AgeGroup = {
  maxAge?: number | null;
  minAge: number;
};

interface IListUsersAgeGroupsProps {
  ageGroups?: AgeGroup[];
  roles?: Array<string>;
}

export interface IListUsersAgeGroupsResponse extends AgeGroup {
  count: number;
}

export const listUsersAgeGroupsService = async ({
  ageGroups,
  roles,
}: IListUsersAgeGroupsProps): Promise<IListUsersAgeGroupsResponse[]> => {
  const { data } = await cjPontanegraApi.post<IListUsersAgeGroupsResponse[]>(
    '/users/age-group',
    {
      ageGroups: ageGroups?.length ? ageGroups : undefined,
      roles: roles?.length ? roles : undefined,
    },
  );

  return data;
};

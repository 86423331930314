import { ISellerBase } from '../../models/sellers';
import cjPontanegraApi from '../apis/cjPontanegraApi';

interface IListSellerProps {
  cnpj?: string;
  email?: string;
  name?: string;
  phone?: string;
  sort?: 'cnpj' | 'name' | 'phone' | 'email' | 'createdAt';
  order?: 'ASC' | 'DESC';
  limit?: number;
  page?: number;
}

export type ISellerListItem = ISellerBase;

export interface ISellerList {
  items: ISellerListItem[];
  pages: number;
  total: number;
}

export const listSellersService = async (
  filters?: IListSellerProps,
): Promise<ISellerList> => {
  const params =
    filters && Object.keys(filters).length !== 0
      ? {
          cnpj: filters.cnpj || undefined,
          email: filters.email || undefined,
          name: filters.name || undefined,
          phone: filters.phone || undefined,
          sort: filters.sort,
          order: filters.order,
          limit: filters.limit,
          page: filters.page,
        }
      : undefined;

  const { data } = await cjPontanegraApi.get<ISellerList>('/sellers', {
    params,
  });

  return data;
};

import { ISellerBase } from '../../models/sellers';
import cjPontanegraApi from '../apis/cjPontanegraApi';

interface ICreateSellerProps {
  bio?: string;
  cnpj: string;
  email: string;
  name: string;
  phone?: string;
}

export type INewSeller = ISellerBase;

export const createSellersService = async ({
  bio,
  cnpj,
  email,
  name,
  phone,
}: ICreateSellerProps): Promise<INewSeller> => {
  const { data } = await cjPontanegraApi.post<INewSeller>('/sellers', {
    bio,
    cnpj,
    email,
    name,
    phone,
  });

  return data;
};

import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  ModalFooter,
  Button,
  Text,
  Heading,
  VStack,
  Input,
  FormControl,
  FormLabel,
  FormErrorMessage,
  ButtonGroup,
} from '@chakra-ui/react';
import { useCallback, useState } from 'react';

export interface IReviewAdvertisementData {
  reviewComments?: string;
  status: 'APPROVED' | 'REJECTED';
}

interface IReviewAdvertisementModalProps {
  isOpen: boolean;
  onClose: () => void;
  onSelect: (reviewData: IReviewAdvertisementData) => void;
}

export const ReviewAdvertisementModal = ({
  isOpen,
  onClose,
  onSelect,
}: IReviewAdvertisementModalProps): JSX.Element => {
  const [reviewComments, setReviewComments] = useState<string>();
  const [status, setStatus] = useState<'APPROVED' | 'REJECTED'>();

  const handleReview = useCallback(
    (reviewStatus: 'APPROVED' | 'REJECTED') => {
      setStatus(reviewStatus);

      if (reviewStatus === 'APPROVED' || reviewComments) {
        onSelect({ reviewComments, status: reviewStatus });
      }
    },
    [onSelect, reviewComments],
  );

  const handleCloseModal = useCallback(() => {
    setReviewComments(undefined);
    setStatus(undefined);

    onClose();
  }, [onClose]);

  return (
    <Modal size="lg" isOpen={isOpen} onClose={handleCloseModal}>
      <ModalOverlay />
      <ModalContent as="form">
        <ModalHeader>
          <Heading size="lg" fontWeight="normal">
            Revisar anúncio
          </Heading>
        </ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <VStack spacing="4">
            <Text color="red.500">
              Escolha com atenção, operação irreversível!
            </Text>

            <FormControl
              isInvalid={
                (status === 'REJECTED' && !reviewComments) ||
                (status === 'APPROVED' && !!reviewComments)
              }
            >
              <FormLabel htmlFor="reviewComments">Motivo da rejeição</FormLabel>

              <Input
                name="reviewComments"
                id="reviewComments"
                value={reviewComments}
                placeholder="Preencha esse campo em caso de rejeição"
                onChange={(e) => setReviewComments(e.target.value)}
                borderColor="gray.300"
                bg="gray.100"
                focusBorderColor="blue.300"
                variant="outline"
                size="lg"
                as="textarea"
                minHeight="100px"
                resize="none"
                py="2"
              />

              <FormErrorMessage position="absolute">
                {status === 'REJECTED'
                  ? 'Requerido para rejeitar'
                  : 'Apenas para anúncios rejeitados'}
              </FormErrorMessage>
            </FormControl>
          </VStack>
        </ModalBody>

        <ModalFooter mt="4">
          <ButtonGroup>
            <Button colorScheme="blackAlpha" onClick={handleCloseModal}>
              Cancelar
            </Button>
            <Button colorScheme="red" onClick={() => handleReview('REJECTED')}>
              Rejeitar
            </Button>
            <Button
              colorScheme="green"
              onClick={() => handleReview('APPROVED')}
            >
              Aprovar
            </Button>
          </ButtonGroup>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

import { useHistory, useLocation } from 'react-router-dom';
import {
  Box,
  Heading,
  Flex,
  Button,
  Icon,
  Text,
  SimpleGrid,
  Avatar,
  useToast,
  ButtonGroup,
  HStack,
} from '@chakra-ui/react';
import { RiDeleteBinLine, RiEditLine, RiMailSendLine } from 'react-icons/ri';
import { useCallback, useEffect, useState } from 'react';
import axios from 'axios';
import { DefaultLayout } from '../../../_layout/DefaultLayout';
import { DeleteConfirmationModal } from '../../../../../components/DeleteConfirmationModal';
import { LinkButton } from '../../../../../components/LinkButton';
import { maskCpf, maskDate } from '../../../../../utils/formatters/handleMask';
import {
  IDetailedUser,
  showUsersService,
} from '../../../../../services/Users/ShowUsersService';
import { deleteUsersService } from '../../../../../services/Users/DeleteUsersService';
import { UserGenders, UserRoles } from '../../../../../models/users';
import { createProfileConfirmationsService } from '../../../../../services/Users/CreateProfileConfirmationsService';
import { ProfileConfirmationModal } from '../../../../../components/ProfileConfirmationModal';
import { translateError } from '../../../../../utils/errors';

interface ICustomer extends IDetailedUser {
  formattedBirthDate: string;
  formattedDate: string;
}

interface ILocationState {
  customerId: string;
}

export const CustomerDetails = (): JSX.Element => {
  const toast = useToast();

  const {
    state: { customerId },
  } = useLocation<ILocationState>();
  const { push } = useHistory();

  const [customer, setCustomer] = useState<ICustomer>();
  const [
    isSendProfileConfirmationModalVisible,
    setIsSendProfileConfirmationModalVisible,
  ] = useState(false);
  const [
    isDeleteConfirmationModalVisible,
    setIsDeleteConfirmationModalVisible,
  ] = useState(false);

  useEffect(() => {
    async function loadCustomer(): Promise<void> {
      try {
        const customerData = await showUsersService({ userId: customerId });

        const parsedCustomer = {
          ...customerData,
          cpf: maskCpf(customerData.cpf).toString(),
          formattedBirthDate: maskDate(customerData.birthDate),
          formattedDate: maskDate(customerData.createdAt),
        };

        setCustomer(parsedCustomer);
      } catch (err) {
        if (axios.isAxiosError(err) && err.response?.status !== 401) {
          toast({
            title: 'Falha ao carregar dados',
            description:
              translateError({ message: err.response?.data.message }) ||
              'Ocorreu um erro ao carregar os detalhes do cliente, tente novamente',
            status: 'error',
            duration: 3000,
            isClosable: true,
            variant: 'subtle',
            position: 'top-right',
          });
        }
      }
    }

    loadCustomer();
  }, [customerId, toast]);

  const handleToggleProfileConfirmationModal = useCallback(() => {
    setIsSendProfileConfirmationModalVisible((prevState) => !prevState);
  }, []);

  const handleToggleDeleteConfirmationModal = useCallback(() => {
    setIsDeleteConfirmationModalVisible((prevState) => !prevState);
  }, []);

  const handleProfileConfirmation = useCallback(async () => {
    try {
      if (customer) {
        await createProfileConfirmationsService(customer.id);
      }

      toast({
        title: 'Notificado com sucesso',
        description: 'O cliente foi notificado corretamente',
        status: 'success',
        duration: 3000,
        isClosable: true,
        variant: 'subtle',
        position: 'top-right',
      });

      handleToggleProfileConfirmationModal();
    } catch (err) {
      if (axios.isAxiosError(err) && err.response?.status !== 401) {
        toast({
          title: 'Falha ao notificar',
          description:
            translateError({ message: err.response?.data.message }) ||
            'Ocorreu um erro ao enviar a notificação, tente novamente',
          status: 'error',
          duration: 3000,
          isClosable: true,
          variant: 'subtle',
          position: 'top-right',
        });
      }
    }
  }, [customer, handleToggleProfileConfirmationModal, toast]);

  const handleDeleteCustomer = useCallback(async () => {
    try {
      await deleteUsersService(customerId);

      toast({
        title: 'Excluído com sucesso',
        description: 'O cliente foi excluído corretamente',
        status: 'success',
        duration: 3000,
        isClosable: true,
        variant: 'subtle',
        position: 'top-right',
      });

      push('/customers');
    } catch (err) {
      if (axios.isAxiosError(err) && err.response?.status !== 401) {
        toast({
          title: 'Falha ao excluir',
          description:
            translateError({ message: err.response?.data.message }) ||
            'Ocorreu um erro ao excluir o cliente, tente novamente',
          status: 'error',
          duration: 3000,
          isClosable: true,
          variant: 'subtle',
          position: 'top-right',
        });
      }
    }
  }, [customerId, push, toast]);

  return (
    <DefaultLayout>
      <DeleteConfirmationModal
        isOpen={isDeleteConfirmationModalVisible}
        onClose={handleToggleDeleteConfirmationModal}
        onConfirm={handleDeleteCustomer}
      />

      <ProfileConfirmationModal
        isOpen={isSendProfileConfirmationModalVisible}
        onClose={handleToggleProfileConfirmationModal}
        onConfirm={handleProfileConfirmation}
      />

      <Box flex="1" borderRadius={8} bg="white" p="8">
        <Flex mb="8" justify="space-between" align="center">
          <Heading size="lg" fontWeight="normal">
            Detalhes do cliente
          </Heading>

          <ButtonGroup>
            {customer?.confirmPending && (
              <Button
                size="sm"
                fontSize="sm"
                colorScheme="blue"
                onClick={handleToggleProfileConfirmationModal}
                leftIcon={<Icon as={RiMailSendLine} fontSize="20" />}
              >
                Reenviar confirmação
              </Button>
            )}

            <LinkButton
              colorScheme="yellow"
              color="white"
              icon={<Icon as={RiEditLine} fontSize="16" />}
              to={{
                pathname: '/customers/update',
                state: {
                  customerId,
                },
              }}
            >
              Editar
            </LinkButton>

            <Button
              size="sm"
              fontSize="sm"
              colorScheme="red"
              onClick={handleToggleDeleteConfirmationModal}
              leftIcon={<Icon as={RiDeleteBinLine} fontSize="20" />}
            >
              Excluir
            </Button>
          </ButtonGroup>
        </Flex>

        {customer && (
          <Flex direction="column">
            <Flex align="center" direction="column">
              <Avatar
                size="2xl"
                name={customer.name}
                src={customer.avatarUrl}
              />

              <Text fontSize="lg" mt="2" color="gray.500">
                {UserRoles[customer.role]}
              </Text>

              <Text fontSize="4xl" fontWeight="bold" mt="4">
                {customer.name}
              </Text>

              <Text color="gray.500">{customer.seller?.name}</Text>
            </Flex>

            <SimpleGrid
              minChildWidth="240px"
              spacing="8"
              px="8"
              mt="4"
              w="100%"
            >
              <HStack>
                <Text>Telefone:</Text>
                <Text as="span" color="gray.500">
                  {customer.phone}
                </Text>
              </HStack>

              <HStack>
                <Text>E-mail:</Text>
                <Text as="span" color="gray.500">
                  {customer.email}
                </Text>
              </HStack>

              <HStack>
                <Text>CPF:</Text>
                <Text as="span" color="gray.500">
                  {customer.cpf}
                </Text>
              </HStack>
            </SimpleGrid>

            <SimpleGrid
              minChildWidth="240px"
              spacing="8"
              px="8"
              mt="4"
              w="100%"
            >
              <HStack>
                <Text>Gênero:</Text>
                <Text as="span" color="gray.500">
                  {UserGenders[customer.gender]}
                </Text>
              </HStack>

              <HStack>
                <Text>Data de nascimento:</Text>
                <Text as="span" color="gray.500">
                  {customer.formattedBirthDate}
                </Text>
              </HStack>

              <HStack>
                <Text>Data de cadastro:</Text>
                <Text as="span" color="gray.500">
                  {customer.formattedDate}
                </Text>
              </HStack>
            </SimpleGrid>
          </Flex>
        )}
      </Box>
    </DefaultLayout>
  );
};

const AppErrorMessages = [
  {
    message: 'invalid-expiration-date',
    ptBr: 'Data de encerramento inválida.',
  },
  {
    message: 'advertisement-not-found',
    ptBr: 'Anúncio não encontrado.',
  },
  {
    message: 'advertisement-category-not-found',
    ptBr: 'Categoria não encontrada.',
  },
  {
    message: 'cnpj-already-in-use',
    ptBr: 'CNPJ já está em uso.',
  },
  {
    message: 'email-already-in-use',
    ptBr: 'E-mail já está em uso.',
  },
  {
    message: 'seller-not-found',
    ptBr: 'Loja não encontrada.',
  },
  {
    message: 'coupon-not-found',
    ptBr: 'Cupom não encontrado ou expirado.',
  },
  {
    message: 'cpf-already-in-use',
    ptBr: 'CPF já está em uso.',
  },
  {
    message: 'user-not-found',
    ptBr: 'Usuário não encontrado.',
  },
  {
    message: 'user-token-not-found',
    ptBr: 'Token inválido.',
  },
  {
    message: 'user-token-expired',
    ptBr: 'Token expirado.',
  },
];

interface ITRanslateErrorProps {
  message: string;
  language?: 'ptBr';
}

export const translateError = ({
  message,
  language = 'ptBr',
}: ITRanslateErrorProps): string | undefined =>
  AppErrorMessages.find((errorMessage) => errorMessage.message === message)?.[
    language
  ];

import {
  Table,
  Thead,
  Tr,
  Th,
  Tbody,
  Td,
  Text,
  Box,
  Icon,
} from '@chakra-ui/react';
import { RiCheckboxCircleLine, RiCloseCircleLine } from 'react-icons/ri';
import { IDetailedAdvertisementCoupon } from '../../../../../../services/AdvertisementCoupons/ShowCouponsService';

export interface IAdvertisementCouponTableItem
  extends IDetailedAdvertisementCoupon {
  formattedExpirationDate?: string;
  formattedUsedAt?: string;
}

interface IAdvertisementCouponTableProps {
  coupon: IAdvertisementCouponTableItem;
}

const AdvertisementCouponTable = ({
  coupon,
}: IAdvertisementCouponTableProps): JSX.Element => (
  <Table colorScheme="blue" mt="4">
    <Thead>
      <Tr>
        <Th w="8" textAlign="center">
          Válido
        </Th>
        <Th>Anúncio</Th>
        <Th>Cliente</Th>
        <Th>Data de encerramento</Th>
        {coupon.usedAt && <Th>Data do uso</Th>}
      </Tr>
    </Thead>

    <Tbody>
      <Tr>
        <Td textAlign="center">
          {!coupon.isExpired && !coupon.isUsed ? (
            <Icon fontSize="3xl" as={RiCheckboxCircleLine} color="green" />
          ) : (
            <Icon fontSize="3xl" as={RiCloseCircleLine} color="red.500" />
          )}
        </Td>

        <Td>
          <Box>
            <Text fontWeight="bold">{coupon.advertisement.title}</Text>
            <Text fontSize="sm" color="gray.500">
              {coupon.advertisement.seller.name}
            </Text>
          </Box>
        </Td>

        <Td>
          <Box>
            <Text fontWeight="bold">{coupon.user.name}</Text>
            <Text fontSize="sm" color="gray.500">
              {coupon.user.email}
            </Text>
          </Box>
        </Td>

        <Td color={coupon.isExpired && !coupon.isUsed ? 'red.500' : 'gray.700'}>
          {coupon.formattedExpirationDate}
        </Td>

        {coupon.usedAt && <Td color="red.500">{coupon.formattedUsedAt}</Td>}
      </Tr>
    </Tbody>
  </Table>
);

export default AdvertisementCouponTable;

import { IEntityBase } from './entityBase';

export enum AppDownloadStore {
  appstore = 'Apple store',
  playstore = 'Play store',
}

export interface IAppDownloadBase extends IEntityBase {
  count: number;
  store: 'appstore' | 'playstore';
}

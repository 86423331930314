import { IAdvertisementBase } from '../../models/advertisements';
import { ISellerBase } from '../../models/sellers';
import cjPontanegraApi from '../apis/cjPontanegraApi';

export interface IDetailedSeller extends ISellerBase {
  advertisements?: IAdvertisementBase[];
}

export const showSellersService = async (
  sellerId: string,
): Promise<IDetailedSeller> => {
  const { data } = await cjPontanegraApi.get<IDetailedSeller>(
    `/sellers/${sellerId}`,
  );

  return data;
};

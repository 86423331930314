import { useCallback, useEffect, useState } from 'react';
import { useHistory } from 'react-router';
import { SubmitHandler, useForm } from 'react-hook-form';
import * as Yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import {
  Box,
  Heading,
  Divider,
  VStack,
  SimpleGrid,
  Flex,
  Button,
  useToast,
  ButtonGroup,
} from '@chakra-ui/react';

import axios from 'axios';
import { endOfDay } from 'date-fns/esm';
import { startOfDay } from 'date-fns';
import { AvatarDropzone } from '../../../../../components/Form/AvatarDropzone';
import { MaskedInput } from '../../../../../components/Form/MaskedInput';
import { DefaultLayout } from '../../../_layout/DefaultLayout';
import { createAdvertisementsService } from '../../../../../services/Advertisements/CreateAdvertisementsService';
import { updateAdvertisementAvatarsService } from '../../../../../services/Advertisements/UpdateAdvertisementAvatarsService';
import { listAdvertisementCategoriesService } from '../../../../../services/AdvertisementCategories/ListAdvertisementCategoriesService';
import {
  AsyncSelect,
  SelectOption,
} from '../../../../../components/Form/AsyncSelect';
import { listSellersService } from '../../../../../services/Sellers/ListSellersService';
import { DatePicker } from '../../../../../components/Form/DatePicker';
import { unmaskNumber } from '../../../../../utils/formatters/handleMask';
import { Switch } from '../../../../../components/Form/Switch';
import { useAuth } from '../../../../../hooks/auth';
import { translateError } from '../../../../../utils/errors';
import { TimeInputWithScale } from '../../../../../components/Form/TimeInputWithScale';

type NewAdvertisementFormData = {
  categoryId: string;
  couponsAmount?: number;
  couponsMaxAgeInMinutes?: number;
  couponsMaxUsagePerUser?: number;
  description?: string;
  discount?: number | null;
  expirationDate?: Date | null;
  isActive: boolean;
  title: string;
  selectedSeller: SelectOption;
};

const registerAdvertisementFormSchema = Yup.object().shape({
  categoryId: Yup.string()
    .uuid()
    .transform((value, originalValue) =>
      originalValue === '' ? null : value.value,
    )
    .nullable()
    .required('Categoria requerida'),
  couponsAmount: Yup.number()
    .integer('Apenas valores inteiros.')
    .positive('Apenas valores maiores que zero.')
    .nullable()
    .transform((value, originalValue) => (originalValue === '' ? null : value))
    .when('couponsMaxUsagePerUser', (couponsMaxUsagePerUser, couponsAmount) =>
      couponsMaxUsagePerUser
        ? couponsAmount.min(
            Yup.ref('couponsMaxUsagePerUser'),
            'Quantidade total deve ser maior que o máximo por usuário',
          )
        : couponsAmount,
    ),
  couponsMaxAgeInMinutes: Yup.number()
    .integer('Apenas valores inteiros.')
    .positive('Apenas valores maiores que zero.')
    .nullable()
    .transform((value, originalValue) => (originalValue === '' ? null : value)),
  couponsMaxUsagePerUser: Yup.number()
    .integer('Apenas valores inteiros.')
    .positive('Apenas valores maiores que zero.')
    .nullable()
    .transform((value, originalValue) => (originalValue === '' ? null : value)),
  description: Yup.string()
    .nullable()
    .transform((value, originalValue) => (originalValue === '' ? null : value)),
  discount: Yup.number()
    .integer('Apenas valores inteiros.')
    .positive('Apenas valores maiores que zero.')
    .nullable()
    .transform((_, originalValue) =>
      originalValue === '' ? null : unmaskNumber(originalValue),
    ),
  expirationDate: Yup.date()
    .nullable()
    .transform((value, originalValue) => (originalValue === '' ? null : value))
    .min(startOfDay(new Date()), 'Data inválida, mínimo hoje'),
  isActive: Yup.boolean().required('Requerido'),
  title: Yup.string().required('Título requerido'),
  selectedSeller: Yup.object()
    .shape({
      label: Yup.string().required('Loja requerida'),
      value: Yup.string().uuid().required('Loja requerida'),
    })
    .required('Loja requerida'),
});

export const AdvertisementRegister = (): JSX.Element => {
  const { goBack, push } = useHistory();
  const toast = useToast();

  const { user } = useAuth();

  const [avatar, setAvatar] = useState<File>();
  const [avatarUrl, setAvatarUrl] = useState<string>();

  const { register, handleSubmit, formState, control, setValue } = useForm({
    resolver: yupResolver(registerAdvertisementFormSchema),
  });

  const { errors } = formState;

  useEffect(() => {
    if (user.role !== 'ADMIN') {
      setValue('selectedSeller', {
        label: user.seller?.name,
        value: user.sellerId,
      });
    }
  }, [setValue, user.role, user.seller?.name, user.sellerId]);

  const handleChangeAvatar = useCallback((file: File) => {
    setAvatar(file);
    setAvatarUrl(URL.createObjectURL(file));
  }, []);

  const handleDeleteAvatar = useCallback(() => {
    setAvatar(undefined);
    setAvatarUrl(undefined);
  }, []);

  const handleLoadCategorySelectOption = useCallback(
    async (name?: string): Promise<SelectOption[]> => {
      const { items: sellers } = await listAdvertisementCategoriesService({
        name,
        limit: 4,
      });

      const parsedCategoriesSelectOption: SelectOption[] = [
        ...sellers.map((seller) => ({
          label: seller.name,
          value: seller.id,
        })),
      ];

      return parsedCategoriesSelectOption;
    },
    [],
  );

  const handleLoadSellerSelectOption = useCallback(
    async (name?: string): Promise<SelectOption[]> => {
      const { items: sellers } = await listSellersService({ name, limit: 4 });

      const parsedSellersSelectOption: SelectOption[] = [
        ...sellers.map((seller) => ({
          label: seller.name,
          value: seller.id,
        })),
      ];

      return parsedSellersSelectOption;
    },
    [],
  );

  const handleNewAdvertisement: SubmitHandler<NewAdvertisementFormData> =
    useCallback(
      async ({
        categoryId,
        couponsAmount,
        couponsMaxAgeInMinutes,
        couponsMaxUsagePerUser,
        description,
        discount,
        expirationDate,
        isActive,
        title,
        selectedSeller,
      }) => {
        try {
          const advertisement = await createAdvertisementsService({
            categoryId,
            couponsAmount,
            couponsMaxAgeInMinutes,
            couponsMaxUsagePerUser,
            description,
            discount,
            expirationDate: expirationDate
              ? endOfDay(expirationDate)
              : expirationDate,
            isActive,
            title,
            sellerId: selectedSeller.value as string,
          });

          if (avatar) {
            const formData = new FormData();

            formData.append('avatar', avatar);

            await updateAdvertisementAvatarsService({
              avatarData: formData,
              advertisementId: advertisement.id,
            });
          }

          toast({
            title: 'Cadastrado com sucesso',
            description: 'O anúncio foi cadastrado corretamente',
            status: 'success',
            duration: 3000,
            isClosable: true,
            variant: 'subtle',
            position: 'top-right',
          });

          push('/advertisements/details', {
            advertisementId: advertisement.id,
          });
        } catch (err) {
          if (axios.isAxiosError(err) && err.response?.status !== 401) {
            toast({
              title: 'Falha no cadastro',
              description:
                translateError({ message: err.response?.data.message }) ||
                'Ocorreu um erro ao cadastrar o anúncio, tente novamente',
              status: 'error',
              duration: 3000,
              isClosable: true,
              variant: 'subtle',
              position: 'top-right',
            });
          }
        }
      },
      [avatar, push, toast],
    );

  return (
    <DefaultLayout>
      <Box
        as="form"
        flex="1"
        borderRadius={8}
        bg="white"
        p="8"
        onSubmit={handleSubmit(handleNewAdvertisement)}
      >
        <Heading size="lg" fontWeight="normal">
          Cadastrar anúncio
        </Heading>

        <Divider my="6" borderColor="gray.300" />

        <Flex justify="center" mb="8">
          <AvatarDropzone
            avatarUrl={avatarUrl}
            onChange={handleChangeAvatar}
            onDelete={handleDeleteAvatar}
          />
        </Flex>

        <VStack spacing="8">
          <MaskedInput
            label="Título"
            error={errors.title}
            {...register('title')}
          />

          <SimpleGrid minChildWidth="240px" spacing="8" w="100%">
            <MaskedInput
              label="Desconto"
              mask="money"
              error={errors.discount}
              {...register('discount')}
            />

            <DatePicker
              label="Data de encerramento"
              minDate={new Date()}
              control={control}
              error={errors.expirationDate}
              {...register('expirationDate')}
            />
          </SimpleGrid>

          <SimpleGrid minChildWidth="240px" spacing="8" w="100%">
            <AsyncSelect
              label="Loja"
              name="selectedSeller"
              loadOptions={handleLoadSellerSelectOption}
              isDisabled={user.role !== 'ADMIN'}
              control={control}
              error={errors.selectedSeller}
            />

            <AsyncSelect
              label="Categoria"
              name="categoryId"
              loadOptions={handleLoadCategorySelectOption}
              control={control}
              error={errors.categoryId}
            />
          </SimpleGrid>

          <SimpleGrid minChildWidth="240px" spacing="8" w="100%">
            <VStack spacing="8">
              <MaskedInput
                label="Quantidade de cupons"
                error={errors.couponsAmount}
                {...register('couponsAmount')}
              />

              <MaskedInput
                label="Máx. cupons por usuário"
                error={errors.couponsMaxUsagePerUser}
                {...register('couponsMaxUsagePerUser')}
              />

              <TimeInputWithScale
                label="Validade dos cupons"
                name="couponsMaxAgeInMinutes"
                control={control}
                error={errors.couponsMaxAgeInMinutes}
              />
            </VStack>

            <VStack spacing="8">
              <MaskedInput
                label="Descrição"
                as="textarea"
                minHeight="160px"
                resize="none"
                py="2"
                error={errors.description}
                {...register('description')}
              />

              <Flex flex={1} w="100%" alignItems="flex-end">
                <Box mx="auto">
                  <Switch
                    label="Ativo"
                    error={errors.isActive}
                    defaultChecked
                    {...register('isActive')}
                  />
                </Box>
              </Flex>
            </VStack>
          </SimpleGrid>
        </VStack>

        <Flex mt="12" justify="flex-end">
          <ButtonGroup>
            <Button colorScheme="blackAlpha" onClick={goBack}>
              Cancelar
            </Button>
            <Button
              type="submit"
              colorScheme="green"
              isLoading={formState.isSubmitting}
            >
              Salvar
            </Button>
          </ButtonGroup>
        </Flex>
      </Box>
    </DefaultLayout>
  );
};

import { useCallback, useEffect, useMemo, useState } from 'react';
import { useHistory, useLocation } from 'react-router';
import { SubmitHandler, useForm } from 'react-hook-form';
import * as Yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import {
  Box,
  Heading,
  Divider,
  VStack,
  SimpleGrid,
  Flex,
  Button,
  useToast,
  ButtonGroup,
  Grid,
  GridItem,
} from '@chakra-ui/react';

import axios from 'axios';
import { startOfDay } from 'date-fns';
import { AvatarDropzone } from '../../../../../components/Form/AvatarDropzone';
import { MaskedInput } from '../../../../../components/Form/MaskedInput';
import { DefaultLayout } from '../../../_layout/DefaultLayout';
import { DeleteConfirmationModal } from '../../../../../components/DeleteConfirmationModal';
import { InternationalPhoneInput } from '../../../../../components/Form/InternationalPhoneInput';
import {
  AsyncSelect,
  SelectOption,
} from '../../../../../components/Form/AsyncSelect';
import { listSellersService } from '../../../../../services/Sellers/ListSellersService';
import { maskCpf } from '../../../../../utils/formatters/handleMask';
import { showUsersService } from '../../../../../services/Users/ShowUsersService';
import { deleteUserAvatarsService } from '../../../../../services/Users/DeleteUserAvatarsService';
import { updateUserAvatarsService } from '../../../../../services/Users/UpdateUserAvatarsService';
import { useAuth } from '../../../../../hooks/auth';
import { updateCustomersService } from '../../../../../services/Users/UpdateCustomersService';
import { translateError } from '../../../../../utils/errors';
import { DatePicker } from '../../../../../components/Form/DatePicker';
import { ReactSelect } from '../../../../../components/Form/ReactSelect';

type UpdateCustomerFormData = {
  birthDate: Date;
  cpf: string;
  email: string;
  gender: 'FEMALE' | 'MALE' | 'OTHER' | 'UNDEFINED';
  name: string;
  phone?: string;
  selectedSeller?: SelectOption;
};

interface ILocationState {
  customerId: string;
}

const customerUpdateFormSchema = Yup.object().shape({
  birthDate: Yup.date()
    .max(startOfDay(new Date()), 'Data inválida')
    .nullable()
    .required('Data de nascimento requerida'),
  cpf: Yup.string()
    .required('CPF requerido')
    .transform((_, originalValue) => originalValue.replace(/\D/g, '')),
  email: Yup.string()
    .email('E-mail inválido')
    .required('E-mail requerido')
    .transform((value) => value.toLowerCase()),
  gender: Yup.string().required('Gênero requrerido'),
  name: Yup.string().required('Nome requerido'),
  phone: Yup.string()
    .nullable()
    .transform((value, originalValue) => (originalValue === '' ? null : value)),
  selectedSeller: Yup.object()
    .shape({
      label: Yup.string().required('Loja requerida'),
      value: Yup.string().uuid().required('Loja requerida'),
    })
    .required('Loja requerida'),
});

export const CustomerUpdate = (): JSX.Element => {
  const { goBack } = useHistory();
  const toast = useToast();
  const { user } = useAuth();

  const { state } = useLocation<ILocationState>();

  const [updatingCustomer, setUpdatingCustomer] =
    useState<UpdateCustomerFormData>();
  const [avatar, setAvatar] = useState<File>();
  const [avatarUrl, setAvatarUrl] = useState<string>();
  const [
    isDeleteConfirmationModalVisible,
    setIsDeleteConfirmationModalVisible,
  ] = useState(false);

  const userGendersSelectOption = useMemo(
    () => [
      {
        label: 'Feminino',
        value: 'FEMALE',
      },
      {
        label: 'Masculino',
        value: 'MALE',
      },
      {
        label: 'Outros',
        value: 'OTHERS',
      },
      {
        label: 'Não informado',
        value: 'UNDEFINED',
      },
    ],
    [],
  );

  const { register, handleSubmit, formState, reset, control } = useForm({
    resolver: yupResolver(customerUpdateFormSchema),
  });

  const { errors } = formState;
  const { customerId } = state;

  useEffect(() => {
    async function loadCustomer(): Promise<void> {
      try {
        const customerData = await showUsersService({ userId: customerId });
        setAvatarUrl(customerData.avatarUrl || undefined);

        setUpdatingCustomer({
          birthDate: new Date(customerData.birthDate),
          cpf: maskCpf(customerData.cpf).toString(),
          email: customerData.email,
          gender: customerData.gender,
          name: customerData.name,
          phone: customerData.phone,
          selectedSeller: customerData.seller
            ? {
                label: customerData.seller.name,
                value: customerData.seller.id,
              }
            : undefined,
        });
      } catch (err) {
        if (axios.isAxiosError(err) && err.response?.status !== 401) {
          toast({
            title: 'Falha ao carregar dados',
            description:
              translateError({ message: err.response?.data.message }) ||
              'Ocorreu um erro ao carregar os dados do cliente, tente novamente',
            status: 'error',
            duration: 3000,
            isClosable: true,
            variant: 'subtle',
            position: 'top-right',
          });
        }
      }
    }

    loadCustomer();
  }, [customerId, reset, toast]);

  useEffect(() => {
    reset(updatingCustomer);
  }, [reset, updatingCustomer]);

  const handleChangeAvatar = useCallback((file: File) => {
    setAvatar(file);
    setAvatarUrl(URL.createObjectURL(file));
  }, []);

  const handleToggleDeleteConfirmationModal = useCallback(() => {
    setIsDeleteConfirmationModalVisible((prevState) => !prevState);
  }, []);

  const handleDeleteAvatar = useCallback(async () => {
    await deleteUserAvatarsService(customerId);

    setAvatar(undefined);
    setAvatarUrl(undefined);
    handleToggleDeleteConfirmationModal();
  }, [customerId, handleToggleDeleteConfirmationModal]);

  const handleLoadSellerSelectOption = useCallback(
    async (name?: string): Promise<SelectOption[]> => {
      const { items: sellers } = await listSellersService({ name, limit: 4 });

      const parsedSellersSelectOption: SelectOption[] = [
        ...sellers.map((seller) => ({
          label: seller.name,
          value: seller.id,
        })),
      ];

      return parsedSellersSelectOption;
    },
    [],
  );
  const handleUpdateCustomer: SubmitHandler<UpdateCustomerFormData> =
    useCallback(
      async ({ birthDate, cpf, email, gender, name, phone }) => {
        try {
          await updateCustomersService({
            userId: customerId,
            birthDate,
            cpf,
            email,
            gender,
            name,
            phone,
          });

          if (avatar) {
            const formData = new FormData();

            formData.append('avatar', avatar);

            await updateUserAvatarsService({
              userId: customerId,
              avatarData: formData,
            });
          }

          toast({
            title: 'Editado com sucesso',
            description: 'O cliente foi editado corretamente',
            status: 'success',
            duration: 3000,
            isClosable: true,
            variant: 'subtle',
            position: 'top-right',
          });

          goBack();
        } catch (err) {
          if (axios.isAxiosError(err) && err.response?.status !== 401) {
            toast({
              title: 'Falha ao editar',
              description:
                translateError({ message: err.response?.data.message }) ||
                'Ocorreu um erro ao editar o cliente, tente novamente',
              status: 'error',
              duration: 3000,
              isClosable: true,
              variant: 'subtle',
              position: 'top-right',
            });
          }
        }
      },
      [avatar, customerId, goBack, toast],
    );

  return (
    <DefaultLayout>
      <DeleteConfirmationModal
        isOpen={isDeleteConfirmationModalVisible}
        onClose={handleToggleDeleteConfirmationModal}
        onConfirm={handleDeleteAvatar}
      />

      <Box
        as="form"
        flex="1"
        borderRadius={8}
        bg="white"
        p="8"
        onSubmit={handleSubmit(handleUpdateCustomer)}
      >
        <Heading size="lg" fontWeight="normal">
          Editar cliente
        </Heading>

        <Divider my="6" borderColor="gray.300" />

        <Flex justify="center" mb="8">
          <AvatarDropzone
            avatarUrl={avatarUrl}
            onChange={handleChangeAvatar}
            onDelete={handleToggleDeleteConfirmationModal}
          />
        </Flex>

        <VStack spacing="8">
          <Grid
            templateColumns={[
              'repeat(1, 1fr)',
              'repeat(1, 1fr)',
              'repeat(1, 1fr)',
              'repeat(1, 1fr)',
              'repeat(12, 1fr)',
            ]}
            gap="8"
            width="100%"
          >
            <GridItem colSpan={[12, 12, 12, 12, 8]}>
              <MaskedInput
                label="Nome completo"
                error={errors.name}
                {...register('name')}
              />
            </GridItem>

            <GridItem colSpan={[12, 12, 12, 12, 4]}>
              <ReactSelect
                name="gender"
                label="Gênero"
                control={control}
                error={errors.gender}
                options={userGendersSelectOption}
              />
            </GridItem>
          </Grid>

          <Grid
            templateColumns={[
              'repeat(1, 1fr)',
              'repeat(1, 1fr)',
              'repeat(1, 1fr)',
              'repeat(1, 1fr)',
              'repeat(12, 1fr)',
            ]}
            gap="8"
            width="100%"
          >
            <GridItem colSpan={[12, 12, 12, 12, 8]}>
              <AsyncSelect
                label="Loja"
                name="selectedSeller"
                loadOptions={handleLoadSellerSelectOption}
                isDisabled={user.role !== 'ADMIN'}
                control={control}
                error={errors.selectedSeller}
              />
            </GridItem>

            <GridItem colSpan={[12, 12, 12, 12, 4]}>
              <MaskedInput
                label="E-mail"
                type="email"
                textTransform="lowercase"
                error={errors.email}
                {...register('email')}
              />
            </GridItem>
          </Grid>

          <SimpleGrid minChildWidth="240px" spacing="8" w="100%">
            <MaskedInput
              label="CPF"
              mask="cpf"
              error={errors.cpf}
              {...register('cpf')}
            />
            <InternationalPhoneInput
              label="Telefone"
              name="phone"
              control={control}
              error={errors.phone}
            />

            <DatePicker
              label="Data de nascimento"
              isClearable={false}
              maxDate={new Date()}
              control={control}
              error={errors.birthDate}
              {...register('birthDate')}
            />
          </SimpleGrid>

          <MaskedInput
            label="Biografia"
            as="textarea"
            minHeight="160px"
            resize="none"
            py="2"
            error={errors.bio}
            {...register('bio')}
          />
        </VStack>

        <Flex mt="12" justify="flex-end">
          <ButtonGroup>
            <Button colorScheme="blackAlpha" onClick={goBack}>
              Cancelar
            </Button>
            <Button
              type="submit"
              colorScheme="green"
              isLoading={formState.isSubmitting}
            >
              Salvar
            </Button>
          </ButtonGroup>
        </Flex>
      </Box>
    </DefaultLayout>
  );
};

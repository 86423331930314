import { useHistory, useLocation } from 'react-router-dom';
import {
  Box,
  Heading,
  Flex,
  Button,
  Icon,
  Image,
  Text,
  SimpleGrid,
  Avatar,
  useToast,
  ButtonGroup,
  HStack,
} from '@chakra-ui/react';
import { RiDeleteBinLine, RiEditLine } from 'react-icons/ri';
import { useCallback, useEffect, useState } from 'react';
import axios from 'axios';
import { DefaultLayout } from '../../../_layout/DefaultLayout';
import { DeleteConfirmationModal } from '../../../../../components/DeleteConfirmationModal';
import { LinkButton } from '../../../../../components/LinkButton';
import { maskCnpj, maskDate } from '../../../../../utils/formatters/handleMask';
import {
  IDetailedSeller,
  showSellersService,
} from '../../../../../services/Sellers/ShowSellersService';
import { deleteSellersService } from '../../../../../services/Sellers/DeleteSellersService';
import { translateError } from '../../../../../utils/errors';

interface ISeller extends IDetailedSeller {
  formattedDate: string;
}

interface ILocationState {
  sellerId: string;
}

export const SellerDetails = (): JSX.Element => {
  const toast = useToast();
  const {
    state: { sellerId },
  } = useLocation<ILocationState>();
  const { push } = useHistory();

  const [seller, setSeller] = useState<ISeller>();
  const [
    isDeleteConfirmationModalVisible,
    setIsDeleteConfirmationModalVisible,
  ] = useState(false);

  useEffect(() => {
    async function loadSeller(): Promise<void> {
      try {
        const sellerData = await showSellersService(sellerId);

        const parsedSeller = {
          ...sellerData,
          cnpj: maskCnpj(sellerData.cnpj).toString(),
          formattedDate: maskDate(sellerData.createdAt),
        };

        setSeller(parsedSeller);
      } catch (err) {
        if (axios.isAxiosError(err) && err.response?.status !== 401) {
          toast({
            title: 'Falha ao carregar dados',
            description:
              translateError({ message: err.response?.data.message }) ||
              'Ocorreu um erro ao carregar os detalhes da loja, tente novamente',
            status: 'error',
            duration: 3000,
            isClosable: true,
            variant: 'subtle',
            position: 'top-right',
          });
        }
      }
    }

    loadSeller();
  }, [sellerId, toast]);

  const handleToggleDeleteConfirmationModal = useCallback(() => {
    setIsDeleteConfirmationModalVisible((prevState) => !prevState);
  }, []);

  const handleDeleteSeller = useCallback(async () => {
    try {
      await deleteSellersService(sellerId);

      toast({
        title: 'Excluído com sucesso',
        description: 'A loja foi excluída corretamente',
        status: 'success',
        duration: 3000,
        isClosable: true,
        variant: 'subtle',
        position: 'top-right',
      });

      push('/sellers');
    } catch (err) {
      if (axios.isAxiosError(err) && err.response?.status !== 401) {
        toast({
          title: 'Falha ao excluir',
          description:
            translateError({ message: err.response?.data.message }) ||
            'Ocorreu um erro ao excluir a loja, tente novamente',
          status: 'error',
          duration: 3000,
          isClosable: true,
          variant: 'subtle',
          position: 'top-right',
        });
      }
    }
  }, [sellerId, push, toast]);

  return (
    <DefaultLayout>
      <DeleteConfirmationModal
        isOpen={isDeleteConfirmationModalVisible}
        onClose={handleToggleDeleteConfirmationModal}
        onConfirm={handleDeleteSeller}
      />

      <Box flex="1" borderRadius={8} bg="white" p="8">
        <Flex mb="8" justify="space-between" align="center">
          <Heading size="lg" fontWeight="normal">
            Detalhes da loja
          </Heading>

          <ButtonGroup>
            <LinkButton
              colorScheme="yellow"
              color="white"
              icon={<Icon as={RiEditLine} fontSize="16" />}
              to={{
                pathname: '/sellers/update',
                state: {
                  sellerId,
                },
              }}
            >
              Editar
            </LinkButton>

            <Button
              size="sm"
              fontSize="sm"
              colorScheme="red"
              onClick={handleToggleDeleteConfirmationModal}
              leftIcon={<Icon as={RiDeleteBinLine} fontSize="20" />}
            >
              Excluir
            </Button>
          </ButtonGroup>
        </Flex>

        {seller && (
          <Flex direction="column">
            <Flex align="center" direction="column">
              {!seller.avatarUrl ? (
                <Avatar size="2xl" name={seller.name} />
              ) : (
                <Image
                  htmlWidth="240px"
                  objectFit="cover"
                  src={seller.avatarUrl}
                  alt={seller.name}
                />
              )}
              <Text fontSize="4xl" fontWeight="bold" mt="4">
                {seller.name}
              </Text>

              <Text
                textAlign="center"
                fontSize="sm"
                color="gray.500"
                mt="2"
                width="50%"
              >
                {seller.bio}
              </Text>
            </Flex>

            <SimpleGrid
              minChildWidth="240px"
              spacing="8"
              px="8"
              mt="4"
              w="100%"
            >
              <HStack>
                <Text>Telefone:</Text>
                <Text as="span" color="gray.500">
                  {seller.phone}
                </Text>
              </HStack>

              <HStack>
                <Text>E-mail:</Text>
                <Text as="span" color="gray.500">
                  {seller.email}
                </Text>
              </HStack>

              <HStack>
                <Text>CNPJ:</Text>
                <Text as="span" color="gray.500">
                  {seller.cnpj}
                </Text>
              </HStack>

              <HStack>
                <Text>Data de cadastro:</Text>
                <Text as="span" color="gray.500">
                  {seller.formattedDate}
                </Text>
              </HStack>
            </SimpleGrid>
          </Flex>
        )}
      </Box>
    </DefaultLayout>
  );
};

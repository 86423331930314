import { ApexOptions } from 'apexcharts';
import theme from '../../../../../../styles/theme';

interface IUseAgeDistributionChartOptions {
  labels: string[];
  series: number[];
}
const useUserGenderDistributionChartOptions = ({
  labels,
  series,
}: IUseAgeDistributionChartOptions): ApexOptions =>
  ({
    chart: {
      foreColor: theme.colors.gray[500],
    },
    legend: {
      position: 'bottom',
    },
    colors: ['#90CDF4', '#63B3ED', '#4299E1', '#3182CE'],
    labels,
    series,
  } as ApexOptions);

export default useUserGenderDistributionChartOptions;

import { IUserBase } from '../../models/users';
import cjPontanegraApi from '../apis/cjPontanegraApi';

interface ICreateUserProps {
  birthDate: Date;
  cpf: string;
  email: string;
  gender: 'FEMALE' | 'MALE' | 'OTHER' | 'UNDEFINED';
  name: string;
  phone?: string;
  role: 'ADMIN' | 'EMPLOYEE' | 'CUSTOMER';
  sellerId?: string | null;
}

export type INewUser = IUserBase;

export const createUsersService = async ({
  birthDate,
  cpf,
  email,
  gender,
  name,
  phone,
  role,
  sellerId,
}: ICreateUserProps): Promise<INewUser> => {
  const { data } = await cjPontanegraApi.post<INewUser>('/users', {
    birthDate,
    cpf,
    email,
    gender,
    name,
    phone,
    role,
    sellerId,
  });

  return data;
};

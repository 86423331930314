import { Flex, Button, Icon, Text } from '@chakra-ui/react';
import { useCallback } from 'react';
import { RiSmartphoneLine } from 'react-icons/ri';

export const ConfirmPassword = (): JSX.Element => {
  const handleOpenApp = useCallback(() => {
    window.location.replace('clube-pontanegra://clube-pontanegra');
  }, []);

  return (
    <Flex w="100vw" h="100vh" align="center" justify="center">
      <Flex
        maxWidth={360}
        minH="40vh"
        bg="white"
        p="8"
        borderRadius={8}
        flexDir="column"
      >
        <Text fontSize="1.75rem" fontWeight="bold" letterSpacing="tight">
          CLUBE-PONTANEGRA
          <Text as="span" ml="1" color="blue.500">
            .
          </Text>
        </Text>

        <Flex flexDirection="column" justifyContent="space-evenly" flex={1}>
          <Text fontSize="lg">Sua senha foi salva com sucesso!</Text>
          <Button
            mt="8"
            colorScheme="green"
            size="lg"
            w="100%"
            onClick={handleOpenApp}
            leftIcon={<Icon as={RiSmartphoneLine} fontSize="24" />}
          >
            Abrir aplicativo
          </Button>
        </Flex>
      </Flex>
    </Flex>
  );
};

import { ISellerBase } from '../../models/sellers';
import { IUserBase } from '../../models/users';
import cjPontanegraApi from '../apis/cjPontanegraApi';

interface IListUserProps {
  cpf?: string;
  email?: string;
  name?: string;
  phone?: string;
  roles?: Array<'ADMIN' | 'EMPLOYEE' | 'CUSTOMER'>;
  sellerId?: string;
  sort?: 'cpf' | 'name' | 'phone' | 'email' | 'createdAt';
  order?: 'ASC' | 'DESC';
  limit?: number;
  page?: number;
}

export interface IUserListItem extends IUserBase {
  seller?: ISellerBase;
}

export interface IUserList {
  items: IUserListItem[];
  pages: number;
  total: number;
}

export const listUsersService = async (
  filters?: IListUserProps,
): Promise<IUserList> => {
  const params =
    filters && Object.keys(filters).length !== 0
      ? {
          cpf: filters.cpf || undefined,
          email: filters.email || undefined,
          name: filters.name || undefined,
          phone: filters.phone || undefined,
          roles: filters.roles || undefined,
          sellerId: filters.sellerId || undefined,
          sort: filters.sort,
          order: filters.order,
          limit: filters.limit,
          page: filters.page,
        }
      : undefined;

  const { data } = await cjPontanegraApi.get<IUserList>('/users', { params });

  return data;
};

import Chart from 'react-apexcharts';
import { Box, Text } from '@chakra-ui/react';
import useUserGenderDistributionChartOptions from './useUserGenderDistributionChartOptions';

type SeriesData = { series: number[]; labels: string[] };

interface IUserGenderDistributionChartProps {
  usersCountByGenders: SeriesData;
}

const UserGenderDistributionChart = ({
  usersCountByGenders,
}: IUserGenderDistributionChartProps): JSX.Element => {
  const genderDistributionOptions =
    useUserGenderDistributionChartOptions(usersCountByGenders);

  return (
    <>
      <Text fontSize="lg">Gênero dos usuários</Text>
      {!!genderDistributionOptions.series?.length && (
        <Box mt="8">
          <Chart
            options={genderDistributionOptions}
            series={genderDistributionOptions.series}
            type="donut"
            height={360}
          />
        </Box>
      )}
    </>
  );
};

export default UserGenderDistributionChart;

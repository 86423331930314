import { useToast } from '@chakra-ui/toast';
import axios, { AxiosError } from 'axios';
import React, {
  createContext,
  useCallback,
  useState,
  useContext,
  useEffect,
} from 'react';
import cjPontanegraApi from '../services/apis/cjPontanegraApi';

import {
  authenticateSessionsService,
  INewSession,
  ISessionUser,
} from '../services/Auth/AuthenticateSessionsService';
import { translateError } from '../utils/errors';

// interface IRefreshingQueueItem {
//   onSuccess: (token: string) => void;
//   onFailure: (error: AxiosError) => void;
// }

type IAuthState = INewSession;

interface ISignInCredentials {
  email: string;
  password: string;
}

interface IAuthContextData {
  user: ISessionUser;
  signIn(credentials: ISignInCredentials): Promise<void>;
  signOut: () => void;
  loading: boolean;
  updateUser: (user: ISessionUser) => void;
}

const AuthContext = createContext<IAuthContextData>({} as IAuthContextData);

const AuthProvider: React.FC = ({ children }) => {
  const toast = useToast();

  const [loading, setLoading] = useState(false);
  const [data, setData] = useState<IAuthState>(() => {
    const refreshToken = localStorage.getItem('@CLUBE-PONTANEGRA:refreshToken');
    const token = localStorage.getItem('@CLUBE-PONTANEGRA:token');
    const user = localStorage.getItem('@CLUBE-PONTANEGRA:user');
    if (refreshToken && token && user) {
      cjPontanegraApi.defaults.headers.authorization = `Bearer ${token}`;

      return { refreshToken, token, user: JSON.parse(user) };
    }

    return {} as IAuthState;
  });

  const signIn = useCallback(
    async ({ email, password }) => {
      setLoading(true);
      try {
        const sessionData = await authenticateSessionsService({
          email,
          password,
        });

        const { refreshToken, token, user } = sessionData;

        if (user.role === 'CUSTOMER') {
          toast({
            title: 'Não autorizado',
            description: 'Você não tem permissão para entrar aqui',
            status: 'error',
            duration: 3000,
            isClosable: true,
            variant: 'subtle',
            position: 'top-right',
          });

          return;
        }

        localStorage.setItem('@CLUBE-PONTANEGRA:refreshToken', refreshToken);
        localStorage.setItem('@CLUBE-PONTANEGRA:token', token);
        localStorage.setItem('@CLUBE-PONTANEGRA:user', JSON.stringify(user));

        cjPontanegraApi.defaults.headers.authorization = `Bearer ${token}`;

        setData({ refreshToken, token, user });
      } catch (err) {
        if (axios.isAxiosError(err) && err.response?.status !== 401) {
          toast({
            title: 'Falha na autenticação',
            description:
              translateError({ message: err.response?.data.message }) ||
              'Ocorreu um erro ao fazer o login, verifique os dados e tente novamente',
            status: 'error',
            duration: 3000,
            isClosable: true,
            variant: 'subtle',
            position: 'top-right',
          });
        }
      } finally {
        setLoading(false);
      }
    },
    [toast],
  );

  const signOut = useCallback(() => {
    localStorage.removeItem('@CLUBE-PONTANEGRA:refreshToken');
    localStorage.removeItem('@CLUBE-PONTANEGRA:token');
    localStorage.removeItem('@CLUBE-PONTANEGRA:user');

    setData({} as IAuthState);
  }, []);

  const updateUser = useCallback((user: ISessionUser) => {
    localStorage.setItem('@CLUBE-PONTANEGRA:user', JSON.stringify(user));

    setData((prev) => ({
      refreshToken: prev.refreshToken,
      token: prev.token,
      user,
    }));
  }, []);

  useEffect(() => {
    // let failedRequestQueue: Array<IRefreshingQueueItem> = [];
    // let isRefreshing = false;

    cjPontanegraApi.interceptors.response.use(
      (response) => response,
      (error: AxiosError) => {
        if (error.response?.status === 401) {
          // if (error.response?.data.message === 'jwt expired') {
          //   const originalConfig = error.config;
          //   const refreshToken = localStorage.getItem(
          //     '@CLUBE-PONTANEGRA:refreshToken',
          //   );

          //   if (!isRefreshing && refreshToken) {
          //     isRefreshing = true;

          //     try {
          //       refreshTokensService(refreshToken).then(
          //         ({ refreshToken: newRefreshToken, token: newToken }) => {
          //           localStorage.setItem(
          //             '@CLUBE-PONTANEGRA:refreshToken',
          //             newRefreshToken,
          //           );

          //           localStorage.setItem('@CLUBE-PONTANEGRA:token', newToken);

          //           cjPontanegraApi.defaults.headers.authorization = `Bearer ${newToken}`;

          //           failedRequestQueue.forEach((request) =>
          //             request.onSuccess(newToken),
          //           );

          //           failedRequestQueue = [];

          //           setData((prevState) => ({
          //             ...prevState,
          //             token: newToken,
          //             refreshToken: newRefreshToken,
          //           }));
          //         },
          //       );
          //       // eslint-disable-next-line @typescript-eslint/no-explicit-any
          //     } catch (err: any) {
          //       failedRequestQueue.forEach((request) => request.onFailure(err));

          //       failedRequestQueue = [];
          //     } finally {
          //       isRefreshing = false;
          //     }
          //   }

          //   return new Promise((resolve, reject) => {
          //     failedRequestQueue.push({
          //       onSuccess: (newToken: string) => {
          //         originalConfig.headers.authorization = `Bearer ${newToken}`;

          //         resolve(cjPontanegraApi(originalConfig));
          //       },
          //       onFailure: (err: AxiosError) => {
          //         reject(err);
          //       },
          //     });
          //   });
          // }

          toast({
            title: 'Sessão expirada',
            description: 'Por favor, faça login novamente.',
            status: 'error',
            duration: 3000,
            isClosable: true,
            variant: 'subtle',
            position: 'top-right',
          });

          signOut();
        }

        return Promise.reject(error);
      },
    );
  }, [signOut, toast]);

  return (
    <AuthContext.Provider
      value={{
        user: data.user,
        signIn,
        signOut,
        loading,
        updateUser,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

const useAuth = (): IAuthContextData => useContext(AuthContext);

export { AuthProvider, useAuth };

import { useCallback, useState } from 'react';
import { useHistory } from 'react-router';
import { SubmitHandler, useForm } from 'react-hook-form';
import * as Yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import {
  Box,
  Heading,
  Divider,
  VStack,
  SimpleGrid,
  Flex,
  Button,
  useToast,
  ButtonGroup,
} from '@chakra-ui/react';

import axios from 'axios';
import { AvatarDropzone } from '../../../../../components/Form/AvatarDropzone';
import { MaskedInput } from '../../../../../components/Form/MaskedInput';
import { DefaultLayout } from '../../../_layout/DefaultLayout';
import { InternationalPhoneInput } from '../../../../../components/Form/InternationalPhoneInput';
import { updateSellerAvatarsService } from '../../../../../services/Sellers/UpdateSellerAvatarsService';
import { createSellersService } from '../../../../../services/Sellers/CreateSellersService';
import { translateError } from '../../../../../utils/errors';

type NewSellerFormData = {
  bio?: string;
  cnpj: string;
  email: string;
  name: string;
  phone?: string;
};

const registerSellerFormSchema = Yup.object().shape({
  bio: Yup.string()
    .nullable()
    .transform((value, originalValue) => (originalValue === '' ? null : value)),
  cnpj: Yup.string()
    .required('CNPJ requerido')
    .length(14, 'Valor inválido, requerido 14 dígitos')
    .transform((_, originalValue) => originalValue.replace(/\D/g, '')),
  email: Yup.string()
    .email('E-mail inválido')
    .required('E-mail requerido')
    .transform((value) => value.toLowerCase()),
  name: Yup.string().required('Nome requerido'),
  phone: Yup.string()
    .nullable()
    .transform((value, originalValue) => (originalValue === '' ? null : value)),
});

export const SellerRegister = (): JSX.Element => {
  const { goBack, push } = useHistory();
  const toast = useToast();

  const [avatar, setAvatar] = useState<File>();
  const [avatarUrl, setAvatarUrl] = useState<string>();

  const { register, handleSubmit, formState, control } = useForm({
    resolver: yupResolver(registerSellerFormSchema),
  });

  const { errors } = formState;

  const handleChangeAvatar = useCallback((file: File) => {
    setAvatar(file);
    setAvatarUrl(URL.createObjectURL(file));
  }, []);

  const handleDeleteAvatar = useCallback(() => {
    setAvatar(undefined);
    setAvatarUrl(undefined);
  }, []);

  const handleNewSeller: SubmitHandler<NewSellerFormData> = useCallback(
    async ({ bio, cnpj, email, name, phone }) => {
      try {
        const seller = await createSellersService({
          bio,
          cnpj,
          email,
          name,
          phone,
        });

        if (avatar) {
          const formData = new FormData();

          formData.append('avatar', avatar);

          await updateSellerAvatarsService({
            avatarData: formData,
            sellerId: seller.id,
          });
        }

        toast({
          title: 'Cadastrado com sucesso',
          description: 'A loja foi cadastrada corretamente',
          status: 'success',
          duration: 3000,
          isClosable: true,
          variant: 'subtle',
          position: 'top-right',
        });

        push('/sellers/details', { sellerId: seller.id });
      } catch (err) {
        if (axios.isAxiosError(err) && err.response?.status !== 401) {
          toast({
            title: 'Falha no cadastro',
            description:
              translateError({ message: err.response?.data.message }) ||
              'Ocorreu um erro ao cadastrar a loja, tente novamente',
            status: 'error',
            duration: 3000,
            isClosable: true,
            variant: 'subtle',
            position: 'top-right',
          });
        }
      }
    },
    [avatar, push, toast],
  );

  return (
    <DefaultLayout>
      <Box
        as="form"
        flex="1"
        borderRadius={8}
        bg="white"
        p="8"
        onSubmit={handleSubmit(handleNewSeller)}
      >
        <Heading size="lg" fontWeight="normal">
          Cadastrar loja
        </Heading>

        <Divider my="6" borderColor="gray.300" />

        <Flex justify="center" mb="8">
          <AvatarDropzone
            avatarUrl={avatarUrl}
            onChange={handleChangeAvatar}
            onDelete={handleDeleteAvatar}
          />
        </Flex>

        <VStack spacing="8">
          <MaskedInput label="Nome" error={errors.name} {...register('name')} />
          <SimpleGrid minChildWidth="240px" spacing="8" w="100%">
            <MaskedInput
              label="E-mail"
              type="email"
              textTransform="lowercase"
              error={errors.email}
              {...register('email')}
            />

            <InternationalPhoneInput
              label="Telefone"
              name="phone"
              control={control}
              error={errors.phone}
            />

            <MaskedInput
              label="CNPJ"
              mask="cnpj"
              error={errors.cnpj}
              {...register('cnpj')}
            />
          </SimpleGrid>

          <MaskedInput
            label="Biografia"
            as="textarea"
            minHeight="160px"
            resize="none"
            py="2"
            error={errors.bio}
            {...register('bio')}
          />
        </VStack>

        <Flex mt="12" justify="flex-end">
          <ButtonGroup>
            <Button colorScheme="blackAlpha" onClick={goBack}>
              Cancelar
            </Button>
            <Button
              type="submit"
              colorScheme="green"
              isLoading={formState.isSubmitting}
            >
              Salvar
            </Button>
          </ButtonGroup>
        </Flex>
      </Box>
    </DefaultLayout>
  );
};

import { ApexOptions } from 'apexcharts';
import theme from '../../../../../../styles/theme';

interface IUseAdvertisementClickChartOptions {
  labels: string[];
  series: number[];
}
const useAdvertisementClickChartOptions = ({
  labels,
  series,
}: IUseAdvertisementClickChartOptions): ApexOptions =>
  ({
    chart: {
      toolbar: {
        show: false,
      },
      foreColor: theme.colors.gray[500],
    },
    grid: {
      show: false,
    },
    plotOptions: {
      bar: {
        horizontal: true,
        borderRadius: 4,
        barHeight: '96%',
      },
    },
    series: [{ name: 'cliques', data: series }],
    xaxis: {
      type: 'category',
      axisBorder: {
        color: theme.colors.gray[400],
      },
      axisTicks: {
        color: theme.colors.gray[400],
      },
      categories: labels,
    },
    yaxis: {
      labels: {
        show: false,
      },
    },
  } as ApexOptions);

export default useAdvertisementClickChartOptions;

import {
  Box,
  Table,
  Text,
  Tbody,
  Td,
  Th,
  Thead,
  Tr,
  BoxProps,
  Icon,
} from '@chakra-ui/react';
import { useMemo } from 'react';
import {
  RiCheckboxBlankCircleLine,
  RiCheckboxCircleLine,
  RiEyeLine,
} from 'react-icons/ri';
import { LinkButton } from '../../../../../../../components/LinkButton';
import { Pagination } from '../../../../../../../components/Pagination';
import { TableFilters } from '../../../../../../../components/TableFilters';
import { IAdvertisementCategoryListItem } from '../../../../../../../services/AdvertisementCategories/ListAdvertisementCategoriesService';

export interface IAdvertisementCategory extends IAdvertisementCategoryListItem {
  formattedDate: string;
}

interface IAdvertisementCategoryTableProps extends BoxProps {
  advertisementCategories: IAdvertisementCategory[];
  currentPage?: number;
  filterBy?: string;
  onChangeFilterBy?: (value: string) => void;
  onFilter?: (value: string) => void;
  onOrder?: (order: 'ASC' | 'DESC') => void;
  onPageChange?: (page: number) => void;
  onSort?: (sort: 'isActive' | 'name' | 'showOrder' | 'createdAt') => void;
  order?: 'ASC' | 'DESC';
  sortBy?: string;
  totalPages?: number;
}

export const AdvertisementCategoryTable = ({
  advertisementCategories,
  currentPage,
  filterBy,
  onChangeFilterBy,
  onFilter,
  onOrder,
  onPageChange,
  onSort,
  order,
  sortBy,
  totalPages,
  ...rest
}: IAdvertisementCategoryTableProps): JSX.Element => {
  const filterByOptions = useMemo(
    () => [
      {
        label: 'Nome',
        value: 'name',
      },
    ],
    [],
  );

  const sortByOptions = useMemo(
    () => [
      {
        label: 'Nome',
        value: 'name',
      },
      {
        label: 'Status',
        value: 'isActive',
      },
      {
        label: 'Ordem de exibição',
        value: 'showOrder',
      },
      {
        label: 'Data de cadastro',
        value: 'createdAt',
      },
    ],
    [],
  );

  return (
    <Box {...rest}>
      <TableFilters
        filterBy={filterBy}
        filterByOptions={filterByOptions}
        onChangeFilterBy={onChangeFilterBy}
        onFilter={onFilter}
        onOrder={onOrder}
        onSort={(sort) =>
          !!onSort &&
          onSort(sort as 'isActive' | 'name' | 'showOrder' | 'createdAt')
        }
        order={order}
        sortBy={sortBy}
        sortByOptions={sortByOptions}
      />

      <Table colorScheme="blue" mt="4">
        <Thead>
          <Tr>
            <Th>Categoria</Th>
            <Th textAlign="center">Ativo</Th>
            <Th textAlign="center">Ordem de exibição</Th>
            <Th textAlign="center">Anúncios</Th>
            <Th textAlign="center">Data de cadastro</Th>
            <Th w="8" />
          </Tr>
        </Thead>

        <Tbody>
          {advertisementCategories.map((advertisementCategory) => (
            <Tr key={advertisementCategory.id}>
              <Td>
                <Text fontWeight="bold">{advertisementCategory.name}</Text>
              </Td>

              <Td textAlign="center">
                {advertisementCategory.isActive ? (
                  <Icon as={RiCheckboxCircleLine} color="green" />
                ) : (
                  <Icon as={RiCheckboxBlankCircleLine} color="gray.400" />
                )}
              </Td>

              <Td textAlign="center">{advertisementCategory.showOrder}</Td>

              <Td textAlign="center">
                {advertisementCategory.advertisements.length}
              </Td>

              <Td textAlign="center">{advertisementCategory.formattedDate}</Td>

              <Td>
                <LinkButton
                  p={0}
                  to={{
                    pathname: '/categories/details',
                    state: {
                      advertisementCategoryId: advertisementCategory.id,
                    },
                  }}
                >
                  <Icon as={RiEyeLine} fontSize="16" />
                </LinkButton>
              </Td>
            </Tr>
          ))}
        </Tbody>
      </Table>

      {!!currentPage && !!onPageChange && !!totalPages && totalPages > 1 && (
        <Pagination
          currentPage={currentPage}
          onPageChange={onPageChange}
          totalPages={totalPages}
        />
      )}
    </Box>
  );
};

import { IAppDownloadBase } from '../../models/appDownloads';
import cjPontanegraApi from '../apis/cjPontanegraApi';

export type IAppDownload = IAppDownloadBase;

export const listAppDownloadsService = async (): Promise<IAppDownload[]> => {
  const { data } = await cjPontanegraApi.get<IAppDownload[]>('/app-downloads');

  return data;
};

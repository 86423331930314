import {
  Box,
  Table,
  Text,
  Tbody,
  Td,
  Th,
  Thead,
  Tr,
  BoxProps,
  Icon,
} from '@chakra-ui/react';
import { useMemo } from 'react';
import { RiEyeLine } from 'react-icons/ri';
import { LinkButton } from '../../../../../../../components/LinkButton';
import { Pagination } from '../../../../../../../components/Pagination';
import { TableFilters } from '../../../../../../../components/TableFilters';
import { ISellerListItem } from '../../../../../../../services/Sellers/ListSellersService';

export interface ISeller extends ISellerListItem {
  formattedDate: string;
}

interface ISellerTableProps extends BoxProps {
  sellers: ISeller[];
  currentPage?: number;
  filterBy?: string;
  onChangeFilterBy?: (value: string) => void;
  onFilter?: (value: string) => void;
  onOrder?: (order: 'ASC' | 'DESC') => void;
  onPageChange?: (page: number) => void;
  onSort?: (sort: 'cnpj' | 'name' | 'phone' | 'email' | 'createdAt') => void;
  order?: 'ASC' | 'DESC';
  sortBy?: string;
  totalPages?: number;
}

export const SellerTable = ({
  sellers,
  currentPage,
  filterBy,
  onChangeFilterBy,
  onFilter,
  onOrder,
  onPageChange,
  onSort,
  order,
  sortBy,
  totalPages,
  ...rest
}: ISellerTableProps): JSX.Element => {
  const filterByOptions = useMemo(
    () => [
      {
        label: 'Nome',
        value: 'name',
      },
      {
        label: 'E-mail',
        value: 'email',
      },
      {
        label: 'Telefone',
        value: 'phone',
      },
      {
        label: 'CNPJ',
        value: 'cnpj',
      },
    ],
    [],
  );

  const sortByOptions = useMemo(
    () => [
      {
        label: 'Nome',
        value: 'name',
      },
      {
        label: 'Telefone',
        value: 'phone',
      },
      {
        label: 'E-mail',
        value: 'email',
      },
      {
        label: 'CNPJ',
        value: 'cnpj',
      },
      {
        label: 'Data de cadastro',
        value: 'createdAt',
      },
    ],
    [],
  );

  return (
    <Box {...rest}>
      <TableFilters
        filterBy={filterBy}
        filterByOptions={filterByOptions}
        onChangeFilterBy={onChangeFilterBy}
        onFilter={onFilter}
        onOrder={onOrder}
        onSort={(sort) =>
          !!onSort &&
          onSort(sort as 'cnpj' | 'name' | 'phone' | 'email' | 'createdAt')
        }
        order={order}
        sortBy={sortBy}
        sortByOptions={sortByOptions}
      />

      <Table colorScheme="blue" mt="4">
        <Thead>
          <Tr>
            <Th>Loja</Th>
            <Th>Telefone</Th>
            <Th>E-mail</Th>
            <Th>CNPJ</Th>
            <Th textAlign="center">Data de cadastro</Th>
            <Th w="8" />
          </Tr>
        </Thead>

        <Tbody>
          {sellers.map((seller) => (
            <Tr key={seller.id}>
              <Td>
                <Box>
                  <Text fontWeight="bold">{seller.name}</Text>
                </Box>
              </Td>

              <Td>{seller.phone}</Td>

              <Td>{seller.email}</Td>

              <Td>{seller.cnpj}</Td>

              <Td textAlign="center">{seller.formattedDate}</Td>

              <Td>
                <LinkButton
                  p={0}
                  to={{
                    pathname: '/sellers/details',
                    state: {
                      sellerId: seller.id,
                    },
                  }}
                >
                  <Icon as={RiEyeLine} fontSize="16" />
                </LinkButton>
              </Td>
            </Tr>
          ))}
        </Tbody>
      </Table>

      {!!currentPage && !!onPageChange && !!totalPages && totalPages > 1 && (
        <Pagination
          currentPage={currentPage}
          onPageChange={onPageChange}
          totalPages={totalPages}
        />
      )}
    </Box>
  );
};

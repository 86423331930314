import {
  IAdvertisementBase,
  IAdvertisementCouponBase,
} from '../../models/advertisements';
import { ISellerBase } from '../../models/sellers';
import { IUserBase } from '../../models/users';
import cjPontanegraApi from '../apis/cjPontanegraApi';

interface IDetailedAdvertisement extends IAdvertisementBase {
  seller: ISellerBase;
}

export interface IDetailedAdvertisementCoupon extends IAdvertisementCouponBase {
  advertisement: IDetailedAdvertisement;
  user: IUserBase;
}

export const showCouponsService = async (
  token: string,
): Promise<IDetailedAdvertisementCoupon> => {
  const { data } = await cjPontanegraApi.post<IDetailedAdvertisementCoupon>(
    '/announcements/coupons/find',
    { token },
  );

  return data;
};
